import { PaginationFrag } from '../fragments';
import gql from 'graphql-tag';

export const QUERY_MANAGE_SEARCH_COMPANIES = gql`
  query GetManageCompanySearch(
    $company: CompanyFilterInput
    $filter: CustomerFilterInput = { dNamePrefix: null, corporateNameSubstring: null }
    $page: UnsignedInt32! = 1
    $perPage: UnsignedInt32! = 10
  ) {
    company(
      filter: $company
    ) {
      allCustomers(
        filter: $filter,
        page: $page,
        perPage: $perPage
      ) {
        results {
          id
          dName
          shortname
          corporateName
          isReseller
          createdAt
          reseller {
            dName
            corporateName
            isReseller
          }
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;
