import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import { QUERY_TRAFFIC_PERCENTILE95 } from './models';

/**
 * Get Summary Percentage Query
 */
export const GetTrafficPercentile95Query = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_TRAFFIC_PERCENTILE95,
    variables: { filter: { companyDName: CoreConfig.company.dName } },
    fetchPolicy: 'no-cache'
  };
});
