/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * ISO 3166-1 alpha-2 country codes
 */
export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

/**
 * IP versions (v4 or v6)
 */
export enum IPVersion {
  IPV4 = 'IPV4',
  IPV6 = 'IPV6',
}

/**
 * Allowed sort direction values.
 */
export enum SortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

/**
 * Dimensions that results may be grouped by
 */
export enum TrafficDimension {
  COUNTRY = 'COUNTRY',
  DAY = 'DAY',
  DESTINATION_IP = 'DESTINATION_IP',
  DESTINATION_PORT = 'DESTINATION_PORT',
  HOUR = 'HOUR',
  INTERFACE_PATH = 'INTERFACE_PATH',
  IP_PROTOCOL = 'IP_PROTOCOL',
  IP_VERSION = 'IP_VERSION',
  MINUTE = 'MINUTE',
  NODE = 'NODE',
  SOURCE_ASN = 'SOURCE_ASN',
}

/**
 * Field values that can be included in `TrafficData`
 */
export enum TrafficField {
  IN_BITS = 'IN_BITS',
  IN_BITS_PER_SECOND = 'IN_BITS_PER_SECOND',
  IN_PACKETS = 'IN_PACKETS',
  IN_PACKETS_PER_SECOND = 'IN_PACKETS_PER_SECOND',
  MITIGATED_BITS = 'MITIGATED_BITS',
  MITIGATED_BITS_PER_SECOND = 'MITIGATED_BITS_PER_SECOND',
  MITIGATED_PACKETS = 'MITIGATED_PACKETS',
  MITIGATED_PACKETS_PER_SECOND = 'MITIGATED_PACKETS_PER_SECOND',
  OUT_BITS = 'OUT_BITS',
  OUT_BITS_PER_SECOND = 'OUT_BITS_PER_SECOND',
  OUT_PACKETS = 'OUT_PACKETS',
  OUT_PACKETS_PER_SECOND = 'OUT_PACKETS_PER_SECOND',
}

/**
 * Metrics that can be reported for fields.
 */
export enum TrafficMetric {
  AVERAGE = 'AVERAGE',
  MAX = 'MAX',
  MIN = 'MIN',
  PERCENTILE_50 = 'PERCENTILE_50',
  PERCENTILE_95 = 'PERCENTILE_95',
  SUM = 'SUM',
}

export enum TunnelState {
  DOWN = 'DOWN',
  UP = 'UP',
}

/**
 * TrafficFilterInput is used to filter results.
 */
export interface TrafficFilterInput {
  companyDName?: string | null;
  countries?: CountryCode[] | null;
  nodes?: string[] | null;
  sourceASNs?: any[] | null;
  destinationIPs?: any[] | null;
  destinationPorts?: any[] | null;
  ipVersions?: IPVersion[] | null;
  ipProtocols?: string[] | null;
  interfacePath?: string | null;
}

export interface TrafficGroupBy {
  dimension: TrafficDimension;
  timeMultiplier?: any | null;
  includeRollup: boolean;
}

/**
 * Sort criteria.
 *
 * Either `field`/`metric` or `dimension` must be specified, and only one or the other.
 * If `field`/`metric` is given, it must be a valid combination from the request `fields`/`metrics` lists.
 * If `dimension` is given, it must be one of the `groupBy` dimensions.
 */
export interface TrafficSortBy {
  field?: TrafficField | null;
  metric?: TrafficMetric | null;
  dimension?: TrafficDimension | null;
  direction: SortDirection;
}

export interface TunnelFilterInput {
  companyDName?: string | null;
  interfacePath?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
