import gql from 'graphql-tag';

import { TrafficByKeyFrag, TrafficByKeyDepth2Frag } from '../fragments';

export const QUERY_GEO_TRAFFIC = gql`
  query GetGeoTrafficQuery(
    $fields: [TrafficField!]! = [IN_BITS_PER_SECOND]
    $metrics: [TrafficMetric!]! = [PERCENTILE_95]
    $from: Time!
    $to: Time
    $groupBy: [TrafficGroupBy!] = [{ dimension: COUNTRY }]
    $filter: TrafficFilterInput
  ) {
    traffic(fields: $fields, metrics: $metrics, from: $from, to: $to, groupBy: $groupBy, filter: $filter) {
      ...TrafficByKeyFields
    }
  }
  ${TrafficByKeyFrag}
`;

export const SUBSCRIPTION_GEO_TRAFFIC = gql`
  subscription GetGeoTrafficSubscription(
    $fields: [TrafficField!]! = [IN_BITS_PER_SECOND]
    $metrics: [TrafficMetric!]! = [PERCENTILE_95]
    $timeSpanMinutes: UnsignedInt32! = 60
    $groupBy: [TrafficGroupBy!] = [{ dimension: COUNTRY }]
    $filter: TrafficFilterInput
  ) {
    traffic(fields: $fields, metrics: $metrics, timeSpanMinutes: $timeSpanMinutes, groupBy: $groupBy, filter: $filter) {
      ...TrafficByKeyFields
    }
  }
  ${TrafficByKeyFrag}
`;

export const QUERY_GEO_TRAFFIC_TYPE = gql`
  query GetGeoTrafficQueryType(
    $fields: [TrafficField!]! = [IN_BITS_PER_SECOND]
    $metrics: [TrafficMetric!]! = [PERCENTILE_95]
    $from: Time!
    $to: Time
    $groupBy: [TrafficGroupBy!] = [{ dimension: COUNTRY }, { dimension: IP_PROTOCOL }]
    $filter: TrafficFilterInput
  ) {
    traffic(fields: $fields, metrics: $metrics, from: $from, to: $to, groupBy: $groupBy, filter: $filter) {
      ...TrafficByKeyDepth2Fields
    }
  }
  ${TrafficByKeyDepth2Frag}
`;
