import gql from 'graphql-tag';

import { PaginationFrag } from '../fragments';

export const QUERY_GET_FILTERLIST_LIST = gql`
  query GetFilterLists($companyFilter: CompanyFilterInput, $page: UnsignedInt32, $perPage: UnsignedInt32, $sortBy: [FilterListSortBy!], $filter: FilterListsFilterInput) {
    company(filter: $companyFilter) {
      filterLists(page: $page, perPage: $perPage, sortBy: $sortBy, filter: $filter) {
        results {
          description
          filterType
          id
          listType
          listTypeLabel
          name
          usedInAutoMitigation
          usedInTemplate
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_GET_FILTERLIST_ITEM = gql`
  query GetFilterListsItem($companyFilter: CompanyFilterInput, $page: UnsignedInt32, $perPage: UnsignedInt32, $sortBy: [FilterListSortBy!], $filter: FilterListsFilterInput) {
    company(filter: $companyFilter) {
      filterLists(page: $page, perPage: $perPage, sortBy: $sortBy, filter: $filter) {
        results {
          description
          entries
          filterType
          id
          listType
          listTypeLabel
          name
          usedInAutoMitigation
          usedInTemplate
          ... on InlineFilter {
            managedObjects {
              id
              name
            }
          }
          ... on UserDefinedFilter {
            templates {
              id
              managedObjects {
                id
                name
              }
              name
            }
          }
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;
