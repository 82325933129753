import gql from 'graphql-tag';

export const ManagedObjectFrag = gql`
  fragment ManagedObjects on ManagedObject {
    id
    name
    types
    elementType
    elements {
      ... on ManagedObjectElementSimple {
        value
      }
      ... on ManagedObjectElementGroup {
        values
        tag
      }
    }
    mitigationTemplates {
      id
      ipVersion
      deviceGroup {
        id
        name
      }
      filterList {
        id
        name
      }
    }
  }
`;

export const ProxySectionFrag = gql`
  fragment ProxySectionFields on Company {
    proxyPackage {
      enabled
      wafEnabled
      botEnabled
      networkEnabled
      enableHTTPSPacketInspection
      proxyType
      vipCategory
      vipPoolAllocationLimit
      maxWAFSignatures
      maxSSLCertificates
    }
    legacyProxies {
      id
    }
    proxies {
      id
    }
  }
`;

export const SalesforceSectionFrag = gql`
  fragment SalesforceSectionFields on Company {
    accountInfo {
      accountNumber
      domain
      countryName
      state
      accountExecutiveList {
        name
        email
        phone
      }
      featureList {
        name
        activationStatus
      }
    }
  }
`;


export const DetectionAndAlertingSectionFrag = gql`
  fragment DNASectionFields on Company {
    detectionAndAlertingPackage {
      enabled
      managedObjects {
        ...ManagedObjects
      }
      routers {
        id
        name
        flowConfig {
          exportIP {
            string
          }
          alertEnabled
          alertTimeout
        }
        snmpConfig {
          community
          ipAddress {
            string
          }
        }
      }
    }
  }
  ${ManagedObjectFrag}
`;

export const WhiteLabelSectionFrag = gql`
  fragment WhiteLabelSectionFields on Company {
    whiteLabel {
      enabled
      productName
      theme
      supportURL
      domain
      headerLogo
      favicon
      footerLinks {
        url
        label
      }
      loginMarqueeItems
      bottomLeftLabel
      bottomRightLabel
      email {
        welcomeOutroText
        welcomeIntroText
        resetOutroText
        resetIntroText
        fromAddr
        resetSignatureText
        welcomeSignatureText
      }
    }
  }
`;
