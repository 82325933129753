import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import {
  QUERY_TRAFFIC_NODES,
  QUERY_TRAFFIC_NODES_TOTALS
} from './models';

/**
 * Get Traffic Nodes Query
 */
export const GetTrafficNodesQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_TRAFFIC_NODES,
    variables: { filter: { company: CoreConfig.company.dName } }
  };
});

/**
 * Get Traffic Nodes Totals Query
 */
export const GetTrafficNodesTotalsQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_TRAFFIC_NODES_TOTALS,
    variables: { filter: { company: CoreConfig.company.dName } }
  };
});
