import React from 'react';
import { cloneDeep } from 'lodash';
import { DocumentNode, FetchPolicy } from '@apollo/client';

import { QUERY_SEARCH_COMPANY } from 'Graph';

import CompaniesSelectorBase from './SelectorBase';

import Candidates from './Candidates';

import { CompaniesResultsFilter } from '../factories/';
import { ListSwitchState } from '../interfaces/';

import { COMPANIES_SEARCHING_MESSAGE, COMPANIES_SEARCHING_NO_MATCHES, COMPANIES_NETWORK_ERROR, LIST_SWITCH_STATE } from '../constants';

import { CoreConfigCompany, CoreGraphDataFactory, CoreBusyIconWithMessage } from '@neustar/core-ui';
import { IsEmpty, WaitForDelay } from '@neustar/core-ui/lib/utils/';

/**
 * Search Switch Component
 * @export
 * @class SearchListSwitchComponent
 * @extends {CompaniesSelectorBase<{}, ListSwitchState> }
 */
export default class SearchListSwitchComponent extends CompaniesSelectorBase<{}, ListSwitchState> {
  /**
   * State
   * @type {ListSwitchState}
   * @memberof SearchListSwitchComponent
   */
  public readonly state: ListSwitchState = cloneDeep(LIST_SWITCH_STATE);

  /**
   * Observable
   * @protected
   * @memberof SearchListSwitchComponent
   */
  protected observable = null!;

  /**
   * OnMount - LifeCycle Hook
   * @override
   * @memberof SearchListSwitchComponent
   */
  public OnMount = (): void => {
    const {
      context: { candidate },
    } = this;
    this.fetch(candidate);
  };

  /**
   * OnUnmount - LifeCycle Hook
   * @override
   * @memberof SearchListSwitchComponent
   */
  public OnUnmount = (): void => {
    if (this.observable) {
      this.observable.unsubscribe();
    }
  };

  /**
   * Render
   * @returns
   * @memberof SearchListSwitchComponent
   */
  public render() {
    const {
      fetch,
      context: { candidate },
      state: { error, loading, items },
    } = this;

    if (error) {
      return (
        <figure>
          <label>
            <nux-button-primary
              size="small"
              onClick={() => {
                fetch(candidate);
              }}
            >
              retry
            </nux-button-primary>
          </label>
          <CoreBusyIconWithMessage icon={false} message={COMPANIES_NETWORK_ERROR} />
        </figure>
      );
    }

    if (loading) {
      return (
        <figure>
          <CoreBusyIconWithMessage icon={true} message={COMPANIES_SEARCHING_MESSAGE} />
        </figure>
      );
    }

    if (IsEmpty(items)) {
      return (
        <figure>
          <CoreBusyIconWithMessage icon={false} message={COMPANIES_SEARCHING_NO_MATCHES} />
        </figure>
      );
    }

    return <Candidates items={items} />;
  }

  /**
   * Fetch
   * @protected
   * @param {string} candidate
   * @memberof SearchListSwitchComponent
   */
  protected fetch = (candidate: string): void => {
    const {
      context: { client },
    } = this;

    if (this.observable) {
      this.observable.unsubscribe();
    }

    const query: DocumentNode = QUERY_SEARCH_COMPANY;
    const fetchPolicy: FetchPolicy = 'no-cache';

    const dName: string = CoreConfigCompany.DNAME;
    const dNamePrefix: string = candidate;
    const variables: HashMap<any> = { company: { dName }, allCustomersFilter: { dNamePrefix } };

    this.observable = client.watchQuery({ query, variables, fetchPolicy }).subscribe(({ error, loading, data }: Partial<CoreGraphResultType>) => {
      if (error) {
        WaitForDelay().then(() => {
          if (!this.listener.signal.aborted) {
            this.setState({ error, items: [], loading: false });
          }
        });
      }
      const src = CoreGraphDataFactory({ loading, data }, 'company.allCustomers.results');
      const results = CompaniesResultsFilter(src);
      WaitForDelay().then(() => {
        if (!this.listener.signal.aborted) {
          this.setState({ items: results, error: null, loading });
        }
      });
    });
  };

  /**
   * Subscriber
   * @protected
   * @memberof SearchListSwitchComponent
   */
  protected Subscriber = null;
}
