import React from 'react';
import { cloneDeep } from 'lodash';
import { DocumentNode, FetchPolicy } from '@apollo/client';

import { QUERY_GET_COMPANIES_NESTED } from 'Graph';

import CompaniesSelectorBase from './SelectorBase';

import ListSwitch from './ListSwitch';
import { CompaniesResultsFilter } from '../factories/';
import { CompaniesListItemProps, CompaniesListItemState, CompaniesListItemShape } from '../interfaces/';
import { COMPANIES_LIST_ITEM_PROPS, COMPANIES_LIST_ITEM_STATE, COMPANIES_RESET_EVENT } from '../constants';

import { CoreConfig, CoreGraphDataFactory } from '@neustar/core-ui';

import { MergeClassNames, IsEmpty } from '@neustar/core-ui/lib/utils/';

/**
 * List Item Component
 * @export
 * @class ListItemComponent
 * @extends {CompaniesSelectorBase<CompaniesListItemProps, CompaniesListItemState>}
 */
export default class ListItemComponent extends CompaniesSelectorBase<CompaniesListItemProps, CompaniesListItemState> {
  /**
   * Default Props
   * @static
   * @type {CompaniesSwitchProps}
   * @memberof ListSwitchComponent
   */
  public static defaultProps: CompaniesListItemProps = cloneDeep(COMPANIES_LIST_ITEM_PROPS);

  /**
   * State
   * @type {CompaniesListItemState}
   * @memberof ListItemComponent
   */
  public readonly state: CompaniesListItemState = cloneDeep(COMPANIES_LIST_ITEM_STATE);

  /**
   * Observable
   * @protected
   * @memberof CompaniesSelectorFormComponent
   */
  protected observable = null!;

  /**
   * OnMount - LifeCycle Hook
   * @override
   * @memberof ListItemComponent
   */
  public OnMount = (): void => {
    const { context, props: { item: { dName } }} = this;
    const query: DocumentNode = QUERY_GET_COMPANIES_NESTED;
    const fetchPolicy: FetchPolicy = 'no-cache';
    const variables: HashMap<any> = { company: { dName } };
    this.observable = context.client.watchQuery({ query, variables, fetchPolicy }).subscribe(({ loading, data }: Partial<CoreGraphResultType>) => {
      const src = CoreGraphDataFactory({ loading, data }, 'company.customers') as Array<CompaniesListItemShape>;
      const results = CompaniesResultsFilter(src);
      if (!this.listener.signal.aborted) {
        this.setState({ customers: results, looking: false });
      }
    });
  };

  /**
   * OnUnmount - LifeCycle Hook
   * @override
   * @memberof ListItemComponent
   */
  public OnUnmount = (): void => {
    if (this.observable) {
      this.observable.unsubscribe();
    }
  };

  /**
   * Render
   * @memberof ListItemComponent
   */
  public render() {
    const { OnToggleClick, OnSelectClick, state: { active, looking, customers }, props: { item: { dName, isReseller } } } = this;

    const rtitle: string = isReseller ? 'Reseller' : 'Direct Customer';
    const disabled: boolean = IsEmpty(customers);
    const clz = MergeClassNames('', { active });
    const tclz = MergeClassNames('toggle', { looking });
    const rclz = MergeClassNames('', { reseller: isReseller, customer: !isReseller });

    return (
      <>
        <dt className={clz}>
          <button type="button" className={tclz} onClick={OnToggleClick} title="Toggle List" disabled={disabled}>
            &nbsp;
          </button>
          <span className={rclz} title={rtitle}>
            {dName}
          </span>
          <button type="button" className="select" onClick={OnSelectClick} title="Use">
            &nbsp;
          </button>
        </dt>
        {active ? (
          <dd className={clz}>
            <ListSwitch items={customers} />
          </dd>
        ) : null}
      </>
    );
  }

  /**
   * On Toggle Click
   * @protected
   * @memberof ListItemComponent
   */
  protected OnToggleClick = (evt: any): void => {
    const { state } = this;
    const active: boolean = !state.active;
    this.setState({ active });
  };

  /**
   * On Select Click
   * @protected
   * @memberof ListItemComponent
   */
  protected OnSelectClick = (evt: any): void => {
    const { context, props: { item: { dName } } } = this;
    CoreConfig.company.dName = dName;
    context.term = dName;
    context.valid = true;
    context.open = false;
    context.populated = false;
    context.emit(COMPANIES_RESET_EVENT);
    context.emit();
  };

  /**
   * Subscriber
   * @protected
   * @memberof ListItemComponent
   */
  protected Subscriber = null;
}
