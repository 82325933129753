import { CoreConfigApp, CoreConfigMeta, CoreConfigHeader, CoreConfigFooter, THEMES, THEME_LIGHT, SP_LOGO_DARK, SP_LOGO_LIGHT } from '@neustar/core-ui';
import { IsHashMap } from '@neustar/core-ui/lib/utils';

/**
 * Apply Domain White Label - legacy
 * @async
 * @export
 * @param {HashMap<any>} src
 */
export async function ApplyDomainWhiteLabel(src: HashMap<any>): Promise<void> {
  if (!src || !Object.keys(src).length) {
    return void 0;
  }

  const {
    enabled,
    bottom_left_label,
    bottom_right_label,
    favicon,
    footer_links,
    header_logo,
    hostname,
    legal_notices,
    login_marquee_items,
    product_name,
    support_url,
    theme,
    nux_theme,
  } = src;

  if (enabled) {
    // adds whitelabel class to html
    CoreConfigMeta.WHITELABELED = true;

    // Header
    if (header_logo) {
      CoreConfigHeader.LOGO = header_logo;
      CoreConfigApp.PDF_HEADER_IMAGE = header_logo;
    } else {
      CoreConfigApp.PDF_HEADER_IMAGE = null;
    }

    if (product_name) {
      CoreConfigHeader.LOGO_TITLE = product_name;
      CoreConfigHeader.PRODUCT_NAME = product_name;
      CoreConfigFooter.PRODUCT_NAME = product_name;
    }

    if (hostname) {
      CoreConfigHeader.HOSTNAME = hostname;
    }

    if (support_url) {
      CoreConfigHeader.SUPPORT_URL = support_url;
    }

    // Footer Left
    if (legal_notices || bottom_left_label) {
      CoreConfigFooter.COPYRIGHT = legal_notices || bottom_left_label;
    }

    if (footer_links && footer_links.length) {
      CoreConfigFooter.LINKS = footer_links;
    }

    // Footer Right
    if (bottom_right_label) {
      CoreConfigFooter.CONTACT_INFORMATION = bottom_right_label;
    }

    const wlFavicon: string =
      'iVBORw0KGgoAAAANSUhEUgAAAsAAAAGMAQMAAADuk4YmAAAAA1BMVEX///+nxBvIAAAAAXRSTlMAQObYZgAAADlJREFUeF7twDEBAAAAwiD7p7bGDlgYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAwAGJrAABgPqdWQAAAABJRU5ErkJggg==';
    if (favicon && IsHashMap(favicon)) {
      for (const size in favicon) {
        if (!favicon[size]) {
          favicon[size] = wlFavicon;
        }
      }
      CoreConfigMeta.FAVICON = favicon;
    } else {
      CoreConfigMeta.FAVICON = {
        '57': wlFavicon,
        '60': wlFavicon,
        '72': wlFavicon,
        '76': wlFavicon,
        '114': wlFavicon,
        '120': wlFavicon,
        '144': wlFavicon,
        '152': wlFavicon,
        '180': wlFavicon,
        '16': wlFavicon,
        '32': wlFavicon,
        '194': wlFavicon,
      };
    }

    if (login_marquee_items) {
      CoreConfigMeta.MARQUEE = login_marquee_items;
    }

    if (nux_theme) {
      CoreConfigMeta.NUXTHEME = nux_theme;
    }

    // Meta
    if (theme) {
      const THEME = `theme-${theme}`;

      if (!!~THEMES.indexOf(THEME)) {
        CoreConfigMeta.THEME = THEME;

        if (!header_logo) {
          switch (CoreConfigMeta.THEME) {
            case THEME_LIGHT:
              if (CoreConfigHeader.LOGO === SP_LOGO_DARK) {
                CoreConfigHeader.LOGO = SP_LOGO_LIGHT;
              }
              break;
            default:
              break;
          }
        }
      }
    }
  }

  return void 0;
}

/**
 * Apply White Label
 * @async
 * @export
 * @param {HashMap<any>} src
 */
export async function ApplyWhiteLabel(src: HashMap<any>): Promise<void> {
  if (!src || !Object.keys(src).length) {
    return void 0;
  }

  const { enabled, theme, nux_theme } = src;

  if (enabled) {
    if (nux_theme) {
      CoreConfigMeta.NUXTHEME = nux_theme;
    }
    // #SPPROJ-9264 - prevent user whitelabel from overwriting domain whitelabel
    // while allowing theme change.
    // Meta
    if (theme) {
      const THEME = `theme-${theme}`;
      if (!!~THEMES.indexOf(THEME)) {
        CoreConfigMeta.THEME = THEME;
        if (!CoreConfigMeta.WHITELABELED) {
          switch (CoreConfigMeta.THEME) {
            case THEME_LIGHT:
              CoreConfigHeader.LOGO = SP_LOGO_LIGHT;
              break;
            default:
              break;
          }
        }
      }
    }

    // adds whitelabel class to html
    CoreConfigMeta.WHITELABELED = true;
  }

  return void 0;
}
