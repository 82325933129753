import { IsEmpty, InitialCap, SentenceCap } from '@neustar/core-ui/lib/utils/';

/**
 * Enum Value To Label
 * @export
 * @param {string} value
 * @returns {string}
 * Human Readable string from enumerated token.
 */
export function EnumValueToLabel(value: string): string {
  if (IsEmpty(value)) {
    return '';
  }

  const SHORT: HashMap<string> = {
    REQ: 'Request',
    RESP: 'Response',
  };
  const ACRONYMS: string[] = [
    'ANSI',
    'ASN',
    'ATTR',
    'ATTRS',
    'BODY',
    'CSRF',
    'DNS',
    'DOS',
    'ENV',
    'ERR',
    'FAULT',
    'FAULTCODE',
    'LRTM',
    'HDR',
    'HTR',
    'HTTP',
    'INV',
    'IP',
    'NIL',
    'NO',
    'PCRE',
    'POST',
    'REDIRECT',
    'RFC',
    'RPC',
    'SHA1',
    'SHA256',
    'SOAP',
    'SOAPACTION',
    'SOAPBIND',
    'SOAPFAULT',
    'SQL',
    'SSL',
    'TCP',
    'UDP',
    'URI',
    'URL',
    'WSDL',
    'WSI',
    'XML',
    'XSS',
  ];
  const LC: string[] = ['BY', 'DOT', 'IS', 'NOT', 'NOTATION', 'ON', 'OR'];
  const SC: HashMap<string> = { AND: '&' };

  value = value.replace(/(\d+)_(\d+)/g, '$1.$2');

  return value
    .split('_')
    .map((v) => {
      if (!!~ACRONYMS.indexOf(v)) {
        return v;
      }
      if (!!~LC.indexOf(v)) {
        return v.toLowerCase();
      }
      if (v in SHORT) {
        v = SHORT[v];
      }
      if (v in SC) {
        v = SC[v];
      }
      return InitialCap(v);
    })
    .join(' ');
}

/**
 * Enum To Options
 * @export
 * @param {HashMap<any>} src
 * @param {string[]} [skip=[]]
 * @param {boolean} [titled=[]]
 * @returns {CoreFormOptionsType}
 * @description
 * Collects and parses Enumerated sets into a Human Readable Dictionary for Select Menu Options.
 */
export function EnumToOptions(src: HashMap<any>, skip: string[] = [], titled: boolean = false): CoreFormOptionsType {
  const arr: CoreFormOptionsType = [];
  let label: string | string[];

  for (const value in src) {
    if (!!~skip.indexOf(value)) {
      continue;
    }

    label = SentenceCap(EnumValueToLabel(value));
    if (titled) {
      arr.push({ value, label, title: label });
    } else {
      arr.push({ value, label });
    }
  }

  return arr;
}
