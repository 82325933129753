import gql from 'graphql-tag';

export const QUERY_EVENT_TRAFFIC = gql`
  query GetEventTrafficDetailQuery(
    $fields: [TrafficField!] = [IN_BITS_PER_SECOND, MITIGATED_BITS_PER_SECOND, OUT_BITS_PER_SECOND]
    $metrics: [TrafficMetric!] = [AVERAGE]
    $from: Time!
    $to: Time
    $filter: TrafficFilterInput
    $groupBy: [TrafficGroupBy!] = [{ dimension: MINUTE, timeMultiplier: 1 }]
    $sortBy: [TrafficSortBy!] = [{ dimension: MINUTE, direction: ASCENDING }]
  ) {
    traffic(fields: $fields, metrics: $metrics, from: $from, to: $to, filter: $filter, groupBy: $groupBy, sortBy: $sortBy) {
      companyDName
      data {
        id
        field 
        values {
          ... on TrafficByTime {
            ts
            v
          }
        }
      }
    }
  }
`;

export const SUBSCRIPTION_EVENT_TRAFFIC = gql`
  subscription GetEventTrafficDetailSubscription(
    $fields: [TrafficField!] = [IN_BITS_PER_SECOND, MITIGATED_BITS_PER_SECOND, OUT_BITS_PER_SECOND]
    $metrics: [TrafficMetric!] = [AVERAGE]
    $timeSpanMinutes: UnsignedInt32! = 1440
    $intervalMinutes: UnsignedInt32 = 1
    $filter: TrafficFilterInput
    $groupBy: [TrafficGroupBy!] = [{ dimension: MINUTE, timeMultiplier: 1 }]
    $sortBy: [TrafficSortBy!] = [{ dimension: MINUTE, direction: ASCENDING }]
  ) {
    traffic(fields: $fields, metrics: $metrics, timeSpanMinutes: $timeSpanMinutes, intervalMinutes: $intervalMinutes, filter: $filter, groupBy: $groupBy, sortBy: $sortBy) {
      companyDName
      data {
        id
        field
        values {
          ... on TrafficByTime {
            ts
            v
          }
        }
      }
    }
  }
`;