import { CoreSyncWebStorage } from '@neustar/core-ui';
import { NewExperienceToggleServiceShape } from '../interfaces';

/**
 * New Experience Toggle Service
 * @export
 * @class NewExperienceToggleService
 */
export default class NewExperienceToggleService implements NewExperienceToggleServiceShape {
  /**
   * storage name
   * @private
   * @type {string}
   * @memberof NewExperienceToggleService
   */
  private _storage_name: string = null;

  /**
   * storage
   * @private
   * @memberof NewExperienceToggleService
   */
  private _storage: CoreSyncWebStorageType;

  /**
   * Subscribers
   * @private
   * @type {Map<string, Func<void, any>>}
   * @memberof NewExperienceToggleService
   */
  private _subscribers: Map<string, Func<void, any>> = new Map<string, Func<void, any>>();

  /**
   * Creates an instance of NewExperienceToggleService
   * @param {string} storage_name
   * @memberof NewExperienceToggleService
   */
  constructor(storage_name: string) {
    this._storage_name = storage_name;
    this._storage = new CoreSyncWebStorage(this._storage_name);
    if (!this._storage.get()) {
      this._storage.init({});
    }
  }

  /**
   * new experience - setter
   * @memberof NewExperienceToggleService
   */
  public set new_experience(new_experience) {
    this.storage = { new_experience };
  }

  /**
   * new experience - getter
   * @readonly
   * @memberof NewExperienceToggleService
   */
  public get new_experience() {
    return this.storage.new_experience;
  }

  /**
   * Storage - setter
   * @private
   * @memberof NewExperienceToggleService
   */
  private set storage(values: HashMap<any>) {
    const { storage } = this;
    this._storage.set(Object.assign({}, storage, values));
  }

  /**
   * Storage - getter
   * @readonly
   * @private
   * @memberof NewExperienceToggleService
   */
  private get storage() {
    return this._storage.get() as any;
  }

  /**
   * Subscribe
   * @param {string} name
   * @memberof NewExperienceToggleService
   */
  public subscribe(name: string, callback: Func<void, boolean>): void {
    this._subscribers.set(name, callback);
  }

  /**
   * Unsubscribe
   * @param {string} name
   * @memberof NewExperienceToggleService
   */
  public unsubscribe(name: string): void {
    if (this._subscribers.has(name)) {
      this._subscribers.delete(name);
    }
  }

  /**
   * Broadcast
   * @memberof NewExperienceToggleService
   */
  public broadcast() {
    for (const callback of this._subscribers.values()) {
      callback();
    }
  }
}
