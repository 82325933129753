import React, { Component } from 'react';

import { TableEmptyProps, TableEmptyInstanceShape } from '../interfaces/';

import CoreTableContext from '@neustar/core-ui/lib/shared/modules/tables/context';

/**
 * Table Empty Component
 * @export
 * @class TableEmptyComponent
 * @extends {Component<TableEmptyProps, {}>}
 */
export default class TableEmptyComponent extends Component<TableEmptyProps, {}> {
  /**
   * Display Name
   * @static
   * @memberof TableEmptyComponent
   */
  public static displayName = 'TableEmptyComponent';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof TableEmptyComponent
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * Instance
   * @protected
   * @type {TableEmptyInstanceShape}
   * @memberof TableEmptyComponent
   */
  protected instance: TableEmptyInstanceShape = null!;

  /**
   * Creates an instance of TableEmptyComponent.
   * @param {TableEmptyProps} props
   * @memberof TableEmptyComponent
   */
  constructor(props: TableEmptyProps) {
    super(props);
    this.init(props);
  }

  /**
   * LifeCycle Hook
   * @memberof TableEmptyComponent
   */
  public componentDidMount() {
    const { context } = this;
    context.subscribe(TableEmptyComponent.displayName, this);
  }

  /**
   * LifeCycle Hook
   * @memberof CoreTableEmptyContainer
   */
  public componentWillUnmount() {
    const { context } = this;
    context.unsubscribe(TableEmptyComponent.displayName);
  }

  /**
   * Render
   * @return {*}
   * @memberof TableEmptyComponent
   */
  public render() {
    const { looking, message, empty } = this.instance;
    return looking ? <>{message}</> : <>{empty}</>;
  }

  /**
   * Init
   * @private
   * @param {TableEmptyProps} props
   * @memberof TableEmptyComponent
   */
  private init = (props: TableEmptyProps): void => {
    try {
      if (!('instance' in props)) {
        throw new ReferenceError(`missing prop for TableEmptyInstance.`);
      }

      this.instance = props.instance;
    } catch (err: any) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(err.message || err);
      }
    }
  };
}
