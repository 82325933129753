import React, { Component, FormEvent } from 'react';

import { QUERY_CHECK_COMPANY, QUERY_CHECK_COMPANY_POPULATED } from 'Graph';

import CompaniesContext from '../context';

import { SearchIndicator, SelectorButtons, ToggleButtons, SelectorList } from '../components/';
import { CompaniesServiceShape } from '../interfaces/';
import { CompaniesListMode } from '../enums/';
import { COMPANIES_SELECT_EVENT, COMPANIES_RESET_EVENT } from '../constants';

import { CoreConfig, CoreFormService, CoreForm, CoreFormGroupInline, CoreInput, CoreGraphDataFactory } from '@neustar/core-ui';
import { IsNull, WaitForDelay } from '@neustar/core-ui/lib/utils/';

/**
 * Companies Selector Form Component
 * @export
 * @class CompaniesSelectorFormComponent
 * @extends {Component<{}, {}>}
 */
export default class CompaniesSelectorFormComponent extends Component<{}, {}> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CompaniesContext>}
   * @memberof CompaniesSelectorFormComponent
   */
  public static contextType: Readonly<typeof CompaniesContext> = CompaniesContext;

  /**
   * Populated Subscription
   * @protected
   * @memberof CompaniesSelectorFormComponent
   */
  protected populated_observable = null!;

  /**
   * Search Subscription
   * @protected
   * @memberof CompaniesSelectorFormComponent
   */
  protected search_observable = null!;

  /**
   * Form
   * @protected
   * @type {CoreFormServiceType}
   * @memberof CompaniesSelectorFormComponent
   */
  protected form: CoreFormServiceType = new CoreFormService();

  /**
   * Creates an instance of CompaniesSelectorFormComponent.
   * @param {GenericPropType} props
   * @param {CompaniesServiceShape} context
   * @memberof CompaniesSelectorFormComponent
   */
  constructor(props: GenericPropType, context: CompaniesServiceShape) {
    super(props);
    const source = { term: context.term };
    this.form.use({ source });
  }

  /**
   * LifeCycle Hook
   * @memberof CompaniesSelectorFormComponent
   */
  public componentDidMount() {
    const { CheckCompanyPopulated, context, Subscriber } = this;
    context.subscribe(Subscriber, COMPANIES_SELECT_EVENT, COMPANIES_RESET_EVENT);
    CheckCompanyPopulated();
  }

  /**
   * LifeCycle Hook
   * @memberof CompaniesSelectorFormComponent
   */
  public componentDidUpdate() {
    const { CheckCompanyPopulated, context } = this;
    WaitForDelay().then(() => {
      CheckCompanyPopulated(context.term);
    });
  }

  /**
   * LifeCycle Hook
   * @memberof CompaniesSelectorFormComponent
   */
  public componentWillUnmount() {
    const { context, Subscriber } = this;
    context.unsubscribe(Subscriber, COMPANIES_SELECT_EVENT, COMPANIES_RESET_EVENT);
    if (this.search_observable) {
      this.search_observable.unsubscribe();
    }
    if (this.populated_observable) {
      this.populated_observable.unsubscribe();
    }
  }

  /**
   * Render
   * @memberof CompaniesSelectorFormComponent
   */
  public render() {
    const { OnSubmit, OnChange, form } = this;
    return (
      <CoreForm source={form} onSubmit={OnSubmit}>
        <div className="tree-container">
          <CoreFormGroupInline>
            <ToggleButtons />
            <CoreInput name="term" onChange={OnChange} placeholder="company..." autoComplete="off" className="auto-width uc">
              <SearchIndicator />
            </CoreInput>
            <SelectorButtons />
          </CoreFormGroupInline>
          <SelectorList />
        </div>
      </CoreForm>
    );
  }

  /**
   * Check Company Populated
   * @protected
   * @memberof CompaniesSelectorFormComponent
   */
  protected CheckCompanyPopulated = (dName: string = CoreConfig.company.dName): void => {
    const { context } = this;

    // clear old populated_observable;
    if (this.populated_observable) {
      this.populated_observable.unsubscribe();
    }

    const variables: HashMap<any> = { company: { dName } };

    context.valid = IsNull(context.candidate);

    const dNamePrefix: string = context.candidate;

    Object.assign(variables, { allCustomersFilter: { dNamePrefix } });

    // start observable
    this.populated_observable = context.client.watchQuery({ query: QUERY_CHECK_COMPANY_POPULATED, variables }).subscribe(({ loading, data }: CoreGraphResultType) => {
      const { totalItems } = CoreGraphDataFactory({ loading, data }, 'company.allCustomers.pageInfo', { totalItems: 0 }) as HashMap<any>;
      // in cases where search is updated while query is running, we double check valid before saying `populated`
      context.populated = context.valid ? totalItems > 0 : false;
      context.emit();
    });
  };

  /**
   * On Submit
   * @protected
   * @param {FormEvent<any>} evt
   * @memberof CompaniesSelectorFormComponent
   */
  protected OnSubmit = (evt: FormEvent<any>): void => {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.context.valid && !this.context.term_equals_dname) {
      this.context.submit();
    }
  };

  /**
   * On Change
   * @protected
   * @param {FormEvent<any>} evt
   * @param {CoreFormServiceType} form
   * @param {string} value
   * @memberof CompaniesSelectorFormComponent
   */
  protected OnChange = (evt: FormEvent<any>, form: CoreFormServiceType, value: string): void => {
    const { CheckCompanyPopulated, context } = this;
    const { client } = context;

    // clear old search_observable;
    if (this.search_observable) {
      this.search_observable.unsubscribe();
    }

    const dName: string = value.toUpperCase();

    // only run search for substantial candifdates.
    if (value.length <= 3) {
      context.mode = CompaniesListMode.NONE;
      context.valid = false;
      context.candidate = dName;
      context.open = false;
      context.populated = false;
      context.emit();
      return void 0;
    }

    // we are searching
    if (dName !== context.term) {
      context.mode = CompaniesListMode.NONE;
      context.valid = false;
      context.open = false;
      context.populated = false;
      context.emit();
    }

    // start observable
    this.search_observable = client.watchQuery({ query: QUERY_CHECK_COMPANY, variables: { company: { dName } } }).subscribe(({ loading, data }: CoreGraphResultType) => {
      const { id } = CoreGraphDataFactory({ loading, data }, 'company') as HashMap<any>;

      if (id) {
        context.mode = CompaniesListMode.NESTED;
        context.valid = true;
        context.term = dName;
        context.candidate = null;
        context.open = false;
        CheckCompanyPopulated(dName);
      } else {
        context.mode = CompaniesListMode.SEARCH;
        context.valid = false;
        context.term = CoreConfig.company.dName;
        context.candidate = dName;
        context.open = true;
        context.populated = true;
      }

      context.emit();
    });
  };

  /**
   * Subscriber
   * @protected
   * @memberof CompaniesSelectorFormComponent
   */
  protected Subscriber = (evt: string, dName: string): void => {
    const { form } = this;
    const value = form.value('term');
    if (value !== dName) {
      form.update('term', dName);
    }
    this.forceUpdate();
  };
}
