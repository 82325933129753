import gql from 'graphql-tag';

export const QUERY_NOTIFICATION_RECORDS_LIST = gql`
  query GetNotificationRecordsList(
    $company: CompanyFilterInput
    $from: Time!
    $notificationRecordsFilter: NotificationRecordFilterInput
    $page: UnsignedInt32
    $perPage: UnsignedInt32
    $sortBy: [NotificationSortBy!]
    $to: Time
  ) {
    company(filter: $company) {
      notificationRecords(from: $from, filter: $notificationRecordsFilter, page: $page, perPage: $perPage, sortBy: $sortBy, to: $to) {
        pageInfo {
          itemsPerPage
          pageNumber
          totalItems
        }
        results {
          category
          createdAt
          id
          reads
          subject
          content
          updatedAt
        }
      }
    }
  }
`;

export const QUERY_NOTIFICATION_CONFIGURATION = gql`
  query GetNotificationConfiguration(
    $company: CompanyFilterInput
    $notificationConfigurationsFilter: NotificationConfigurationFilterInput
    $page: UnsignedInt32
    $perPage: UnsignedInt32
    $sortBy: [NotificationSortBy!]
  ) {
    company(filter: $company) {
      dName
      notificationConfigurations(filter: $notificationConfigurationsFilter, page: $page, perPage: $perPage, sortBy: $sortBy) {
        pageInfo {
          itemsPerPage
          pageNumber
          totalItems
        }
        results {
          categories {
            category
            email
            slack
            webhook
            label
            description
          }
          ... on CompanyNotificationConfiguration {
            channel {
              email {
                emailAddresses
              }
              slack {
                URL
                channel
              }
              webhook {
                URL
              }
            }
          }
          createdAt
          id
          updatedAt
        }
      }
    }
  }
`;

export const QUERY_USER_NOTIFICATION_CONFIGURATION = gql`
  query GetNotificationConfigurationForUser(
    $company: CompanyFilterInput
    $notificationConfigurationsFilter: NotificationConfigurationFilterInput
    $page: UnsignedInt32
    $perPage: UnsignedInt32
    $sortBy: [NotificationSortBy!]
  ) {
    company(filter: $company) {
      dName
      notificationConfigurations(filter: $notificationConfigurationsFilter, page: $page, perPage: $perPage, sortBy: $sortBy) {
        pageInfo {
          itemsPerPage
          pageNumber
          totalItems
        }
        results {
          categories {
            category
            email
          }
          createdAt
          id
          updatedAt
        }
      }
    }
  }
`;

export const MUTATION_NOTIFICATION_CONFIG_UPDATE = gql`
  mutation UpdateCompanyNotificationConfiguration($input: UpdateCompanyNotificationConfigurationInput!) {
    updateCompanyNotificationConfiguration(input: $input) {
      configuration {
        id
      }
    }
  }
`;

export const MUTATION_USER_NOTIFICATION_CONFIG_UPDATE = gql`
  mutation UpdateUserNotificationConfiguration($input: UpdateUserNotificationConfigurationInput!) {
    updateUserNotificationConfiguration(input: $input) {
      configuration {
        id
      }
    }
  }
`;

export const MUTATION_SEND_TEST_NOTIFICATION = gql`
  mutation SendTestNotification($input: SendTestNotificationInput!) {
    sendTestNotification(input: $input) {
      statusCode
    }
  }
`;
