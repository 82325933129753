import React, { PureComponent } from 'react';

import { IsLogoutQuery, IsSSOQuery } from 'App/factories/';

import { CoreAuth, CoreRedirect } from '@neustar/core-ui';

import { SearchToHashMap } from '@neustar/core-ui/lib/utils/';

/**
 * Index Module
 * @export
 * @class IndexModule
 * @extends {PureComponent<CoreRouteProps, {}>}
 */
export default class IndexModule extends PureComponent<CoreRouteProps, {}> {
  /**
   * Render
   * @returns
   * @memberof IndexModule
   */
  public render() {
    const {
      location: { search },
    } = this.props;
    const qobj: HashMap<string> = SearchToHashMap(search);

    if (IsLogoutQuery(qobj)) {
      CoreAuth.logout();
      return null;
    }

    if (IsSSOQuery(qobj)) {
      return <CoreRedirect from="/" to={`/manage/sso/${search}`} />;
    }

    return <CoreRedirect from="/" to="home" />;
  }
}
