import { CoreConfigPermissions } from '@neustar/core-ui';

export enum PermissionsEnum {
  MASQUERADE = 'masquerade:other',
  SOC_TOOLS = 'ui:soc-tools',

  USER_READ = 'user:read',
  USER_WRITE = 'user:write',
  USER_OTHER = 'user:other',

  PROXY_READ = 'proxy:read',
  PROXY_WRITE = 'proxy:write',
  PROXY_OTHER = 'proxy:other',

  COMPANY_READ = 'company:read',
  COMPANY_WRITE = 'company:write',
  COMPANY_OTHER = 'company:other',

  POLICY_READ = 'policy:read',
  POLICY_WRITE = 'policy:write',
  POLICY_OTHER = 'policy:other',

  CERT_READ = 'certificate:read',
  CERT_WRITE = 'certificate:write',
  CERT_OTHER = 'certificate:other',

  SSO_READ = 'sso:read',
  SSO_WRITE = 'sso:write',
  SSO_OTHER = 'sso:other',
}

/**
 * Permissions Plugin
 * @export
 * @returns {Promise<boolean|Error>}
 */
export default async function PermissionsPlugin(): Promise<boolean | Error> {
  try {
    CoreConfigPermissions.COMPLEX = [
      'alert',
      'audit',
      'bot',
      'certificate',
      'config-engine',
      'company',
      'devices',
      'mitigations',
      'nis',
      'policy',
      'proxy',
      'traffic',
      'vip-pools',
      'reports',
      'user',
      'sso'
    ];
    CoreConfigPermissions.SIMPLE = Object.values(PermissionsEnum);
    return true;
  } catch (err: any) {
    throw Error(err);
  }
}
