import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import { QUERY_ASSETS_MENU, QUERY_ASSETS_LIST, QUERY_ASSETS_STATUS, QUERY_ASSET, QUERY_CONFIG_CHANGES } from './models';

import { CONFIG_CHANGES_POLL_INTERVAL, VSERVER_STATUS_POLL_INTERVAL } from '../constants';


/**
 * Get Assets - menu
 */
export const GetAssetsMenuQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_ASSETS_MENU,
    variables: { company: { dName: CoreConfig.company.dName } },
  };
});

/**
 * Get Assets - list
 */
export const GetAssetsListQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_ASSETS_LIST,
    variables: { company: { dName: CoreConfig.company.dName } },
    fetchPolicy: 'no-cache',
  };
});

/**
 * Get Asset - id
 */
export const GetAssetQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_ASSET,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id }) => ({ proxy: { id } }),
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache'
  };
});

/**
 * Get Asset Status - id
 */
export const GetAssetStatusQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_ASSETS_STATUS,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id }) => ({ proxy: { id } }),
    pollInterval: VSERVER_STATUS_POLL_INTERVAL,
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache'
  };
});

/**
 * Get Config Changes Poll Query - list
 */
export const GetConfigChangesPollQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_CONFIG_CHANGES,
    variables: { company: { dName: CoreConfig.company.dName } },
    pollInterval: CONFIG_CHANGES_POLL_INTERVAL,
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache'
  };
});
