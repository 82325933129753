import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import {
  QUERY_MANAGE_SEARCH_COMPANIES
} from './models';

/**
 * Get Companies - list
 */
export const GetManageCompaniesSearchQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_MANAGE_SEARCH_COMPANIES,
    variables: { company: { dName: CoreConfig.company.dName }, filter: {} },
  };
});