import { CoreConfig, CoreGraphSocketConsumer } from '@neustar/core-ui';

import { SUBSCRIPTION_EVENT_TRAFFIC } from './models';

/**
 * Get Event Traffic Subscription
 */
export const GetEventTrafficSubscription = CoreGraphSocketConsumer(() => {
  return {
    subscription: SUBSCRIPTION_EVENT_TRAFFIC,
    variables: { filter: { companyDName: CoreConfig.company.dName } }
  };
});