import React from 'react';
import { cloneDeep } from 'lodash';
import { DocumentNode, FetchPolicy } from '@apollo/client';

import { QUERY_GET_COMPANIES_NESTED } from 'Graph';

import CompaniesSelectorBase from './SelectorBase';

import ListItems from './ListItems';

import { CompaniesResultsFilter } from '../factories/';
import { CompaniesListItemShape, ListSwitchState } from '../interfaces/';
import { COMPANIES_SELECT_EVENT, COMPANIES_RESET_EVENT, COMPANIES_NESTED_MESSAGE, COMPANIES_NESTED_NO_MATCHES, COMPANIES_NETWORK_ERROR, LIST_SWITCH_STATE } from '../constants';

import { CoreBusyIconWithMessage, CoreGraphDataFactory } from '@neustar/core-ui';
import { IsEmpty, WaitForDelay } from '@neustar/core-ui/lib/utils/';

/**
 * Nested Switch Component
 * @export
 * @class NestedListSwitchComponent
 * @extends {CompaniesSelectorBase<{}, ListSwitchState>}
 */
export default class NestedListSwitchComponent extends CompaniesSelectorBase<{}, ListSwitchState> {
  /**
   * State
   * @type {ListSwitchState}
   * @memberof NestedListSwitchComponent
   */
  public readonly state: ListSwitchState = cloneDeep(LIST_SWITCH_STATE);

  /**
   * Events
   * @protected
   * @type {Array<string>}
   * @memberof NestedListSwitchComponent
   */
  protected events: Array<string> = [COMPANIES_SELECT_EVENT, COMPANIES_RESET_EVENT];

  /**
   * Observable
   * @protected
   * @memberof CompaniesSelectorFormComponent
   */
  protected observable = null!;

  /**
   * OnMount - LifeCycle Hook
   * @override
   * @memberof NestedListSwitchComponent
   */
  public OnMount = (): void => {
    const {
      context: { term: dName },
    } = this;
    this.fetch(dName);
  };

  /**
   * OnUnmount - LifeCycle Hook
   * @override
   * @memberof NestedListSwitchComponent
   */
  public OnUnmount = (): void => {
    if (this.observable) {
      this.observable.unsubscribe();
    }
  };

  /**
   * Render
   * @returns
   * @memberof NestedListSwitchComponent
   */
  public render() {
    const {
      fetch,
      context: { term: dName },
      state: { error, loading, items },
    } = this;

    if (error) {
      return (
        <figure>
          <label>
            <nux-button-primary
              onClick={() => {
                fetch(dName);
              }}
            >
              retry
            </nux-button-primary>
          </label>
          <CoreBusyIconWithMessage icon={false} message={COMPANIES_NETWORK_ERROR} />
        </figure>
      );
    }

    if (loading) {
      return (
        <figure>
          <CoreBusyIconWithMessage icon={true} message={COMPANIES_NESTED_MESSAGE} />
        </figure>
      );
    }

    if (IsEmpty(items)) {
      return (
        <figure>
          <CoreBusyIconWithMessage icon={false} message={COMPANIES_NESTED_NO_MATCHES} />
        </figure>
      );
    }

    return <ListItems items={items} />;
  }

  /**
   * Fetch
   * @protected
   * @param {strin} dName
   * @memberof NestedListSwitchComponent
   */
  protected fetch = (dName: string): void => {
    const {
      context: { client },
    } = this;

    if (this.observable) {
      this.observable.unsubscribe();
    }

    const query: DocumentNode = QUERY_GET_COMPANIES_NESTED;
    const fetchPolicy: FetchPolicy = 'no-cache';
    const variables: HashMap<any> = { company: { dName } };

    this.observable = client.watchQuery({ query, variables, fetchPolicy }).subscribe(({ error, loading, data }: Partial<CoreGraphResultType>) => {
      if (error) {
        WaitForDelay().then(() => {
          if (!this.listener.signal.aborted) {
            this.setState({ error, items: [], loading: false });
          }
        });
        return null;
      }
      const src = CoreGraphDataFactory({ loading, data }, 'company.customers') as Array<CompaniesListItemShape>;
      const results = CompaniesResultsFilter(src);
      WaitForDelay().then(() => {
        if (!this.listener.signal.aborted) {
          this.setState({ items: results, error: null, loading });
        }
      });
    });
  };

  /**
   * Subscriber
   * @protected
   * @memberof NestedListSwitchComponent
   */
  protected Subscriber = (evt: string, dName: string) => {
    this.fetch(dName);
  };
}
