import { CoreConfig, CoreGraphSocketConsumer } from '@neustar/core-ui';

import { SUBSCRIPTION_TRAFFIC_PERCENTILE95 } from './models';

/**
 * Get Traffic Percentage Subscription
 */
export const GetTrafficPercentile95Subscription = CoreGraphSocketConsumer(() => {
  return {
    subscription: SUBSCRIPTION_TRAFFIC_PERCENTILE95,
    variables: { filter: { companyDName: CoreConfig.company.dName } },
  };
});
