import { FetchPolicy } from '@apollo/client';
import {
  QUERY_REPORTS_CONFIGS_BASE,
  QUERY_REPORTS_DETAIL,
  QUERY_REPORT_RECURRING_CONFIG,
  QUERY_REPORT_ONE_TIME_CONFIG,
  QUERY_REPORT_ARCHIVES,
  QUERY_REPORTS_PDF,
  QUERY_REPORT_CONFIG_EMAILS,
  MUTATION_EXECUTIVE_REPORT_DELETE,
} from './models';

import { CoreConfig, CoreGraphQueryConsumer, CoreGraphMutationConsumer } from '@neustar/core-ui';
import { CORE_EPOCH_MINUTE } from '@neustar/core-ui/lib/utils/';

const pollInterval: number = CORE_EPOCH_MINUTE;
const fetchPolicy: FetchPolicy = 'no-cache';

export const GetReportsBaseQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_REPORTS_CONFIGS_BASE,
    variables: { company: { dName: CoreConfig.company.dName } },
  };
});

export const GetReportsDetailQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_REPORTS_DETAIL,
    variables: { company: { dName: CoreConfig.company.dName }, filter: { id: null } },
    transform: ({ id }) => ({ filter: { id, includeDisabled: true } }),
    fetchPolicy,
  };
});

export const GetReportRecurringConfigurationQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_REPORT_RECURRING_CONFIG,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id }) => ({ filter: { id } }),
    fetchPolicy,
  };
});

export const GetReportOneTimeConfigurationQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_REPORT_ONE_TIME_CONFIG,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id }) => ({ filter: { id } }),
    fetchPolicy,
  };
});

export const GetReportArchivesQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_REPORT_ARCHIVES,
    variables: { company: { dName: CoreConfig.company.dName } },
    pollInterval,
    fetchPolicy,
  };
});

export const GetReportsPDFQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_REPORTS_PDF,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id }) => ({ filter: { id } }),
    fetchPolicy: 'cache-first',
  };
});
export const GetReportsConfigEmailsQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_REPORT_CONFIG_EMAILS,
    variables: { company: { dName: CoreConfig.company.dName } },
    fetchPolicy: 'cache-first',
  };
});

export const PostDeleteReportMutation = CoreGraphMutationConsumer(() => {
  return {
    mutation: MUTATION_EXECUTIVE_REPORT_DELETE,
    variables: { company: { dName: CoreConfig.company.dName } },
  };
});
