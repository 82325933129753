import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import { QUERY_USERS, QUERY_USER_LIST } from './models';

/**
 * Get Users - list
 */
export const GetUsersQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_USERS,
    variables: { company: { dName: CoreConfig.company.dName } },
  };
});


/**
 * Get Users List - list
 */
 export const GetUsersListQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_USER_LIST,
    variables: { company: { dName: CoreConfig.company.dName }, filter: { includeDisabled: true } },
  };
});

