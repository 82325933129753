import React, { useContext, useEffect, useState } from 'react';

import { EVENT_STORAGE_NAME } from 'App/Events/constants';

import { NEW_EXPERIENCE_SWITCH } from '../constants';
import { NewExperienceToggleContext } from '../contexts';
import { GetNewExperienceStatus } from '../factories';
import { NewExperienceToggleContextType, NewExperienceToggleComponentProps } from '../interfaces';
import { CoreForm, CoreFormButton, CoreFormGroupInline, CoreHelp, CoreInputLabel, IsDefined, IsNull, HTMLQuadrantEnum } from '@neustar/core-ui';

/**
 * New Experience Toggle Component
 * @param {<NewExperienceToggleComponentProps>}
 * @returns {JSX.Element}
 */
const NewExperienceToggleComponent: React.FC<NewExperienceToggleComponentProps> = ({ onChange }): React.JSX.Element => {
  const context: NewExperienceToggleContextType = useContext(NewExperienceToggleContext);
  const [toggleClass, setToggleClass] = useState(`icon toggle-button`);
  const [newExperience, setNewExperience] = useState(false);

  const OnNewExperienceChange = () => {
    context.new_experience = !newExperience;
    context.broadcast();
    onChange();
  }
      
  useEffect(() => {
    const updateToggleClass = () => {
      const ne = GetNewExperienceStatus(EVENT_STORAGE_NAME);
      const toggleStatus = ne ? 'on' : 'off';
      setNewExperience(ne);
      setToggleClass(`icon toggle-button toggle-${toggleStatus}`);
    }
    updateToggleClass();
    if (!IsNull(context) && IsDefined(context.subscribe)) {
      context.subscribe(NEW_EXPERIENCE_SWITCH, () => updateToggleClass());
    }
    return () => context.unsubscribe(NEW_EXPERIENCE_SWITCH);
  }, [context]);

  return (
      <CoreForm id="ne-toggle-container">
        <CoreFormGroupInline>
          <CoreInputLabel key={`newexperience.toggle.${Date.now()}`} htmlFor="_">
            <CoreFormButton id="ne-toggle" title="New Experience" className={toggleClass} onClick={OnNewExperienceChange} /><span className="toggle-label">New Experience<NewExperienceHelpTooltip /></span>
          </CoreInputLabel>
        </CoreFormGroupInline>
      </CoreForm>
  );
}

export default NewExperienceToggleComponent;

/**
 * New Experience Help
 * @returns {*}
 */
const NewExperienceHelpTooltip = () => {
  return (
    <CoreHelp quadrant={HTMLQuadrantEnum.TOP_RIGHT} title={`New Experience`}>
      <p>The "old" experience displays mitigated, inbound, and outbound traffic statistics that are derived from sampled sflow data, offering valuable insights but with some limitations in accuracy. The "new" experience instead uses unsampled data, providing precise and faithful traffic statistics for mitigated and clean traffic return (i.e., outbound) traffic statistics. This enhanced experience is currently available exclusively to DDoS protection customers using GRE tunnels for clean traffic return.</p>
    </CoreHelp>
  );
};
