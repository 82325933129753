import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import { QUERY_AUDITLOGS } from './models';

/**
 * Get Auditlogs - list
 */
export const GetAuditlogsQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_AUDITLOGS,
    variables: { company: { dName: CoreConfig.company.dName } },
  };
});
