import React, { lazy } from 'react';
import { LocationProvider } from '@reach/router';
import { CoreMainRouter } from '@neustar/core-ui';
import IndexModule from './Index/';
import GoogleAnalytics from './components/GoogleAnalytics';

// CHILD MODULES - (code split)
const HomeModule = lazy(() => import('./Home/'));
const EventsModule = lazy(() => import('./Events/'));
const ConfigurationModule = lazy(() => import('./Configuration/'));
const ManagementModule = lazy(() => import('./Management'));
const AnalyticsModule = lazy(() => import('./Analytics/'));
const StatusCodeModule = lazy(() => import('./StatusCode/'));

const AppModule = () => {
  return (
    <LocationProvider>
      <GoogleAnalytics />
      <CoreMainRouter>
        <IndexModule path="/" />
        <HomeModule path="home" />
        <ManagementModule path="manage/*" />
        <EventsModule path="events/*" />
        <EventsModule path="event/:id" />
        <ConfigurationModule path="configuration/*" />
        <AnalyticsModule path="analytics/*" />
        <StatusCodeModule default path="*" />
      </CoreMainRouter>
    </LocationProvider>
  );
};

export default AppModule;
