import gql from 'graphql-tag';

export const ConfigChangesFrag = gql`
  fragment ConfigChangesFields on Company {
    configurationChanges(filter: { latest: true }) {
      id
      status
      statusText
      started
      ended
      createdAt
      company {
        shortname
      }
    }
  }
`;

export const CompanyPackagesFrag = gql`
  fragment CompanyPackagesFields on Company {
    proxyPackage {
      enabled
    }
    bgpPackage {
      enabled
    }
    detectionAndAlertingPackage {
      enabled
    }
    apiPackage {
      enabled
    }
  }
`;

export const CompanyPoliciesFrag = gql`
  fragment CompanyPoliciesFields on Company {
    policies {
      id
      name
    }
  }
`;

export const CompanyAssetsCertificatesFrag = gql`
  fragment CompanyAssetsCertificatesFields on Company {
    certificates {
      id
      name
      createdAt
    }
  }
`;

export const CompanyFrag = gql`
  fragment CompanyFields on Company {
    dName
    ...CompanyPoliciesFields
    ...CompanyAssetsCertificatesFields
  }
  ${CompanyPoliciesFrag}
  ${CompanyAssetsCertificatesFrag}
`;

export const ApplicationServicesFrag = gql`
  fragment ApplicationServicesFields on ApplicationService {
    port
    protocol
    origin
    monitor
  }
`;
