import './fusion.scss';

import { CoreConfigApp } from '@neustar/core-ui';

/**
 * Fusion Plugin
 * @export
 * @returns {Promise<boolean|Error>}
 */
export default async function FusionPlugin(): Promise<boolean | Error> {
  try {
    CoreConfigApp.FUSION_ENDPOINT = (window as any).__SPENV__['FUSION_ENDPOINT'];
    CoreConfigApp.FUSION_TIMEOUT = (window as any).__SPENV__['FUSION_TIMEOUT'] || 0;
    return true;
  } catch (err: any) {
    throw Error(err);
  }
}
