import React from 'react';
import { cloneDeep } from 'lodash';

import CompaniesSelectorBase from './SelectorBase';

import { CompaniesCandidateProps } from '../interfaces/';
import { COMPANIES_CANDIDATE_PROPS, COMPANIES_SELECT_EVENT } from '../constants';

import { CoreConfig } from '@neustar/core-ui';

/**
 * Candidate Component
 * @export
 * @class CandidateComponent
 * @extends {Component<CompaniesCandidateProps, CompaniesCandidateState>}
 */
export default class CandidateComponent extends CompaniesSelectorBase<CompaniesCandidateProps> {
  /**
   * Default Props
   * @static
   * @type {CompaniesSwitchProps}
   * @memberof ListSwitchComponent
   */
  public static defaultProps: CompaniesCandidateProps = cloneDeep(COMPANIES_CANDIDATE_PROPS);

  /**
   * Render
   * @memberof CandidateComponent
   */
  public render() {
    const { OnSelectClick, props: { item: { dName } } } = this;
    return (
      <>
        <dt>
          <span>{dName}</span>
          <button type="button" className="select" onClick={OnSelectClick} title="Use">
            &nbsp;
          </button>
        </dt>
      </>
    );
  }

  /**
   * On Select Click
   * @protected
   * @memberof CandidateComponent
   */
  protected OnSelectClick = (evt: any): void => {
    const { context, props: { item: { dName } } } = this;
    CoreConfig.company.dName = dName;
    context.term = dName;
    context.valid = true;
    context.open = false;
    context.candidate = null;
    context.populated = false;
    context.emit(COMPANIES_SELECT_EVENT);
    context.emit();
  };

  /**
   * Subscriber
   * @protected
   * @memberof CandidateComponent
   */
  protected Subscriber = null;
}
