import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';

import { CoreSelect } from '@neustar/core-ui';

import UsersContext from '../context';
import { FetchMoreUsersFactory } from '../factories/';
import { UsersListState } from '../interfaces/';
import { USERS_LIST_STATE } from '../constants';

/**
 * User List Select Component
 * @export
 * @class UserListSelectComponent
 * @extends {PureComponent<GenericPropType, UsersListState>}
 */
export default class UserListSelectComponent extends PureComponent<GenericPropType, UsersListState> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof ClientContext>}
   * @memberof PoliciesContainer
   */
  public static contextType: Readonly<typeof UsersContext> = UsersContext;

  /**
   * State
   * @type {UsersListState}
   * @memberof UserListSelectComponent
   */
  public readonly state: UsersListState = cloneDeep(USERS_LIST_STATE);

  /**
   * Abort Controller Listener
   * @private
   * @type {AbortController}
   * @memberof UserListSelectComponent
   */
  private _listener: AbortController;

  /**
   * LifeCycle Hook
   * @memberof UserListSelectComponent
   */
  public componentDidMount() {
    const {
      context: { variables, fetchMore },
    } = this;
    this._listener = new AbortController();

    FetchMoreUsersFactory(fetchMore, variables).then((opts: CoreFormOptionsType) => {
      if (!this.listener.signal.aborted) {
        this.setState({ opts });
      }
    });
  }
  /**
   * LifeCycle Hook
   * @memberof UserListSelectComponent
   */
  public componentWillUnmount() {
    this.listener.abort();
  }

  /**
   * Render
   * @returns
   * @memberof UserListSelectComponent
   */
  public render() {
    const {
      state: { opts },
      props: { name, options, filter, placeholder, children, ...rest },
    } = this;
    const $placeholder: string = opts.length ? 'Select...' : 'Loading...';
    const disabled: boolean = opts.length === 0;
    return <CoreSelect key={`.${Date.now()}`} name={name} options={opts} filter={true} placeholder={$placeholder} disabled={disabled} {...rest} />;
  }

  /**
   * Listener - getter
   * @readonly
   * @protected
   * @memberof UserListSelectComponent
   */
  public get listener() {
    return this._listener;
  }

}
