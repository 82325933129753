import React, { MouseEvent, ClassType } from 'react';
import { DocumentNode } from '@apollo/client';
import { cloneDeep } from 'lodash';

import { QUERY_CORPORATE_LINEAGE } from 'Graph';

import '../lineage.scss';

import {
  CoreGraphDataFactory,
  CoreGraphErrorHandler,
  CoreTooltip,
  CoreBusy
} from '@neustar/core-ui';

import { GetDeepHashMap, Noop } from '@neustar/core-ui/lib/utils';

/**
 * Corporate Lineage Cell Factory
 * @export
 * @param {ClassType<any, any, any>} Base
 * @return {*}  {*}
 */
export function CorporateLineageCellFactory(Base: ClassType<any, any, any>): any {
  const CORPORATE_LINEAGE_STATE = {
    lineage: ['loading...'],
    populated: false
  };

  type CorporateLineageCellState = Readonly<typeof CORPORATE_LINEAGE_STATE>;

  interface CorporateLineageCellProps {
    use: string;
    [prop: string]: any;
  } 
  
  return class CorporateLineageCell extends Base<CorporateLineageCellProps, CorporateLineageCellState> {
    /**
     * State
     * @type {CorporateLineageCellState}
     * @memberof CorporateLineageCell
     */
    public readonly state: CorporateLineageCellState = cloneDeep(CORPORATE_LINEAGE_STATE);
  
    /**
     * Render
     * @return {*} 
     * @memberof CorporateLineageCell
     */
    public render() {
      const { OnMouseOver, state: { lineage }, props: { use, ...rest }} = this;
  
      const { corporateName } = GetDeepHashMap(rest, use, {
        dName: '',
        corporateName: ''
      }, true);
  
      return (
        <div>
          <label className="lineage-container" htmlFor="_" onMouseOver={OnMouseOver}>
            {lineage.length ? (
              <CoreTooltip tooltip={lineage.join(' > ')}><span>{corporateName}</span></CoreTooltip>
            ) : (
              <span>{corporateName}</span>
            )}
          </label>
        </div>
      );
    }
  
    /**
     * On Mouse Over
     * @protected
     * @param {MouseEvent<any>} evt
     * @memberof CorporateLineageCell
     */
    protected OnMouseOver = (evt: MouseEvent<any>): void => {
      const { context: { graph }, state: { lineage: LINEAGE, populated: POPULATED }, props: { use, ...rest }} = this;
  
      // skip query if already populated
      if (POPULATED) {
        return void 0;
      }
  
      const { dName, corporateName } = GetDeepHashMap(rest, use, {
        dName: '',
        corporateName: ''
      });
  
      const query: DocumentNode  = QUERY_CORPORATE_LINEAGE;
      const variables = { company: { dName } };
  
      if (!LINEAGE.length) {
        this.setState({ lineage: ['loading...'] });
      }
  
      // temporarily shuts off wait mask
      CoreBusy.deny();
      
      graph.client.query({ query, variables }).then(({ error, loading, data }: HashMap<any>) => {
        if (error) {
          CoreGraphErrorHandler(error);
        }

        // restores wait mask
        CoreBusy.deny(false);

        if (loading) {
          return null;
        }
  
        const result = CoreGraphDataFactory({ loading, data }, 'company', {}) as HashMap<any>;
        
        let item = cloneDeep(result);
        const lineage: Array<string> = [corporateName];
        const populated: boolean = true;
  
        while (item.reseller) {
          lineage.unshift(item.reseller.corporateName);
          item = item.reseller;
        }
  
        this.setState({ lineage, populated });
  
      }).catch(Noop);
    };
  }
}