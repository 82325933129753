import { CoreAuthCredentialsService } from '@neustar/core-ui';

/**
 * Auth Plugin [required]
 * @export
 * @returns {Promise<boolean>}
 */
export default async function (): Promise<CoreAuthCredentialsType> {
  const { clientID, domain, audience } = (window as any).__SPENV__;
  try {
    CoreAuthCredentialsService.CLIENTID = clientID;
    CoreAuthCredentialsService.DOMAIN = domain;
    CoreAuthCredentialsService.AUDIENCE = audience;
    return { clientID, domain, audience };
  } catch (err: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err.message || err);
    }
    return err;
  }
}
