import gql from 'graphql-tag';

import { UserWhiteLabelFrag, UserAccountFrag } from './fragments';

export const QUERY_CURRENT_USER = gql`
  query GetCurrentUser {
    company {
      id
      ...UserWhiteLabelFields
      ...UserAccountFields
    }
  }
  ${UserWhiteLabelFrag}
  ${UserAccountFrag}
`;

export const QUERY_ACTIVE_USER = gql`
  query GetActiveUser($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      proxyPackage {
        wafEnabled
        botEnabled
      }
    }
  }
`;

export const QUERY_USER_PROFILE = gql`
  query GetUserProfile($filter: UserFilterInput) {
    user(filter: $filter) {
      id
      createdAt
      updatedAt
      enabled
      userName
      firstName
      lastName
      company {
        dName
        corporateName
      }
      email
      lastLogin
      jobTitle
      phone
      mobile
      roles
      notificationConfiguration {
        id
        categories {
          category
          email
          description
          label
        }
      }
    }
  }
`;

export const MUTATION_CREATE_USER_NOTIFICATION_CONFIG = gql`
  mutation CreateUserNotificationConfiguration($input: CreateUserNotificationConfigurationInput!) {
    createUserNotificationConfiguration(input: $input) {
      configuration {
        id
      }
    }
  }
`;

export const QUERY_USER_ROLE = gql`
  query GetUserRole($filter: UserFilterInput) {
    user(filter: $filter) {
      roles
    }
  }
`;
