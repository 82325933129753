import { BGPServiceType } from "Graph";
import { CoreSyncWebStorage, CoreConfig } from "@neustar/core-ui";

/**
 * Get New Experience Status
 * @export
 * @param {string>} storageName
 * @return {boolean}  newExperience
 */
export const GetNewExperienceStatus = (storageName): boolean => {
  const storage = new CoreSyncWebStorage(storageName) as any;
  let newExperience = storage.get().new_experience;
  if (newExperience === undefined) {
    const HasBGP: boolean = CoreConfig.account.bgp;
    const HasDNA: boolean = CoreConfig.account.dna;
    newExperience = HasBGP || HasDNA ? true : false;
    // APPSEC-1719 - show old landing page for COX accounts
    if (CoreConfig.account.bgp_routing && CoreConfig.account.bgp_routing === BGPServiceType.BGP_IP) {
      newExperience = false;
    }
  }
  return newExperience;
};
