import { CoreTableSortMethodMask } from '@neustar/core-ui';
import { InitialCap, CORE_EPOCH_MINUTE } from '@neustar/core-ui/lib/utils/';
import { TrafficField } from 'Graph';

import { TrafficUnitsEnum, EventsActiveEnum, ProviderSelectEnum } from 'App/enums/';
import { EventTrafficSeriesShape } from './interfaces/';

export const EVENTS_TABLE_MESSAGE_LOOKING = 'Looking for Events...';
export const EVENTS_TABLE_MESSAGE_EMPTY = 'No results...';
export const EVENTS_CHANGE_EVENT = 'EVENTS_CHANGE_EVENT';

export const PAGE_NO: number = 1;
export const DEFAULT_FROM_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

export const EVENTS_STATUS_ONGOING: CoreFormOptionType = { label: InitialCap(EventsActiveEnum[EventsActiveEnum.ONGOING]), value: EventsActiveEnum.ONGOING, title: EventsActiveEnum[EventsActiveEnum.ONGOING] };
export const EVENTS_STATUS_ENDED: CoreFormOptionType = { label: InitialCap(EventsActiveEnum[EventsActiveEnum.ENDED]), value: EventsActiveEnum.ENDED, title:EventsActiveEnum[EventsActiveEnum.ENDED] };
export const EVENTS_STATUS_ALL: CoreFormOptionType = { label: InitialCap(EventsActiveEnum[EventsActiveEnum.ALL]), value: EventsActiveEnum.ALL, title: EventsActiveEnum[EventsActiveEnum.ALL] };

export const EVENTS_STATUS_OPTIONS: Array<CoreFormOptionType> = [
  EVENTS_STATUS_ALL,
  EVENTS_STATUS_ENDED,
  EVENTS_STATUS_ONGOING
];

export const EVENTS_PROVIDER_WAF: CoreFormOptionType = { label: ProviderSelectEnum.WAF, value: Object.keys(ProviderSelectEnum)[Object.values(ProviderSelectEnum).indexOf(ProviderSelectEnum.WAF)], title: ProviderSelectEnum.WAF };
export const EVENTS_PROVIDER_BOT: CoreFormOptionType = { label: ProviderSelectEnum.BOT, value: Object.keys(ProviderSelectEnum)[Object.values(ProviderSelectEnum).indexOf(ProviderSelectEnum.BOT)], title: ProviderSelectEnum.BOT };
export const EVENTS_PROVIDER_DDOS: CoreFormOptionType = { label: ProviderSelectEnum.DDOS, value: Object.keys(ProviderSelectEnum)[Object.values(ProviderSelectEnum).indexOf(ProviderSelectEnum.DDOS)], title: ProviderSelectEnum.DDOS };

export const EVENTS_PROVIDER_OPTIONS: Array<CoreFormOptionType> = [
  EVENTS_PROVIDER_WAF,
  EVENTS_PROVIDER_BOT,
  EVENTS_PROVIDER_DDOS
];

export const EVENTS_PERPAGE_10: CoreFormOptionType = { label: '10', value: 10, title: '10 results per/page' };
export const EVENTS_PERPAGE_25: CoreFormOptionType = { label: '25', value: 25, title: '25 results per/page' };
export const EVENTS_PERPAGE_50: CoreFormOptionType = { label: '50', value: 50, title: '50 results per/page' };
export const EVENTS_PERPAGE_100: CoreFormOptionType = { label: '100', value: 100, title: '100 results per/page' };

export const EVENTS_PERPAGE_OPTIONS: Array<CoreFormOptionType> = [
  EVENTS_PERPAGE_10,
  EVENTS_PERPAGE_25,
  EVENTS_PERPAGE_50,
  EVENTS_PERPAGE_100,
];

export const EVENTS_TABLE_SOURCE = {
  paginate: { page: 0, limit: 10, count: 0 },
  sort: { column: 'start', method: CoreTableSortMethodMask.DESCENDING },
  results: [],
};

export const EVENTS_LIST_FILTER_SOURCE = {
  active: EVENTS_STATUS_ALL.value,
  destinations: '',
  provider: [],
  from: '',
  to: '',
  perPage: EVENTS_PERPAGE_10.value
};

export const EVENTS_FILTER_PROPS = {
  onChange: () => {},
  onSubmit: () => {},
  onReset: () => {}
};


export const EVENTS_EVENT_STATUS = 'EVENTS_EVENT_STATUS';

export const DNAME_REGEX = /^[A-Z]{2}_[A-Z]{1}[A-Z0-9]{0,20}$/;
export const UUID_REGEX = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
export const OBJID_REGEX = /^[a-f\d]{24}$/i;

export const ERROR_MESSAGE_FAIL_PERMISSIONS = 'Insufficient user permissions. Redirected to Events.';
export const ALERT_MESSAGE_DNAME_CHANGE = `Switching Company Name:`;

export const EVENTS_PARAMS_STATE = {
  lookup: false
};

export const EVENT_TRAFFIC_REFETCH = 'event-traffic';

export const TRAFFIC_TITLE = 'Traffic';
export const EVENT_UNITS_OPTIONS = [TrafficUnitsEnum.BITS, TrafficUnitsEnum.PACKETS];
export const EVENT_TRAFFIC_UNIT_DEFAULT = TrafficUnitsEnum.BITS;
export const EVENT_POLL_INTERVAL = CORE_EPOCH_MINUTE;
export const EVENT_DURATION_PROPS = {
  duration: 0
};

export const EVENT_TRAFFIC_PLOT_OPTIONS = {
  time: { timezoneOffset: new Date().getTimezoneOffset() },
  series: {
    showInNavigator: true
  },
  area: { marker: { enabled: false } },
  xAxis: {
    type: 'datetime',
    labels: {
      format: '{value:%Y-%b-%e}'
    }
  }
};

export const EVENT_TRAFFIC_LEGEND_STYLE = {
  STANDARD: { color: '#aaa' },
  HOVER: { color: '#eee', fontSize: '12px', fontWeight: 'bold', textOverflow: 'ellipsis' },
};

const EVENT_TRAFFIC_DEF = {
  id: '', tagName: 'linearGradient', x1: 0, y1: 0, x2: 0, y2: 1, children: [{ tagName: 'stop', offset: 0 }, { tagName: 'stop', offset: 1 }]
};

export const EVENT_TRAFFIC_DEFS = {
  gradient1: Object.assign({}, EVENT_TRAFFIC_DEF, { id: 'gradient1' }),
  gradient2: Object.assign({}, EVENT_TRAFFIC_DEF, { id: 'gradient2' }),
  gradient3: Object.assign({}, EVENT_TRAFFIC_DEF, { id: 'gradient3' }),
};

export const INBOUND_TRAFFIC_COLOR = '#af4dff';
export const OUTBOUND_TRAFFIC_COLOR = '#90ed7d';
export const MITIGATED_TRAFFIC_COLOR = '#b51313';

export const EVENT_TRAFFIC_SERIES_COLORS = [INBOUND_TRAFFIC_COLOR, OUTBOUND_TRAFFIC_COLOR, MITIGATED_TRAFFIC_COLOR];

export const EVENT_TRAFFIC_SERIES_INBOUND: EventTrafficSeriesShape = { name: 'Inbound', data: [], field: TrafficField.IN_BITS };
export const EVENT_TRAFFIC_SERIES_OUTBOUND: EventTrafficSeriesShape = { name: 'Outbound', data: [], field: TrafficField.IN_BITS };
export const EVENT_TRAFFIC_SERIES_MITIGATED: EventTrafficSeriesShape = { name: 'Mitigated', data: [], field: TrafficField.IN_BITS };

export const EVENT_TRAFFIC_BITS_SERIES_MAP: HashMap<EventTrafficSeriesShape> = {
  [TrafficField.IN_BITS_PER_SECOND]: Object.assign({}, EVENT_TRAFFIC_SERIES_INBOUND, { field: TrafficField.IN_BITS_PER_SECOND }),
  [TrafficField.OUT_BITS_PER_SECOND]: Object.assign({}, EVENT_TRAFFIC_SERIES_OUTBOUND, { field: TrafficField.OUT_BITS_PER_SECOND }),
  [TrafficField.MITIGATED_BITS_PER_SECOND]: Object.assign({}, EVENT_TRAFFIC_SERIES_MITIGATED, { field: TrafficField.MITIGATED_BITS_PER_SECOND }),
};

export const EVENT_TRAFFIC_PACKETS_SERIES_MAP: HashMap<EventTrafficSeriesShape> = {
  [TrafficField.IN_PACKETS_PER_SECOND]: Object.assign({}, EVENT_TRAFFIC_SERIES_INBOUND, { field: TrafficField.IN_PACKETS_PER_SECOND }),
  [TrafficField.OUT_PACKETS_PER_SECOND]: Object.assign({}, EVENT_TRAFFIC_SERIES_OUTBOUND, { field: TrafficField.OUT_PACKETS_PER_SECOND }),
  [TrafficField.MITIGATED_PACKETS_PER_SECOND]: Object.assign({}, EVENT_TRAFFIC_SERIES_MITIGATED, { field: TrafficField.MITIGATED_PACKETS_PER_SECOND }),
};

export const MITIGATIONS_TABLE_MESSAGE_LOOKING = 'Looking for Mitigations...';
export const MITIGATIONS_TABLE_MESSAGE_EMPTY = 'No results...';

export const MITIGATIONS_TABLE_SOURCE = {
  paginate: { page: 0, limit: 5, count: 0 },
  sort: { column: 'end', method: CoreTableSortMethodMask.DESCENDING },
  results: [],
};

export const EVENT_STORAGE_NAME = 'neusec_event';

export const MITIGATION_TEMPLATE_MESSAGE_EMPTY = 'No template action for this Provider.';
export const MITIGATION_TEMPLATE_MESSAGE_CONFIRM = 'Confirm to open a new window with external link.';

export const VIEWLOGS_URI = '/analytics/waf/logs/violations';
export const VIEWWAFLOGS_URI = '/analytics/waf/logs/violations';
export const VIEWBOTLOGS_URI = '/analytics/bot/logs/violations';
export const MITIGATION_STATISTICS_NO_RESULTS = 'No countermeasure stats available.';
export const EVENT_SERIES_NO_DATA = 'Traffic data for this event is not available.';

export const ALLOWED_MITIGATION_FIELDS = [
  'IN_BITS_PER_SECOND',
  'IN_PACKETS_PER_SECOND',
  'OUT_BITS_PER_SECOND',
  'OUT_PACKETS_PER_SECOND',
  'MITIGATED_BITS_PER_SECOND',
  'MITIGATED_PACKETS_PER_SECOND'
];
export * from 'App/constants/';

export const EVENT_PAGE_STATE = {
  newExperience: false
};

export const EVENT_TRAFFIC_SERIES_PROPS = {
  styledMode: true,
  id: Date.now(),
  exporting: { enabled: false },
  plotOptions: {},
  defs: EVENT_TRAFFIC_DEFS,
  className: 'event-traffic-chart',
  lang: { 
    noData: 'No data to display'
  }
}