import React, { PureComponent } from 'react';


import CompaniesContext from '../context';
import CompaniesService from '../service';
import SelectorForm from './SelectorForm';

import { CompaniesSelectorProps } from '../interfaces/';
import { COMPANIES_SELECT_EVENT, COMPANIES_RESET_EVENT } from '../constants';

import { CoreConfig, CorePermissionsAdmin } from '@neustar/core-ui';

/**
 * Companies Selector Container
 * @export
 * @class CompaniesSelectorContainer
 * @extends {PureComponent<CompaniesSelectorProps, {}>}
 */
export default class CompaniesSelectorContainer extends PureComponent<CompaniesSelectorProps, {}> {
  /**
   * Service
   * @protected
   * @type {CompaniesService}
   * @memberof CompaniesSelectorContainer
   */
  protected service: CompaniesService;

  /**
   * Creates an instance of CompaniesSelectorContainer.
   * @param {CompaniesSelectorProps} props
   * @memberof CompaniesSelectorContainer
   */
  constructor(props: CompaniesSelectorProps) {
    super(props);
    this.service = new CompaniesService(CoreConfig.company.dName);
  }

  /**
   * LifeCycle Hook
   * @memberof CompaniesSelectorContainer
   */
  public componentDidMount() {
    const {
      service,
      props: { onSelectEmit, onResetEmit },
    } = this;
    if (onSelectEmit) {
      service.subscribe(onSelectEmit, COMPANIES_SELECT_EVENT);
    }
    if (onResetEmit) {
      service.subscribe(onResetEmit, COMPANIES_RESET_EVENT);
    }
  }

  /**
   * LifeCycle Hook
   * @memberof CompaniesSelectorContainer
   */
  public componentWillUnmount() {
    const {
      service,
      props: { onSelectEmit, onResetEmit },
    } = this;
    if (onSelectEmit) {
      service.unsubscribe(onSelectEmit, COMPANIES_SELECT_EVENT);
    }
    if (onResetEmit) {
      service.unsubscribe(onResetEmit, COMPANIES_RESET_EVENT);
    }
  }

  /**
   * Render
   * @memberof CompaniesSelectorContainer
   */
  public render() {
    const { service } = this;

    return (
      <CorePermissionsAdmin type="company">
        <CompaniesContext.Provider value={service}>
          <SelectorForm />
        </CompaniesContext.Provider>
      </CorePermissionsAdmin>
    );
  }
}
