import { useEffect } from 'react';
import { useLocation } from '@reach/router';
import ga from '../../Plugins/shared/GoogleAnalytics';

export default () => {
  const location = useLocation();
  useEffect(() => {
    ga.gtag('event', 'page_view', {
      page_title: document.title,
      page_location: location.pathname + location.search,
    });
  }, [location]);

  return null;
};
