import gql from 'graphql-tag';

import { PaginationFrag } from '../fragments';

export const QUERY_ATTACKS_IN_PROGRESS = gql`
  query GetAttackInProgress($company: CompanyFilterInput, $from: Time!, $event: EventFilterInput) {
    company(filter: $company) {
      id
      events(from: $from, filter: $event, page: 1, perPage: 1) {
        results {
          id
          start
          end
          company {
            dName
          }
        }
      }
    }
  }
`;

export const QUERY_EVENTS_IN_RANGE = gql`
  query GetEventsInRange($company: CompanyFilterInput, $filter: EventFilterInput, $from: Time!) {
    company(filter: $company) {
      id
      events(filter: $filter, from: $from, sortBy: [{ dimension: START, direction: DESCENDING }]) {
        results {
          id
          start
          end
          mitigations(page: 1, perPage: 200) {
            results {
              id
              ... on WAFMitigation {
                id
                WAFMitigationCountermeasures: countermeasures {
                  id
                  name
                  violationsDetails {
                    value
                  }
                }
              }
              ... on BotMitigation {
                id
                BotMitigationCountermeasures: countermeasures {
                  id
                  name
                  violationsDetails {
                    value
                  }
                }
              }
              ... on DDOSMitigation {
                id
                DDOSMitigationCountermeasures: countermeasures {
                  id
                  name
                  trafficData {
                    field
                    metric
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_EVENTS = gql`
  query GetEventsList(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time
    $filter: EventFilterInput
    $sortBy: [EventSortBy!] = [{ dimension: END, direction: DESCENDING }]
    $page: UnsignedInt32! = 1
    $perPage: UnsignedInt32! = 10
  ) {
    company(filter: $company) {
      id
      events(from: $from, to: $to, page: $page, perPage: $perPage, filter: $filter, sortBy: $sortBy) {
        results {
          id
          company {
            dName
          }
          start
          end
          historicalPrefixes
          activePrefixes
          mitigations(page: 1, perPage: 20) {
            results {
              id
            }
          }
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_EVENT = gql`
  query GetEventDetail($company: CompanyFilterInput, $filter: EventFilterInput, $from: Time!) {
    company(filter: $company) {
      id
      events(from: $from, filter: $filter) {
        results {
          id
          company {
            dName
          }
          start
          end
          historicalPrefixes
          activePrefixes
        }
      }
    }
  }
`;

export const QUERY_EVENT_MITIGATIONS = gql`
  query GetEventMitigationsList(
    $company: CompanyFilterInput
    $sortBy: [MitigationSortBy!] = [{ dimension: END, direction: DESCENDING }]
    $page: UnsignedInt32! = 1
    $perPage: UnsignedInt32! = 5
    $filter: EventFilterInput
    $from: Time!
    $mitigationsFilter: MitigationFilterInput
  ) {
    company(filter: $company) {
      id
      events(from: $from, filter: $filter) {
        results {
          mitigations(sortBy: $sortBy, page: $page, perPage: $perPage, filter: $mitigationsFilter) {
            results {
              id
              ... on WAFMitigation {
                id
                start
                end
                destinationIP {
                  string
                }
                policyKey
                WAFMitigationCountermeasures: countermeasures {
                  name
                  violationsDetails {
                    value
                  }
                }
              }
              ... on BotMitigation {
                id
                start
                end
                destinationIP {
                  string
                }
                policyKey
                BotMitigationCountermeasures: countermeasures {
                  name
                  violationsDetails {
                    value
                  }
                }
              }
              ... on DDOSMitigation {
                id
                start
                end
                activePrefixes
                historicalPrefixes
                mitigationTemplate {
                  id
                }
                DDOSMitigationCountermeasures: countermeasures {
                  name
                  trafficData: traffic {
                    field
                    metric
                    value
                  }
                }
              }
            }
            pageInfo {
              ...PaginationFields
            }
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_EVENT_MITIGATIONS_ALERTS_LIST = gql`
  query GetEventMitigationsAlertsList(
    $company: CompanyFilterInput
    $sortBy: [MitigationSortBy!] = [{ dimension: END, direction: DESCENDING }]
    $page: UnsignedInt32! = 1
    $perPage: UnsignedInt32! = 5
    $filter: EventFilterInput
    $from: Time!
    $mitigationsFilter: MitigationFilterInput
  ) {
    company(filter: $company) {
      events(from: $from, filter: $filter) {
        results {
          mitigations(sortBy: $sortBy, page: $page, perPage: $perPage, filter: $mitigationsFilter) {
            results {
              ... on DDOSMitigation {
                alerts {
                  end
                  id
                  severity
                  start
                  type
                  details {
                    ... on DDOSDNAAlertDetails {
                      hostAddress
                    }
                    ... on DDOSOnNetworkAlertDetails {
                      hostAddress
                    }
                  }
                }
              }
            }
            pageInfo {
              ...PaginationFields
            }
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_EVENT_REPORT = gql`
  query GetEventReport($company: CompanyFilterInput, $filter: EventFilterInput, $from: Time!) {
    company(filter: $company) {
      id
      events(from: $from, filter: $filter) {
        results {
          id
          company {
            dName
          }
          start
          end
          historicalPrefixes
          activePrefixes
          mitigations {
            results {
              id
              company {
                dName
              }
              start
              end
              ... on WAFMitigation {
                id
                destinationIP {
                  string
                }
                WAFMitigationCountermeasures: countermeasures {
                  name
                }
              }
              ... on BotMitigation {
                id
                destinationIP {
                  string
                }
                BotMitigationCountermeasures: countermeasures {
                  name
                }
              }
              ... on DDOSMitigation {
                id
                activePrefixes
                historicalPrefixes
                DDOSMitigationCountermeasures: countermeasures {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_EVENTS_REPORT = gql`
  query GetEventsReport($company: CompanyFilterInput, $from: Time!) {
    company(filter: $company) {
      id
      events(from: $from, perPage: 50) {
        results {
          id
          company {
            dName
          }
          start
          end
          historicalPrefixes
          activePrefixes
        }
      }
    }
  }
`;

export const QUERY_EVENTS_ATTACK_TYPES = gql`
  query GetEventsAttackTypesList(
    $company: CompanyFilterInput
    $from: Time!
    $to: Time
    $event: EventFilterInput
    $sortBy: [EventSortBy!] = [{ dimension: START, direction: DESCENDING }]
  ) {
    company(filter: $company) {
      id
      events(from: $from, to: $to, filter: $event, sortBy: $sortBy) {
        results {
          id
          company {
            dName
            corporateName
          }
          start
          end
          historicalPrefixes
          activePrefixes
          mitigations {
            results {
              id
              ... on WAFMitigation {
                id
                WAFMitigationCountermeasures: countermeasures {
                  name
                }
              }
              ... on BotMitigation {
                id
                BotMitigationCountermeasures: countermeasures {
                  name
                }
              }
              ... on DDOSMitigation {
                id
                DDOSMitigationCountermeasures: countermeasures {
                  trafficData: traffic {
                    id
                    field
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_EVENT_ATTACK_TYPES_COUNTERMEASURES = gql`
  query GetEventAttackTypesCountermeasures($company: CompanyFilterInput, $filter: EventFilterInput, $from: Time!) {
    company(filter: $company) {
      id
      events(from: $from, filter: $filter) {
        results {
          id
          mitigations {
            results {
              id
              ... on WAFMitigation {
                id
                WAFMitigationCountermeasures: countermeasures {
                  name
                  violationsDetails {
                    value
                  }
                }
              }
              ... on BotMitigation {
                id
                BotMitigationCountermeasures: countermeasures {
                  name
                  violationsDetails {
                    value
                  }
                }
              }
              ... on DDOSMitigation {
                id
                DDOSMitigationCountermeasures: countermeasures {
                  name
                  trafficData: traffic {
                    field
                    metric
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
