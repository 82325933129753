import { cloneDeep } from 'lodash';

import { PAGE_INFO_DEFAULT } from 'App/constants/';

import { CoreGraphDataFactory } from '@neustar/core-ui';

/**
 * Users To Options
 * @export
 * @param {HashMap<any>} src
 * @returns {CoreFormOptionsType}
 * @description
 * Collects and parses Users response into a Human Readable Dictionary for Select Menu Options.
 */
export function UsersToOptions(src: HashMap<any>): CoreFormOptionsType {
  return src.reduce((d: any[], { id: value, userName: label }: HashMap<string>) => {
    d.push({ label, value });
    return d;
  }, []);
}

/**
 * Fetch More Users Factory
 * @export
 * @param {Func<any, any>} fetcher
 * @param {HashMap<any>} variables
 * @param {CoreFormOptionsType} [opts=[]]
 * @returns {Promise<CoreFormOptionsType>}
 */
export async function FetchMoreUsersFactory(fetcher: Func<any, any>, variables: HashMap<any>, opts: CoreFormOptionsType = []): Promise<CoreFormOptionsType> {
  return fetcher(variables).then((response: any) => {
    const { results, pageInfo: { totalItems } } = CoreGraphDataFactory(response, 'company.users', {
      results: [],
      pageInfo: cloneDeep(PAGE_INFO_DEFAULT)
    }) as HashMap<any>;

    if (results.length && totalItems) {
      opts = opts.concat(UsersToOptions(results));

      if (opts.length < totalItems) {
        const page: number = variables.page ? variables.page + 1 : 1;
        Object.assign(variables, { page });
        return FetchMoreUsersFactory(fetcher, variables, opts);
      }
    }
    return opts;
  })
  .catch(console.error);
}
