export enum EventsActiveEnum {
  ONGOING = 0,
  ENDED = 1,
  ALL = 2,
}

export enum ProviderSelectEnum {
  WAF = 'WAF',
  DDOS = 'DDoS',
  BOT = 'Bot'
}

export enum ProviderTypeEnum {
  WAFMitigation = 'WAFMitigation',
  DDOSMitigation = 'DDOSMitigation',
  BotMitigation = 'BotMitigation'
}

export enum ProviderTypeCountermeasuresEnum {
  WAFMitigation = 'WAFMitigationCountermeasures',
  DDOSMitigation = 'DDOSMitigationCountermeasures',
  BotMitigation = 'BotMitigationCountermeasures'
}

export enum ProviderLabelEnum {
  WAFMitigation = 'waf',
  DDOSMitigation = 'ddos',
  BotMitigation = 'bot'
}