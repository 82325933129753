import gql from 'graphql-tag';

import { TrafficByTimeFrag } from '../fragments';

export const QUERY_TRAFFIC_SUMMARY = gql`
  query GetTrafficSummaryQuery(
    $fields: [TrafficField!]! = [IN_BITS_PER_SECOND, OUT_BITS_PER_SECOND, MITIGATED_BITS_PER_SECOND]
    $metrics: [TrafficMetric!]! = [AVERAGE]
    $from: Time!
    $to: Time
    $filter: TrafficFilterInput
    $groupBy: [TrafficGroupBy!] = [{ dimension: HOUR }]
  ) {
    traffic(
      fields: $fields, 
      metrics: $metrics, 
      from: $from, 
      to: $to, 
      groupBy: $groupBy, 
      filter: $filter
    ) {
      ...TrafficByTimeFields
    }
  }
  ${TrafficByTimeFrag}
`;

export const SUBSCRIPTION_TRAFFIC_SUMMARY = gql`
  subscription GetTrafficSummarySubscription(
    $fields: [TrafficField!]! = [IN_BITS_PER_SECOND, OUT_BITS_PER_SECOND, MITIGATED_BITS_PER_SECOND]
    $metrics: [TrafficMetric!]! = [AVERAGE]
    $timeSpanMinutes: UnsignedInt32! = 60
    $groupBy: [TrafficGroupBy!] = [{ dimension: HOUR }]
    $filter: TrafficFilterInput
  ) {
    traffic(
      fields: $fields, 
      metrics: $metrics, 
      timeSpanMinutes: $timeSpanMinutes, 
      filter: $filter,
      groupBy: $groupBy
    ) {
      ...TrafficByTimeFields
    }
  }
  ${TrafficByTimeFrag}
`;
