import { CoreAuth, CoreConfig, CorePermissionSimpleInstance } from '@neustar/core-ui';

/**
 * Is SSO Enabled Async
 * @export
 * @return {*}  {Promise<boolean>}
 */
export async function IsSSOEnabledAsync(): Promise<boolean> {
  try {
    // SSO NOT ENABLED - at company level
    if (CoreConfig.account.sso === false) {
      return false;
    }

    if (!CoreAuth.user.profile.masquerade) {
      const permissions: CorePermissionSimpleType = CorePermissionSimpleInstance('sso:write');
      return permissions.Allowed;
    }

    return true;
  } catch (err: any) {
    return Promise.reject(err.message || err);
  }
}

/**
 * Is SSO Query
 * @export
 * @param {HashMap<string>} src
 * @return {*}  {boolean}
 */
export function IsSSOQuery(src: HashMap<string>): boolean {
  if ('status' in src && 'appsec_portal_company' in src && 'appsec_portal_role' in src && 'reason' in src && Object.keys(src).length === 4) {
    return true;
  }
  if ('status' in src && 'reason' in src && Object.keys(src).length === 2) {
    return true;
  }
  return false;
}
