import { AdjustNavigation } from './AdjustNavigation';
import { CoreConfigAccount } from '@neustar/core-ui';
import { GetDeepHashMap } from '@neustar/core-ui/lib/utils/';

/**
 * Adjust Config
 * @async
 * @export
 * @param {HashMap<any>} { isReseller, proxyPackage, bgpPackage, dnaPackage, apiPackage }
 * @returns {Promise<void>}
 */
export async function AdjustConfig({ isReseller, proxyPackage, bgpPackage, dnaPackage, apiPackage, ssoPackage, tunnelTypes }: HashMap<any>): Promise<void> {
  CoreConfigAccount.RESELLER = isReseller === true;
  CoreConfigAccount.PROXY = GetDeepHashMap(proxyPackage, 'enabled', false) === true;
  CoreConfigAccount.PROXY_TYPE = GetDeepHashMap(proxyPackage, 'proxyType');
  CoreConfigAccount.WAF = GetDeepHashMap(proxyPackage, 'wafEnabled', false) === true;
  CoreConfigAccount.ULTRAWAF = GetDeepHashMap(proxyPackage, 'wafEnabled', false) === true;
  CoreConfigAccount.BOT = GetDeepHashMap(proxyPackage, 'botEnabled', false) === true;
  CoreConfigAccount.BGP = GetDeepHashMap(bgpPackage, 'enabled', false) === true;
  CoreConfigAccount.BGP_ROUTING = GetDeepHashMap(bgpPackage, 'bgpRouting');
  CoreConfigAccount.PROXY_API = GetDeepHashMap(proxyPackage, 'api');
  CoreConfigAccount.PROXY_EDGE = GetDeepHashMap(proxyPackage, 'edge');
  CoreConfigAccount.DNA = GetDeepHashMap(dnaPackage, 'enabled', false) === true;
  CoreConfigAccount.API_ACCESS = GetDeepHashMap(apiPackage, 'enabled', false) === true;
  CoreConfigAccount.OPENHYBRID = GetDeepHashMap(apiPackage, 'openHybridEnabled', false) === true;
  CoreConfigAccount.SSO = GetDeepHashMap(ssoPackage, 'enabled', false) === true;
  CoreConfigAccount.TUNNEL_TYPES = tunnelTypes;
  return await AdjustNavigation().catch(console.error);
}
