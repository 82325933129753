import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import { QUERY_GEO_TRAFFIC } from './models';

/**
 * Get Geo Traffic Query
 */
export const GetGeoTrafficQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_GEO_TRAFFIC,
    variables: { filter: { company: CoreConfig.company.dName } },
  };
});
