import React from 'react';

import CompaniesSelectorBase from './SelectorBase';

import NestedListSwitch from './NestedListSwitch';
import SearchListSwitch from './SearchListSwitch';

/**
 * Selector List Switch Component
 * @export
 * @class SelectorListSwitchComponent
 * @extends {CompaniesSelectorBase}
 */
export default class SelectorListSwitchComponent extends CompaniesSelectorBase {
  /**
   * Render
   * @returns
   * @memberof SelectorListSwitchComponent
   */
  public render() {
    const { context: { open, valid } } = this;

    if (!open) {
      return null;
    }

    if (valid) {
      return <NestedListSwitch />
    }

    return <SearchListSwitch />;
  }

  /**
   * Subscriber
   * @protected
   * @memberof SelectorListSwitchComponent
   */
  protected Subscriber = () => {
    this.forceUpdate();
  };
}
