import { IsSSOEnabledAsync } from 'App/factories/';

import { CoreNavigation } from '@neustar/core-ui';

/**
 * Adjust Navigation SSO
 * @async
 * @export
 * @return {*}  {Promise<void>}
 */
export async function AdjustNavigationSSO(): Promise<void> {
  try {
    if (!CoreNavigation.items.size) {
      throw new ReferenceError('navigation items not instantiated.');
    }

    const manage: CoreNavItemType = CoreNavigation.items.get('manage');

    if (manage && manage.children.has('manage.sso')) {
      await IsSSOEnabledAsync().then((enabled: boolean) => {
        if (!enabled) {
          manage.children.delete('manage.sso');
        }
      });
    }
  } catch (err: any) {
    console.error(err);
  }

  return void 0;
}
