import gql from 'graphql-tag';

export const QUERY_TUNNEL_STATUS = gql`
  query GetTunnelStatus(
    $filter: TunnelFilterInput
  ) {
    tunnelStatus(
      filter: $filter
    ) {
      interfacePath
      status
    }
  }
`;

export const QUERY_TUNNELS_TRAFFIC = gql`
  query GetTunnelsTrafficQuery(
    $filter: TrafficFilterInput,
    $from: Time!,
    $to: Time,
    $fields: [TrafficField!] = [ OUT_BITS ],
    $metrics: [TrafficMetric!] = [ SUM ],
    $groupBy: [TrafficGroupBy!] = [{ dimension: INTERFACE_PATH }]
  ) {
    traffic(
      filter: $filter
      from: $from
      to: $to
      fields: $fields
      metrics: $metrics
      groupBy: $groupBy
    ) {
      data {
        id
        field
        metric
        values {
          ... on TrafficByKey {
            k
            v
          }
        }
      }
    }
  }
`;