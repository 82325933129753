import gql from 'graphql-tag';

export const GET_DOMAIN = gql`
  query GetDomain {
    domain @rest(type: "Domain", path: "/domain", endpoint: "xhr", method: "GET") {
      whitelabel
    }
  }
`;

export const GET_USER_SESSION = gql`
  query GetUserSession {
    session @rest(type: "UserSession", path: "/user/session", endpoint: "xhr", method: "GET") {
      active
    }
  }
`;

export const GET_SSO_RETURN = gql`
  query GetSSOReturn {
    sso @rest(type: "UserSSOReturn", path: "/user/sso", endpoint: "xhr", method: "GET") {
      return_to
    }
  }
`;

export const GET_USER_SETTINGS = gql`
  query GetUserSettings {
    settings @rest(type: "UserSettings", path: "/user/settings", endpoint: "xhr", method: "GET") {
      masquerade
      config
      user
      federated
      error
    }
  }
`;

export const POST_USER_LOGOUT = gql`
  mutation PostUserLogout {
    logout(input: {}) @rest(type: "UserLogout", path: "/user/logout", endpoint: "xhr", method: "POST")
  }
`;

export const GET_NOTIFICATIONS_UNREAD = gql`
  query GetNotificationsUnread {
    company {
      notificationRecords(from: "2006-01-02T15:04:05Z", page: "1", perPage: "10", filter: { unread: true }) {
        results {
          level: category
          date: createdAt
          id
          subject
          content
        }
        count: pageInfo {
          totalItems
        }
      }
    }
  }
`;

export const MUTATION_MARK_NOTIFICATIONS_READ = gql`
  mutation UpdateNotificationRecord($input: UpdateNotificationRecordInput!) {
    updateNotificationRecord(input: $input) {
      records {
        id
      }
    }
  }
`;

export const MUTATION_MARK_ALL_NOTIFICATIONS_READ = gql`
  mutation MarkAllUserNotificationRecordsRead($input: UpdateAllUserNotificationRecordsInput!) {
    markAllUserNotificationRecordsRead(input: $input) {
      userID
    }
  }
`;

export const POST_NOTIFICATIONS_MARK = gql`
  mutation MarkAllAsRead($input: Any!) {
    notifications(input: {}) @rest(type: "Notifications", path: "/notifications/mark/read", endpoint: "xhr", method: "POST") {
      data
    }
  }
`;

export const POST_FORGOT = gql`
  mutation Forgot($input: Any!) {
    forgot(input: $input) @rest(type: "Forgot", path: "/forgot", endpoint: "xhr", method: "POST") {
      response
      error
    }
  }
`;

export const POST_ACTIVATE = gql`
  mutation Activate($input: Any!) {
    activate(input: $input) @rest(type: "Activate", path: "/activate", endpoint: "xhr", method: "POST") {
      response
      error
    }
  }
`;

export const POST_RESET = gql`
  mutation Reset($input: Any!) {
    reset(input: $input) @rest(type: "Reset", path: "/reset", endpoint: "xhr", method: "POST") {
      response
      error
    }
  }
`;
