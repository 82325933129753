import gql from 'graphql-tag';


export const QUERY_TUNNELS = gql`
  query GetTunnelsList(
    $company: CompanyFilterInput
  ) {
    company(
      filter: $company
    ) {
      tunnels {
        id
        interfacePath
        description
        tunnelSource
        tunnelDestination
        networkNodes {
          iataCode
        }
        type
      }
    }
  }
`;