import React from 'react';

import CompaniesSelectorBase from './SelectorBase';

import CompaniesContext from '../context';

/**
 * Search Indicator Component
 * @export
 * @class SearchIndicatorComponent
 * @extends {CompaniesSelectorBase}
 */
export default class SearchIndicatorComponent extends CompaniesSelectorBase {
  /**
   * Display Name
   * @static
   * @type {string}
   * @memberof SearchIndicatorComponent
   */
  public static displayName: string = 'CoreInputKbd';

  /**
   * Context
   * @static
   * @type {Readonly<typeof CompaniesContext>}
   * @memberof CompaniesSelectorButtonsComponent
   */
  public static contextType: Readonly<typeof CompaniesContext> = CompaniesContext;

  /**
   * Render
   * @memberof SearchIndicatorComponent
   */
  public render() {
    const { valid } = this.context;
    return <kbd>{valid ? '\u2b51' : '\u26a0'}</kbd>;
  }

  /**
   * Buttons Subscriber
   * @protected
   * @memberof SearchIndicatorComponent
   */
  protected Subscriber = () => {
    this.forceUpdate();
  };
}
