import gql from 'graphql-tag';

export const TrafficByTimeFrag = gql`
  fragment TrafficByTimeFields on TrafficOutput {
    data {
      field
      metric
      values {
        ... on TrafficByTime {
          ts
          v
        }
      }
    }
  }
`;

export const TrafficByKeyFrag = gql`
  fragment TrafficByKeyFields on TrafficOutput {
    data {
      field
      metric
      values {
        ... on TrafficByKey {
          k
          v
        }
      }
    }
  }
`;


export const TrafficByKeyDepth2Frag = gql`
  fragment TrafficByKeyDepth2Fields on TrafficOutput {
    data {
      field
      metric
      values {
        ... on TrafficByKey {
          k
          v
          vs {
            ... on TrafficByKey {
              k
              v
            }
          }
        }
      }
    }
  }
`;
