import './navigation.scss';

import { SP_MANAGE_URL } from 'Graph';
import { CoreNavigation } from '@neustar/core-ui';

/**
 * Navigation Plugin
 * @export
 * @returns {Promise<boolean>}
 */
export default async function (): Promise<boolean> {
  try {
    const { GRAPHQL_API_DOCS_URL } = (window as any).__SPENV__;

    const NAVIGATION: Map<string, CoreNavItemType> = new Map<string, CoreNavItemType>()
      .set('home', { label: 'Home', path: 'home' })
      .set('manage', {
        label: 'Management',
        children: new Map<string, CoreNavItemType>()
          .set('manage.account', { label: 'Account Information', url: SP_MANAGE_URL })
          .set('manage.notifications', {
            label: 'Notifications',
            children: new Map<string, CoreNavItemType>()
              .set('manage.notifications.view', { label: 'View', path: `manage/notifications/view` })
              .set('manage.notifications.configure', { label: 'Configure', path: `manage/notifications` }),
          })
          .set('manage.users', { label: 'Users', path: 'manage/users/search' })
          .set('manage.auditing', { label: 'Auditing', path: 'manage/auditing' })
          .set('manage.companies', { label: 'Companies', path: 'manage/companies' })
          .set('manage.direct', {
            label: 'Direct Customers',
            children: new Map<string, CoreNavItemType>().set('manage.direct.accounts', { label: 'Accounts', url: `${SP_MANAGE_URL}/direct/accounts` }),
          })
          .set('manage.reseller', {
            label: 'Resellers',
            children: new Map<string, CoreNavItemType>().set('manage.resellers.accounts', { label: 'Accounts', url: `${SP_MANAGE_URL}/resellers/accounts` }),
          })
          .set('manage.soc', {
            label: 'SOC',
            children: new Map<string, CoreNavItemType>().set('manage.soc.companies', { label: 'Companies', path: 'manage/soc/companies' }),
          })
          .set('manage.api', {
            label: 'API',
            children: new Map<string, CoreNavItemType>()
              .set('manage.api.api-access', { label: 'Access', url: `${SP_MANAGE_URL}/api-access` })
              .set('manage.api.openhybrid', { label: 'Openhybrid Alerts', url: `${SP_MANAGE_URL}/openhybrid` })
              .set('manage.api.api-doc', { label: 'REST API Docs', url: `${SP_MANAGE_URL}/api-doc` })
              .set('manage.api.graphql-api-doc', { label: 'GraphQL API Docs', url: GRAPHQL_API_DOCS_URL }),
          })
          .set('manage.sso', {
            label: 'Federated SSO',
            path: 'manage/sso',
          }),
      })
      .set('events', { label: 'Events', path: 'events' })
      .set('analytics', {
        label: 'Analytics',
        children: new Map<string, CoreNavItemType>()
          .set('analytics.ultra-api', { label: 'UltraAPI', url: '#' })
          .set('analytics.alerts', { label: 'Alerts', path: 'analytics/alerts' })
          .set('analytics.reports', { label: 'Executive Reports', path: 'analytics/reports' })
          .set('analytics.ddos', {
            label: 'DDoS',
            children: new Map<string, CoreNavItemType>()
              .set('analytics.ddos.dna-traffic', { label: 'D&A Traffic', url: '/analytics/ddos/dna/traffic' })
              .set('analytics.ddos.events', {
                label: 'Events',
                children: new Map<string, CoreNavItemType>().set('analytics.ddos.events.blockedips', { label: 'Blocked IPs', url: '/analytics/ddos/events/blockedips' }),
              }),
          })
          .set('analytics.proxy', {
            label: 'Assets',
            children: new Map<string, CoreNavItemType>()
              .set('analytics.proxy.domain', { label: 'Domains', url: '/analytics/proxy/web/host' })
              .set('analytics.proxy.geolocation', { label: 'Geolocation', url: '/analytics/proxy/web/geo' })
              .set('analytics.proxy.ip-address', { label: 'IP Address', url: '/analytics/proxy/web/ip-address' })
              .set('analytics.proxy.url-path', { label: 'URL Path', url: '/analytics/proxy/web/url' })
              .set('analytics.proxy.user-agent', { label: 'User Agent', url: '/analytics/proxy/web/user-agent' }),
          })
          .set('analytics.waf', {
            label: 'WAF',
            children: new Map<string, CoreNavItemType>().set('analytics.waf.dashboard', { label: 'Dashboard', path: 'analytics/waf/dashboard' }).set('analytics.waf.logs', {
              label: 'Logs',
              children: new Map<string, CoreNavItemType>()
                .set('analytics.waf.logs.violations', { label: 'Violation', path: 'analytics/waf/logs/violations' })
                .set('analytics.waf.logs.responder-policy', { label: 'Responder/Network', path: 'analytics/waf/logs/responder-policy' }),
            }),
          })
          .set('analytics.bot', {
            label: 'BOT',
            children: new Map<string, CoreNavItemType>().set('analytics.bot.dashboard', { label: 'Dashboard', path: 'analytics/bot/dashboard' }).set('analytics.bot.logs', {
              label: 'Logs',
              children: new Map<string, CoreNavItemType>().set('analytics.bot.logs.violations', { label: 'Violation', path: 'analytics/bot/logs/violations' }),
            }),
          })
          .set('analytics.enrichment', { label: 'Enrichment', path: 'analytics/enrichment' }),
      })
      .set('configuration', {
        label: 'Configuration',
        children: new Map<string, CoreNavItemType>()
          .set('configuration.assets', { label: 'Assets', path: 'configuration/assets' })
          .set('configuration.prefix-map', { label: 'BGP', url: '/configuration/prefix-map' })
          .set('configuration.dna', { label: 'D&A', url: '/configuration/dna' })
          .set('configuration.policies', { label: 'Policies', path: 'configuration/policies' })
          .set('configuration.certificates', { label: 'Certificates', path: 'configuration/certificates' })
          .set('configuration.signatures', { label: 'Signatures', path: 'configuration/signatures' }),
      });
    /**
     * @notes
     * - core-ui Navigation takes the configuration array and
     * constructs the Navigation Aside Component.
     * Empty Array will result in no Navigation Container
     * (<aside/>)
     */
    CoreNavigation.use(NAVIGATION);

    return true;
  } catch (err: any) {
    return Promise.reject(err);
  }
}
