import gql from 'graphql-tag';

export const CompanyCertificatesFrag = gql`
  fragment CompanyCertificatesFields on Company {
    certificates {
      id
      name
      createdAt
      root
      issuer
      issued
      expiration
      commonName
    }
  }
`;

export const CompanyProxiesFrag = gql`
  fragment CompanyProxiesFields on Company {
    proxies {
      id
      name
      vServers {
        certificateBindings {
          certificateID
        }
      }
    }
  }
`;
