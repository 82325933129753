import { CoreConfig, CoreGraphSocketConsumer } from '@neustar/core-ui';

import {
  SUBSCRIPTION_TRAFFIC_NODES,
  SUBSCRIPTION_TRAFFIC_NODES_TOTALS
} from './models';


/**
 * Get Traffic Nodes Subscription
 */
export const GetTrafficNodesSubscription = CoreGraphSocketConsumer(() => {
  return {
    subscription: SUBSCRIPTION_TRAFFIC_NODES,
    variables: { filter: { company: CoreConfig.company.dName } },
  };
});

/**
 * Get Traffic Nodes Totals Subscription
 */
export const GetTrafficNodesTotalsSubscription = CoreGraphSocketConsumer(() => {
  return {
    subscription: SUBSCRIPTION_TRAFFIC_NODES_TOTALS,
    variables: { filter: { company: CoreConfig.company.dName } },
  };
});
