import { XHRClientCredentials, GET_DOMAIN } from 'Graph';
import { ApplyDomainWhiteLabel, StoreConfigMetaData, WhiteLabelAdjustNavigation } from './shared/';
import { CoreGraphDataFactory } from '@neustar/core-ui';

/**
 * Domain Plugin
 * @export
 * @returns {Promise<boolean>}
 */
export default async function (): Promise<boolean> {
  try {
    const XHRClientInstance = XHRClientCredentials();
    const { loading, data } = await XHRClientInstance.query({ query: GET_DOMAIN });
    const { whitelabel } = CoreGraphDataFactory({ loading, data }, 'domain', {}) as HashMap<any>;
    await ApplyDomainWhiteLabel(whitelabel);
    if (whitelabel?.enabled) {
      StoreConfigMetaData('FAVICON');
    }
    await WhiteLabelAdjustNavigation(whitelabel);

    return true;
  } catch (err: any) {
    return Promise.reject(err);
  }
}
