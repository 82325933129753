import './header.scss';

import { CoreAuth, CoreConfig, CoreHeaderMenu, CoreGetApiLocation } from '@neustar/core-ui';

/**
 * Header Plugin
 * @export
 * @returns {Promise<boolean>}
 */
export default async function (): Promise<boolean> {
  try {
    const HEADER: Map<string, CoreHeaderItemType> = new Map<string, CoreHeaderItemType>()
      .set('soc', {
        label: 'SOC',
        name: 'soc',
        href: CoreGetApiLocation('soc'),
        permissions: () => {
          return CoreAuth.user.role.isSocAdmin;
        },
      })
      .set('support', {
        label: 'Support',
        name: 'support',
        href: CoreConfig.header.support_url,
      });

    CoreHeaderMenu.use(HEADER);

    return true;
  } catch (err: any) {
    return Promise.reject(err);
  }
}
