import React, { PureComponent } from 'react';

import { FederationProvider, GetUsersQuery } from 'Graph';

import UsersContext from '../context';

import { UserListSelect } from '../components/';

import { CoreGraphErrorHandler } from '@neustar/core-ui';

/**
 * Users Selector Container
 * @export
 * @class UsersSelectorContainer
 * @extends {PureComponent<GenericPropType, {}>}
 */
export default class UsersSelectorContainer extends PureComponent<GenericPropType, {}> {
  /**
   * Render
   * @memberof UsersSelectorContainer
   */
  public render() {
    const { props } = this;

    return (
      <FederationProvider silent={true}>
        <GetUsersQuery skip={true}>
          {(client: CoreGraphResultType) => {
            const { error } = client;
            if (error) {
              CoreGraphErrorHandler(error);
              return null;
            }
            return (
              <UsersContext.Provider value={client}>
                <UserListSelect {...props} />
              </UsersContext.Provider>
            );
          }}
        </GetUsersQuery>
      </FederationProvider>
    );
  }
}
