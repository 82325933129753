import React, { MouseEvent } from 'react';

import CompaniesSelectorBase from './SelectorBase';

import { CompaniesListMode } from '../enums/';
import { COMPANIES_SELECT_EVENT } from '../constants';

import { CoreInputLabel, CoreFormButton } from '@neustar/core-ui';
import { MergeClassNames } from '@neustar/core-ui/lib/utils/';

/**
 * Toggle Buttons Component
 * @export
 * @class ToggleButtonComponent
 * @extends {CompaniesSelectorBase<{}, {}>}
 */
export default class ToggleButtonComponent extends CompaniesSelectorBase<{}, {}> {
  /**
   * Render
   * @memberof ToggleButtonComponent
   */
  public render() {
    const { OnToggleClick, toggle_disabled, context: { open } } = this;

    const tclz = MergeClassNames('toggle-button', {
      icon: true,
      'toggle-on': open === true,
      'toggle-off': open === false,
    });

    return (
      <CoreInputLabel key={`company.search.toggle.${Date.now()}`} htmlFor="_">
        <CoreFormButton title="Toggle List" className={tclz} onClick={OnToggleClick} disabled={toggle_disabled} />
      </CoreInputLabel>
    );
  }

  /**
   * On Toggle Click
   * @protected
   * @memberof ToggleButtonComponent
   */
  protected OnToggleClick = (evt: MouseEvent<HTMLButtonElement>, form: CoreFormServiceType): void => {
    const { context } = this;
    const { mode, open, valid, candidate, term } = context;

    if (mode === CompaniesListMode.SEARCH && open && !valid && candidate) {
      context.mode = CompaniesListMode.NONE;
      context.candidate = null;
      context.populated = false;
      form.update('term', term);
      context.toggle();
      context.emit(COMPANIES_SELECT_EVENT);
    } else {
      context.toggle();
      context.emit();
    }
  };

  /**
   * Toggle Disabled - getter
   * @readonly
   * @protected
   * @type {boolean}
   * @memberof ToggleButtonComponent
   */
  protected get toggle_disabled(): boolean {
    return this.context.populated === false;
  }
}
