import { ProxyMenuShape, TunnelMenuShape } from '../interfaces/';

import { ArrayUnique } from '@neustar/core-ui/lib/utils/';

/**
 * Proxies To Options
 * @export
 * @param {Array<ProxyMenuShape>} src
 * @returns {CoreFormOptionsType}
 */
 export function ProxiesToOptions(src: Array<ProxyMenuShape>): CoreFormOptionsType {
  return (src||[]).reduce((d: CoreFormOptionsType, { name, ip: { string: value } }: ProxyMenuShape) => {
    const label: string = `${value} (${name})`;
    d.push({ label, value });
    return d;
  }, []);
}

/**
 * Tunnels To Options
 * @export
 * @param {Array<TunnelMenuShape>} src
 * @returns {CoreFormOptionsType}
 */
export function TunnelsToOptions(src: Array<TunnelMenuShape>): CoreFormOptionsType {
  let ips: string[] = [];

  return (src||[]).reduce((d: CoreFormOptionsType, { description, destinationIPs }: TunnelMenuShape) => {
    if (!Array.isArray(destinationIPs) || destinationIPs.length === 0) {
      return d;
    }

    destinationIPs = destinationIPs.filter((ip): boolean => !~ips.indexOf(ip)); 

    destinationIPs.forEach((value: string) => {
      ips.push(value);
      const label: string = `${value} (${description})`;
      d.push({ label, value });
    });

    ips = ArrayUnique(ips);

    return d;
  }, []);
}