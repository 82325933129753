import { FetchPolicy } from '@apollo/client';

import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';
import { CORE_EPOCH_MINUTE } from '@neustar/core-ui/lib/utils/';
import { QUERY_POLICY } from '../Policies';

import { UTC_EPOCH_FROM } from '../constants';

import {
  QUERY_ATTACKS_IN_PROGRESS,
  QUERY_EVENTS_IN_RANGE,
  QUERY_EVENTS,
  QUERY_EVENT,
  QUERY_EVENT_MITIGATIONS,
  QUERY_EVENT_REPORT,
  QUERY_EVENTS_REPORT,
  QUERY_EVENT_ATTACK_TYPES_COUNTERMEASURES,
  QUERY_EVENT_MITIGATIONS_ALERTS_LIST,
} from './models';

const pollInterval: number = CORE_EPOCH_MINUTE;
const fetchPolicy: FetchPolicy = 'no-cache';
const from: string = UTC_EPOCH_FROM; 

/**
 * Get Events Attacks In Progress - list of 1
 */
export const GetEventsAttackInProgress = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_ATTACKS_IN_PROGRESS,
    variables: { company: { dName: CoreConfig.company.dName }, event: { active: true }, from },
    pollInterval,
  };
});

/**
 * Get Events In Range - list
 */
export const GetEventsInRange = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_EVENTS_IN_RANGE,
    variables: { company: { dName: CoreConfig.company.dName }, from },
    fetchPolicy,
    pollInterval
  };
});

/**
 * Get Events Query - list
 */
export const GetEvents = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_EVENTS,
    variables: { company: { dName: CoreConfig.company.dName }, filter: { active: null }, from },
    fetchPolicy,
  };
});

/**
 * Get Event Query
 */
export const GetEvent = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_EVENT,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id, start }) => ({ from: start, filter: { id } }),
    fetchPolicy,
  };
});

/**
 * Get Event Mitigations Query
 */
export const GetEventMitigationsQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_EVENT_MITIGATIONS,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id, start }) => ({ from: start, filter: { id } }),
    fetchPolicy,
  };
});

/**
 * Get Alerts associated to an Event 
 */
export const GetEventMitigationsAlertsList = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_EVENT_MITIGATIONS_ALERTS_LIST,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id, start, mitigationId }) => ({ from: start, filter: { id }, mitigationsFilter: { id: mitigationId } }),
    fetchPolicy,
  };
});

/**
 * Get Event Report Query
 */
export const GetEventReportQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_EVENT_REPORT,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id, start }) => ({ from: start, filter: { id } }),
  };
});

/**
 * Get Events Report Query
 */
export const GetEventsReportQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_EVENTS_REPORT,
    variables: { company: { dName: CoreConfig.company.dName }, from },
  };
});

/**
 * Get Events Attack Types Countermeasures Query
 */
export const GetEventsAttackTypesCountermeasuresQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_EVENT_ATTACK_TYPES_COUNTERMEASURES,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id, start }) => ({ from: start, filter: { id } }),
    fetchPolicy,
  };
});

/**
 * Get Policy - id
 */
 export const GetEventPolicyQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_POLICY,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ key }) => ({ policy: { key } }),
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache'
  };
});
