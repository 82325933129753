import { CoreConfigMeta, CoreConfigApp, CoreSyncWebStorage } from '@neustar/core-ui';

/**
 * Store meta in storage
 * @export
 * @param {string} key
 * @return {*} void
 */
export function StoreConfigMetaData(key: string): void {
  try {
    if (key) {
      const storage = new CoreSyncWebStorage(CoreConfigApp.CONFIG_META_STORAGE_NAME);
      if (!storage.get()) {
        storage.init({});
      }
      const currentStorageValue = storage.get();
      const metaObject = {
        ...currentStorageValue,
        [key]: CoreConfigMeta[key],
      };
      storage.set(metaObject);
    }
  } catch (err: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err.message || err);
    }
  }
  return void 0;
}
