import React, { Component } from 'react';
import { cloneDeep } from 'lodash';

import ListItems from './ListItems';

import { CompaniesSwitchProps, CompaniesListItemState } from '../interfaces/';
import { COMPANIES_LIST_ITEM_STATE } from '../constants';

import { MergeClassNames } from '@neustar/core-ui/lib/utils/';

/**
 * List Switch Component
 * @export
 * @class ListSwitchComponent
 * @extends {Component<CompaniesSwitchProps, CompaniesListItemState>}
 */
export default class ListSwitchComponent extends Component<CompaniesSwitchProps, CompaniesListItemState> {
  /**
   * State
   * @type {CompaniesListItemState}
   * @memberof ListSwitchComponent
   */
  public readonly state: CompaniesListItemState = cloneDeep(COMPANIES_LIST_ITEM_STATE);

  /**
   * Render
   * @memberof ListSwitchComponent
   */
  public render() {
    const { props: { items }, state: { active } } = this;

    const clz = MergeClassNames('', { active });

    return (
      <dl className={clz}>
        <ListItems items={items} />
      </dl>
    );
  }
}
