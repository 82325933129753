import { cloneDeep } from 'lodash';

export const COMPANIES_LIST_ITEM = {
  dName: null,
  enabled: false,
  isReseller: false
};

export const COMPANIES_LIST_ITEM_PROPS = {
  item: cloneDeep(COMPANIES_LIST_ITEM)
};

export const COMPANIES_LIST_ITEMS_PROPS = {
  items: []
};

export const COMPANIES_LIST_ITEM_STATE = {
  active: false,
  looking: true,
  customers: []
};

export const COMPANIES_CANDIDATE = {
  dName: null,
  enabled: false,
};

export const COMPANIES_CANDIDATE_PROPS = {
  item: Object.assign({}, COMPANIES_CANDIDATE),
};

export const COMPANIES_CANDIDATES_PROPS = {
  items: [],
};

export const LIST_SWITCH_STATE = {
  loading: true,
  error: null,
  items: []
};


export const COMPANIES_CHANGE_EVENT = 'COMPANIES_CHANGE_EVENT';
export const COMPANIES_SELECT_EVENT = 'COMPANIES_SELECT_EVENT';
export const COMPANIES_RESET_EVENT = 'COMPANIES_RESET_EVENT';

export const COMPANIES_SEARCHING_MESSAGE = 'Checking for matches...';
export const COMPANIES_SEARCHING_NO_MATCHES = 'No matches found.';
export const COMPANIES_NESTED_MESSAGE = 'Building nested list.';
export const COMPANIES_NESTED_NO_MATCHES = 'No list members.';
export const COMPANIES_NETWORK_ERROR = 'Network Error.';
