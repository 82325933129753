import forge from 'node-forge';

(forge as any).options.usePureJavaScript = true;

interface CertificateDetailsShape {
  version: number;
  serialNumber: string;
  subject: string;
  expires: Date;
}

/**
 * Get Cert KEY Details
 * @export
 * @param {string} cert
 * @return {forge.pki.rsa.PrivateKey | Error }
 */
export function GetCertKEYDetails(cert: string): forge.pki.rsa.PrivateKey | Error {
  try {
    return forge.pki.privateKeyFromPem(cert);
  } catch (err: any) {
    return err as Error;
  }
}

/**
 * Get Cert PEM Details
 * @export
 * @param {string} cert
 * @return {CertificateDetailsShape | Error}
 */
export function GetCertPEMDetails(cert: string): CertificateDetailsShape | Error {
  try {
    const result: forge.pki.Certificate = forge.pki.certificateFromPem(cert);
    const { version, serialNumber } = result;
    const subject: string = result.subject.getField('CN').value;
    const expires: Date = result.validity.notAfter;
    return { version, serialNumber, subject, expires };
  } catch (err: any) {
    return err as Error;
  }
}
