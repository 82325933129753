class GoogleAnalyticsHelper {
  measurementId: string;
  dataLayer: Array<any>;

  constructor(measurementId: string, dataLayer: Array<any> = []) {
    this.measurementId = measurementId;
    this.dataLayer = dataLayer;
  }

  gtag(...args: any) {
    this.dataLayer.push(arguments);
  }

  reportUser(userId) {
    this.gtag('config', this.measurementId, { user_id: userId });
  }

  logError(msg: any) {
    // This will only work with 'Measurement Protocol'
    // this.gtag('event', 'exception', {
    //   description: msg,
    //   fatal: false,
    // });
  }

  attachErrorLoggers() {
    window.addEventListener('error', (event) => {
      this.logError(event.error);
    });
    window.addEventListener('unhandledrejection', (event) => {
      this.logError(event.reason);
    });
  }
}

let { GA_MEASUREMENT_ID } = window.__SPENV__;

const googleAnalyticsSingleton = new GoogleAnalyticsHelper(GA_MEASUREMENT_ID, window.dataLayer);

googleAnalyticsSingleton.attachErrorLoggers();

export default googleAnalyticsSingleton;
