import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import { QUERY_EVENT_TRAFFIC } from './models';

/**
 * Get Event Traffic Query
 */
 export const GetEventTrafficQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_EVENT_TRAFFIC,
    variables: { filter: { companyDName: CoreConfig.company.dName } },
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache'
  };
});