import { SESSION_URL as uri } from '../config';

import { CoreGraphRest } from '@neustar/core-ui';

const xhr = `${uri}/xhr`;
const endpoints = { xhr };
const credentials = 'include';

export function XHRClient(): CoreGraphClientType {
  const graph: CoreGraphConnectionType = CoreGraphRest({ uri, endpoints, authenticate: false }, {});
  return graph.client;
}

export function XHRClientCredentials(): CoreGraphClientType {
  const graph: CoreGraphConnectionType = CoreGraphRest({ uri, endpoints, credentials, authenticate: false }, {});
  return graph.client;
}

export function XHRClientAuth(context: CoreAuthServiceType): CoreGraphClientType {
  const graph: CoreGraphConnectionType = CoreGraphRest({ uri, endpoints, }, context);
  return graph.client;
}
