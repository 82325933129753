import React, { Component } from 'react';

import CompaniesContext from '../context';

/**
 * Companies Selector Base
 * @export
 * @abstract
 * @class CompaniesSelectorBase
 * @extends {Component<P, S>}
 * @template P
 * @template S
 */
export default abstract class CompaniesSelectorBase<P = {}, S = {}> extends Component<P, S> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CompaniesContext>}
   * @memberof CompaniesSelectorBase
   */
  public static contextType: Readonly<typeof CompaniesContext> = CompaniesContext;

  /**
   * Abort Controller Listener
   * @private
   * @type {AbortController}
   * @memberof AnalyticsBase
   */
  private _listener: AbortController;

  /**
   * Events
   * @protected
   * @type {Array<string>}
   * @memberof CompaniesSelectorBase
   */
  protected events: Array<string> = [];

  /**
   * LifeCycle Hook
   * @memberof CompaniesSelectorBase
   */
  public componentDidMount() {
    const { context, events, Subscriber } = this;

    this._listener = new AbortController();

    if (Subscriber) {
      context.subscribe(Subscriber, ...events);
    }

    this.OnMount();
  }

  /**
   * LifeCycle Hook
   * @memberof CompaniesSelectorBase
   */
  public componentWillUnmount() {
    const { context, events, Subscriber } = this;
    const { listener } = this;

    if (listener) {
      listener.abort();
    }

    if (Subscriber) {
      context.unsubscribe(Subscriber, ...events);
    }

    this.OnUnmount();
  }

  /**
   * On Mount
   * @memberof CompaniesSelectorBase
   */
  public OnMount = (): void => {};

   /**
    * On Unmount
    * @memberof CompaniesSelectorBase
    */
  public OnUnmount = (): void => {};

  /**
   * Render
   * @memberof CompaniesSelectorBase
   */
  public render(): any {
    return <>must extend in sub-class.</>;
  }

  /**
   * Subscriber
   * @protected
   * @memberof CompaniesSelectorBase
   */
  protected Subscriber = (...args: any[]): void => {
    this.forceUpdate();
  };

  /**
   * Listener - getter
   * @readonly
   * @memberof CompaniesSelectorBase
   */
  public get listener() {
    return this._listener;
  }
}
