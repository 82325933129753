import { cloneDeep } from 'lodash';

import { TrafficServiceTypes } from 'Graph';

import { RangeOptionShape as LPROS, RangeMetaShape as LPRMS } from '../interfaces/';

import { CORE_EPOCH_HOUR, CORE_EPOCH_DAY, CORE_EPOCH_WEEK, CORE_EPOCH_MONTH, CORE_EPOCH_MINUTE } from '@neustar/core-ui/lib/utils/';

export const UTC_EPOCH_FROM: string = '1970-01-01T00:00:00Z';

const META_1_HOUR: LPRMS = { type: TrafficServiceTypes.TrafficDimension.MINUTE, base: CORE_EPOCH_MINUTE, factor: 1 * 60 };
const META_3_HOURS: LPRMS = { type: TrafficServiceTypes.TrafficDimension.MINUTE, base: CORE_EPOCH_MINUTE, factor: 3 * 60 };
const META_12_HOURS: LPRMS = { type: TrafficServiceTypes.TrafficDimension.HOUR, base: CORE_EPOCH_HOUR, factor: 12 };
const META_1_DAY: LPRMS = { type: TrafficServiceTypes.TrafficDimension.HOUR, base: CORE_EPOCH_DAY, factor: 1 };
const META_7_DAYS: LPRMS = { type: TrafficServiceTypes.TrafficDimension.HOUR, base: CORE_EPOCH_WEEK, factor: 1 };
const META_30_DAYS: LPRMS = { type: TrafficServiceTypes.TrafficDimension.DAY, base: CORE_EPOCH_MONTH, factor: 1 };
const META_90_DAYS: LPRMS = { type: TrafficServiceTypes.TrafficDimension.DAY, base: CORE_EPOCH_DAY, factor: 90 };
const META_180_DAYS: LPRMS = { type: TrafficServiceTypes.TrafficDimension.DAY, base: CORE_EPOCH_DAY, factor: 180 };

const RANGE_OPTION_1_HOUR: LPROS = { label: '1H', value: undefined, title: '1 Hour Ago', meta: META_1_HOUR };
const RANGE_OPTION_3_HOURS: LPROS = { label: '3H', value: undefined, title: '3 Hours Ago', meta: META_3_HOURS };
const RANGE_OPTION_12_HOURS: LPROS = { label: '12H', value: undefined, title: '12 Hours Ago', meta: META_12_HOURS };
const RANGE_OPTION_1_DAY: LPROS = { label: '1D', value: undefined, title: '1 Day Ago', meta: META_1_DAY };
const RANGE_OPTION_7_DAYS: LPROS = { label: '7D', value: undefined, title: '7 Days Ago', meta: META_7_DAYS };
const RANGE_OPTION_30_DAYS: LPROS = { label: '30D', value: undefined, title: '30 Days Ago', meta: META_30_DAYS };
const RANGE_OPTION_90_DAYS: LPROS = { label: '90D', value: undefined, title: '90 Days Ago', meta: META_90_DAYS };
const RANGE_OPTION_180_DAYS: LPROS = { label: '180D', value: undefined, title: '180 Days Ago', meta: META_180_DAYS };

export const RANGE_OPTIONS: LPROS[] = [
  cloneDeep(RANGE_OPTION_1_HOUR),
  cloneDeep(RANGE_OPTION_3_HOURS),
  cloneDeep(RANGE_OPTION_12_HOURS),
  cloneDeep(RANGE_OPTION_1_DAY),
  cloneDeep(RANGE_OPTION_7_DAYS),
  cloneDeep(RANGE_OPTION_30_DAYS),
];

export const ALERTS_RANGE_OPTIONS: LPROS[] = [
  cloneDeep(RANGE_OPTION_12_HOURS),
  cloneDeep(RANGE_OPTION_1_DAY),
  cloneDeep(RANGE_OPTION_7_DAYS),
  cloneDeep(RANGE_OPTION_30_DAYS),
  cloneDeep(RANGE_OPTION_90_DAYS),
  cloneDeep(RANGE_OPTION_180_DAYS),
];
