import { FetchPolicy } from '@apollo/client';
import { QUERY_NOTIFICATION_RECORDS_LIST } from './models';
import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';
const fetchPolicy: FetchPolicy = 'no-cache';
const momentInThePast: string = '2006-01-02T15:04:05Z';
export const GetQueryNotificationRecordsList = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_NOTIFICATION_RECORDS_LIST,
    variables: { company: { dName: CoreConfig.company.dName }, from: momentInThePast },
    transform: ({ id }) => ({ filter: { id } }),
    fetchPolicy,
  };
});
