import React, { PureComponent } from 'react';

import { GetAssetsMenuQuery } from 'Graph';

import ProxiesModuleWrapper from '../Wrapper';

import { ProxiesToOptions, TunnelsToOptions } from '../factories/';
import { ProxiesMenuProps } from '../interfaces/';

import { PROXIES_MENU_TITLE, PROXIES_MENU_PLACEHOLDER, PROXIES_MENU_EMPTY_PLACEHOLDER } from '../constants';

import { CoreFormGroup, CoreSelect, CoreInput, CoreGraphErrorHandler, CoreGraphDataFactory } from '@neustar/core-ui';

/**
 * Proxies Menu Container
 * @export
 * @class ProxiesMenuContainer
 * @extends {PureComponent<ProxiesMenuProps, {}>}
 */
export default class ProxiesMenuContainer extends PureComponent<ProxiesMenuProps, {}> {
  /**
   * Render
   * @returns {JSX.Element}
   * @memberof ProxiesMenuContainer
   */
  public render(): JSX.Element {
    const { props: { name, onChange }} = this;

    let options: CoreFormOptionsType = [];

    return (
      <CoreFormGroup className="assets-menu">
        <ProxiesModuleWrapper>
          <GetAssetsMenuQuery>
            {({ data, loading, error }) => {
              if (error) {
                CoreGraphErrorHandler(error);
                return null;
              }

              if (loading) {
                return null;
              }

              const { proxies, tunnels } = CoreGraphDataFactory({ loading, data }, 'company', {
                proxies: [],
                tunnels: [],
              }) as HashMap<any>;

              options = ProxiesToOptions(proxies).concat(TunnelsToOptions(tunnels));

              // SORT
              options.sort(({ label: A }: CoreFormOptionType, { label: B }: CoreFormOptionType): number => (A === B ? 0 : A > B ? 1 : -1));

              if (options.length) {
                return <CoreSelect
                  type="multiple"
                  filter={true}
                  allornone={true}
                  name={name}
                  onChange={onChange}
                  options={options}
                  placeholder={PROXIES_MENU_PLACEHOLDER}
                  title={PROXIES_MENU_TITLE}
                  className="auto-width proxies-menu"
                />;
              } else {
                return <CoreInput name={name} value={null} placeholder={PROXIES_MENU_EMPTY_PLACEHOLDER} readOnly={true} />;
              }
            }}
          </GetAssetsMenuQuery>
        </ProxiesModuleWrapper>
      </CoreFormGroup>
    );
  }
}
