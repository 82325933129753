import gql from 'graphql-tag';

export const QUERY_CHECK_COMPANY = gql`
  query CheckCompany($company: CompanyFilterInput!) {
    company(filter: $company) {
      id
    }
  }
`;

export const QUERY_CHECK_COMPANY_POPULATED = gql`
  query CheckCompanyPopulated(
    $company: CompanyFilterInput!
    $allCustomersFilter: CustomerFilterInput = {}
  ) {
    company(filter: $company) {
      id
      allCustomers(page: 1, perPage: 1, filter: $allCustomersFilter) {
        results {
          id
          dName
          enabled
        }
        pageInfo {
          totalItems
        }
      }
    }
  }
`;

export const QUERY_SEARCH_COMPANY = gql`
  query SearchCompanies(
    $company: CompanyFilterInput!
    $allCustomersFilter: CustomerFilterInput = {}
  ) {
    company(filter: $company) {
      id
      allCustomers(page: 1, perPage: 100, filter: $allCustomersFilter) {
        results {
          id
          dName
          enabled
        }
      }
    }
  }
`;

export const QUERY_GET_COMPANIES_NESTED = gql`
  query GetCompaniesNested($company: CompanyFilterInput!) {
    company(filter: $company) {
      id
      dName
      enabled
      isReseller
      customers {
        id
        dName
        enabled
        isReseller
      }
    }
  }
`;

export const QUERY_CORPORATE_LINEAGE = gql`
  query GetCompanyCorporateLineage(
    $company: CompanyFilterInput
  ) {
    company(filter: $company) {
      id
      dName
      corporateName
      reseller {
        dName
        corporateName
        reseller {
          dName
          corporateName
          reseller {
            dName
            corporateName
            reseller {
              dName
              corporateName
              reseller {
                dName
                corporateName
              }
            }
          }
        }
      }
    }
  }
`;
