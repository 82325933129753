import gql from 'graphql-tag';

import { PaginationFrag } from '../fragments';
import {
  ManagedObjectFrag,
  ProxySectionFrag,
  SalesforceSectionFrag,
  DetectionAndAlertingSectionFrag,
  WhiteLabelSectionFrag,
} from './fragments';

export const QUERY_SOC_COMPANIES_LIST =  gql`
  query GetSOCCompaniesList(
    $company: CompanyFilterInput,
    $filter: CustomerFilterInput,
    $page: UnsignedInt32! = 1,
    $perPage: UnsignedInt32! = 20
  ) {
    company(filter: $company) {
      id
      allCustomers(filter: $filter, page: $page, perPage: $perPage) {
        results {
          id
          dName
          corporateName
          enabled
          createdAt
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_SOC_COMPANY_DETAIL = gql`
  query GetSOCCompanyDetail($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      dName
      corporateName
      enabled

      createdAt
      accountID
      shortname

      corporateDomain
      managementDomain
      serviceProvider

      technicalEmail
      technicalPhone
      technicalMobile
      technicalFirstName
      technicalLastName

      reseller {
        id
        dName
        corporateName
      }

      details {
        ultraDNSAccountName
        ipiID
        recursiveSponsorID
        recursiveAccountID
        wpmAPIKey
      }

      isReseller
      resellBGP
      resellWAF
      resellBot
      resellProxy

      ...SalesforceSectionFields
      ...ProxySectionFields
      ...DNASectionFields
      ...WhiteLabelSectionFields

      apiPackage {
        enabled
        openHybridEnabled
        maxAPIClients
      }

      tunnels {
        type
        description
        destinationIPs
        tunnelSource
        tunnelDestination
      }

      bgpPackage {
        enabled
        serviceTypes
        routing
        alwaysRouted
        alertAuto
        hybridCloudSignalling
        cleanTrafficDeliveryMechanisms
        mitigationTriggerMechanisms
        managedObjects {
          id
        }
      }
    }
  }
  ${ProxySectionFrag}
  ${SalesforceSectionFrag}
  ${DetectionAndAlertingSectionFrag}
  ${WhiteLabelSectionFrag}
`;

export const QUERY_SOC_COMPANY_DETAIL_MANAGED_OBJECTS = gql`
  query GetSOCCompanyDetailManagedObjects($company: CompanyFilterInput, $filter: ManagedObjectFilterInput) {
    company(filter: $company) {
      id
      managedObjects(filter: $filter) {
        ...ManagedObjects
      }
    }
  }
  ${ManagedObjectFrag}
`;

export const QUERY_SOC_COMPANY_DETAIL_DNA_ROUTERS = gql`
  query GetSOCCompanyDetailDNARouters($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      detectionAndAlertingPackage {
        routers {
          id
          name
          flowConfig {
            exportIP {
              string
            }
            alertEnabled
            alertTimeout
          }
          snmpConfig {
            community
            ipAddress {
              string
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SOC_COMPANY_DETAIL_CHILDREN = gql`
  query GetSOCCompanyDetailChildren($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      customers {
        id
        dName
        corporateName
        enabled
        createdAt
        accountID
        shortname
      }
    }
  }
`;

export const QUERY_SOC_COMPANY_DETAIL_PROXIES = gql`
  query GetSOCCompanyDetailProxies($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      proxies {
        id
        name
        ip {
          string
        }
        vServers {
          port
          protocol
          loadBalanceMethod
          persistenceType
          xffHeader
          tlsOptions {
            minTLSVersion
            forceBackendSNI
            commonName
            hstsEnabled
            hstsMaxAge
            hstsPreload
            hstsIncludeSubdomains
          }
          certificateBindings {
            certificateID
            sni
          }
          applicationServices {
            port
            protocol
            origin
          }
        }
      }
    }
  }
`;

export const QUERY_SOC_COMPANY_DETAIL_LEGACY_PROXIES = gql`
  query GetSOCCompanyDetailLegacyProxies($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      legacyProxies {
        id
        name
        ip {
          string
        }
        provisioningStatus
        vServers {
          port
          protocol
          loadBalanceMethod
          persistenceType
          xffHeader
          sp
          tcpb
          tlsOptions {
            minTLSVersion
            forceBackendSNI
            commonName
            hstsEnabled
            hstsMaxAge
            hstsPreload
            hstsIncludeSubdomains
          }
          certificateBindings {
            certificateID
            sni
          }
          applicationServices {
            origin
            port
            protocol
          }
        }
      }
    }
  }
`;

export const QUERY_SOC_COMPANY_DETAIL_TUNNELS = gql`
  query GetSOCCompanyDetailTunnels($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      tunnels {
        type
        description
        destinationIPs
        tunnelSource
        tunnelDestination
      }
    }
  }
`;
