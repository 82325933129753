import { CorePermissionSimpleInstance } from '@neustar/core-ui';
import { PermissionsEnum } from 'Plugins';

export type PermissionInstanceDictionary = { [permission in PermissionsEnum]?: CorePermissionSimpleType };

export class PermissionHelper {
  private permissions: PermissionInstanceDictionary;

  constructor(permissionTypes: PermissionsEnum[]) {
    this.permissions = permissionTypes.reduce<PermissionInstanceDictionary>((obj, perm) => {
      obj[perm] = CorePermissionSimpleInstance(perm);
      return obj;
    }, {});
  }

  has(perm: PermissionsEnum) {
    return this.permissions[perm].Allowed;
  }
}
