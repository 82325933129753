import gql from 'graphql-tag';

import { ConfigChangesFrag, ApplicationServicesFrag } from './fragments';

export const QUERY_VIPS = gql`
  query GetVIPs($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      proxies {
        id
        name
        ip {
          string
        }
        vServers {
          protocol
        }
      }
    }
  }
`;

export const QUERY_ASSETS_MENU = gql`
  query GetAssetsMenu($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      proxies {
        id
        name
        ip {
          string
        }
      }
      tunnels {
        description
        destinationIPs
      }
    }
  }
`;

export const QUERY_ASSETS_LIST = gql`
  query GetAssetsList($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      proxies {
        id
        name
        ip {
          string
        }
        policies {
          id
        }
        vServers {
          port
          protocol
          applicationServices {
            ...ApplicationServicesFields
          }
        }
      }
    }
  }
  ${ApplicationServicesFrag}
`;

export const QUERY_ASSETS_STATUS = gql`
  query GetAssetsStatusList(
    $company: CompanyFilterInput, 
    $proxy: ProxyFilterInput
  ) {
    company(filter: $company) {
      id
      proxies(filter: $proxy) {
        id
        vServerStatus {
          currentState
          port
          protocol
        }
      }
    }
  }
`;

export const QUERY_ASSET = gql`
  query GetProxyAsset(
    $company: CompanyFilterInput
    $proxy: ProxyFilterInput
  ) {
    company(filter: $company) {
      id
      policies {
        id
        name
      }
      proxies(filter: $proxy) {
        id
        name
        updatedAt
        ip {
          string
        }
        policies {
          id
        }
        company {
          dName
        }
        vServers {
          port
          protocol
          loadBalanceMethod
          persistenceType
          xffHeader
          sp
          tcpb
          tlsOptions {
            minTLSVersion
            forceBackendSNI
            commonName
            hstsEnabled
            hstsMaxAge
            hstsPreload
            hstsIncludeSubdomains
            ciphers
            cipherSelection
          }
          certificateBindings {
            certificateID
          }
          applicationServices {
            ...ApplicationServicesFields
          }
        }
      }
    }
  }
  ${ApplicationServicesFrag}
`;

export const MUTATION_ASSET_CREATE = gql`
  mutation CreateProxy($input: CreateProxyInput!) {
    createProxy(input: $input) {
      proxy {
        name
        ip {
          string
        }
      }
    }
  }
`;

export const MUTATION_ASSET_UPDATE = gql`
  mutation UpdateProxy($input: UpdateProxyInput!) {
    updateProxy(input: $input) {
      proxy {
        id
        name
        ip {
          string
        }
      }
    }
  }
`;

export const MUTATION_ASSET_DELETE = gql`
  mutation DeleteProxy($input: DeleteProxyInput!) {
    deleteProxy(input: $input) {
      deletedProxyID
    }
  }
`;

export const QUERY_CONFIG_CHANGES = gql`
  query GetConfigChanges($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      ...ConfigChangesFields
    }
  }
  ${ConfigChangesFrag}
`;
