import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import { QUERY_POLICIES, QUERY_POLICY, QUERY_POLICY_BY_PROFILE, QUERY_BASIC_POLICY, QUERY_BASE_WAF_SIGNATURES, QUERY_BASE_WAF_SIGNATURES_LAST_CHECKED } from './models';

/**
 * Get Policies - list
 */
export const GetPoliciesQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_POLICIES,
    variables: { company: { dName: CoreConfig.company.dName } },
    fetchPolicy: 'no-cache',
  };
});

/**
 * Get Policy - id
 */
export const GetPolicyQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_POLICY,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id }) => ({ policy: { id } }),
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache'
  };
});

/**
 * Get Basic Policy - id
 */
 export const GetBasicPolicyQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_BASIC_POLICY,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ id }) => ({ policy: { id } }),
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache'
  };
});

export const GetPolicyByProfileQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_POLICY_BY_PROFILE,
    variables: { company: { dName: CoreConfig.company.dName } },
    transform: ({ profile }) => ({ policy: { key: profile } }),
    errorPolicy: 'ignore'
  };
});

/**
 * Get Signatures
 */
export const GetBaseWAFSignatures = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_BASE_WAF_SIGNATURES
  };
});

/**
 * Get Signatures Last Checked Time
 */
 export const GetBaseWAFSignaturesLastChecked = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_BASE_WAF_SIGNATURES_LAST_CHECKED
  };
});
