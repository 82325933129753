import { FetchPolicy, ErrorPolicy } from '@apollo/client';
import { QUERY_TUNNELS_TRAFFIC, QUERY_TUNNEL_STATUS } from './models';

import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';
import { CORE_EPOCH_MINUTE } from '@neustar/core-ui/lib/utils/';

const pollInterval: number = CORE_EPOCH_MINUTE;
const errorPolicy: ErrorPolicy = 'ignore';
const fetchPolicy: FetchPolicy = 'no-cache';

/**
 * Get Tunnels Traffic Query
 */
export const GetTunnelsTrafficQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_TUNNELS_TRAFFIC,
    variables: { filter: { companyDName: CoreConfig.company.dName } },
    pollInterval,
    errorPolicy,
    fetchPolicy,
  };
});

/**
 * Get Tunnel Status Query
 */
export const GetTunnelStatusQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_TUNNEL_STATUS,
    variables: { filter: { companyDName: CoreConfig.company.dName } },
    errorPolicy,
    fetchPolicy,
  };
});
