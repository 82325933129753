import { ManagedObjectType } from '../types';

import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import {
  QUERY_SOC_COMPANIES_LIST,
  QUERY_SOC_COMPANY_DETAIL,
  QUERY_SOC_COMPANY_DETAIL_MANAGED_OBJECTS,
  QUERY_SOC_COMPANY_DETAIL_DNA_ROUTERS,
  QUERY_SOC_COMPANY_DETAIL_CHILDREN,
  QUERY_SOC_COMPANY_DETAIL_PROXIES,
  QUERY_SOC_COMPANY_DETAIL_LEGACY_PROXIES,
  QUERY_SOC_COMPANY_DETAIL_TUNNELS
} from './models';

/**
 * Get Companies - list
 */
export const GetSOCCompaniesListQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_SOC_COMPANIES_LIST,
    variables: { company: { dName: CoreConfig.company.dName }, filter: {} },
  };
});

/**
 * Get Company Detail
 */
 export const GetSOCCompanyDetailQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_SOC_COMPANY_DETAIL,
    variables: { company: { dName: CoreConfig.company.dName } },
    fetchPolicy: 'no-cache'
  };
});

/**
 * Get SOC Company Detail Children
 */
 export const GetSOCCompanyDetailChildrenQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_SOC_COMPANY_DETAIL_CHILDREN,
    variables: { company: { dName: CoreConfig.company.dName } },
  };
});

/**
 * Get SOC Company Detail Managed Objects
 */
 export const GetSOCCompanyDetailManagedObjectsQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_SOC_COMPANY_DETAIL_MANAGED_OBJECTS,
    variables: {
      company: { dName: CoreConfig.company.dName },
      filter: { types: [ ManagedObjectType.BGP, ManagedObjectType.DNA, ManagedObjectType.PROXY ] }
    },
  };
});

/**
 * Get Company Detail DNA Routers
 */
 export const GetSOCCompanyDetailDNARoutersQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_SOC_COMPANY_DETAIL_DNA_ROUTERS,
    variables: { company: { dName: CoreConfig.company.dName }},
  };
});

/**
 * Get Company Detail Proxies
 */
 export const GetSOCCompanyDetailProxiesQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_SOC_COMPANY_DETAIL_PROXIES,
    variables: { company: { dName: CoreConfig.company.dName } },
  };
});

/**
 * Get Company Detail Legacy Proxies
 */
 export const GetCompanyDetailLegacyProxiesQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_SOC_COMPANY_DETAIL_LEGACY_PROXIES,
    variables: { company: { dName: CoreConfig.company.dName } },
  };
});


/**
 * Get Company Detail Tunnels
 */
 export const GetSOCCompanyDetailTunnelsQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_SOC_COMPANY_DETAIL_TUNNELS,
    variables: { company: { dName: CoreConfig.company.dName } },
  };
});
