import { IsDate, IsEmpty } from '@neustar/core-ui/lib/utils/';

/**
 * Make Duration
 * @export
 * @param {string | Date} start
 * @param {string | Date} end
 * @returns {HashMap<any>}
 */
export function MakeDuration(start: string | Date, end: string | Date): HashMap<any> {
  const $start: Date = start ? (IsDate(start) ? (start as Date) : new Date(start)) : new Date();
  const $end: Date = end ? (IsDate(end) ? (end as Date) : new Date(end)) : new Date();
  start = start ? $start : null;
  end = end ? $end : null;
  const duration: number = $end.getTime() - $start.getTime();
  return { start, end, duration };
}

/**
 * De Duplicate Dictionary
 * @export
 * @param {Array<HashMap<any>>} src
 * @param {Array<string>} [map=[]]
 * @return {*}  {Array<HashMap<any>>}
 */
export function DeDupeDictionary<T = HashMap<any>>(src: Array<T>, map: Array<string> = []): Array<T> {
  try {
    if (!map.length) {
      throw new ReferenceError('missing map arguments.');
    }

    let i: number;
    let k: string;
    return src.filter((row: HashMap<any>, ndx: number, arr: Array<T>) => {
      if (IsEmpty(row)) {
        return false;
      }
      return (
        ndx ===
        arr.findIndex((t: HashMap<any>) => {
          i = map.length;
          while (i--) {
            k = map[i];
            if (t[k] !== row[k]) {
              return false;
            }
          }
          return true;
        })
      );
    });
  } catch (err: any) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(err.message || err);
    }
    return src;
  }
}

/**
 * Is Logout Query
 * @export
 * @param {HashMap<string>} src
 * @return {*}  {boolean}
 */
export function IsLogoutQuery(src: HashMap<string>): boolean {
  return 'action' in src && src.action === 'logout';
}

/**
 * Encode Form Values into URL parameters
 * @param {HashMap<any>} src The form values
 * @returns {string} The URL parameters
 */
export function EncodeFormValues(src: HashMap<any>): string {
  return Object.entries(src)
    .filter(([key, value]) => value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        if (typeof value[0] === 'object') {
          const jsonString = JSON.stringify(value[0]);
          return `${encodeURIComponent(key)}=${encodeURIComponent(jsonString)}`;
        } else {
          const combinedValue = value.join(',');
          return `${encodeURIComponent(key)}=${encodeURIComponent(combinedValue)}`;
        }
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .join('&');
}
