import EventEmitter from 'events';

import { FEDERATION_URL } from 'Graph';

import { CompaniesServiceShape } from './interfaces/';
import { CompaniesListMode } from './enums/';
import { COMPANIES_CHANGE_EVENT, COMPANIES_SELECT_EVENT, COMPANIES_RESET_EVENT } from './constants';

import { CoreConfig, CoreConfigCompany, CoreGraphLink } from '@neustar/core-ui';

/**
 * Companies Service
 * @export
 * @class CompaniesService
 * @implements {CompaniesServiceShape}
 */
export default class CompaniesService implements CompaniesServiceShape {
  /**
   * Term
   * @private
   * @type {CompaniesListMode}
   * @memberof CompaniesService
   */
  private _mode: CompaniesListMode = CompaniesListMode.NONE;

  /**
   * Term
   * @private
   * @type {string}
   * @memberof CompaniesService
   */
  private _term: string;

  /**
   * Candidate
   * @private
   * @type {string}
   * @memberof CompaniesService
   */
  private _candidate: string = null!;

  /**
   * Valid
   * @private
   * @type {boolean}
   * @memberof CompaniesService
   */
  private _valid: boolean = true;

  /**
   * Open
   * @private
   * @type {boolean}
   * @memberof CompaniesService
   */
  private _open: boolean = false;

  /**
   * Populated
   * @private
   * @type {boolean}
   * @memberof CompaniesService
   */
  private _populated: boolean = false;

  /**
   * Client
   * @private
   * @type {CoreGraphResultType}
   * @memberof CompaniesService
   */
  public readonly _client: CoreGraphClientType = CoreGraphLink({ uri: FEDERATION_URL, silent: true }).client;

  /**
   * Matches
   * @private
   * @type {Array<HashMap<any>>}
   * @memberof CompaniesService
   */
  private _matches: Array<HashMap<any>> = [];

  /**
   * Emitter
   * @private
   * @type {EventEmitter}
   * @memberof CompaniesService
   */
  private _emitter: EventEmitter = new EventEmitter();

  /**
   * Creates an instance of CompaniesService.
   * @param {string} term
   * @memberof CompaniesService
   */
  constructor(term: string) {
    this._term = term;
  }

  /**
   * Subscribe
   * @param {Func<void>} callback
   * @param {...string[]} events
   * @memberof CompaniesService
   */
  public subscribe(callback: Func<void>, ...events: string[]): Func<void> {
    if (!events.length) {
      events = [COMPANIES_CHANGE_EVENT];
    }
    events.forEach((event: string) => {
      this._emitter.on(event, callback);
    });
    return callback;
  }

  /**
   * Unsubscribe
   * @param {Func<void>} callback
   * @param {...string[]} events
   * @memberof CompaniesService
   */
  public unsubscribe(callback: Func<void>, ...events: string[]): void {
    if (!events.length) {
      events = [COMPANIES_CHANGE_EVENT];
    }
    events.forEach((event: string) => {
      this._emitter.off(event, callback);
    });
    callback = undefined;
  }

  /**
   * Emit
   * @param {string} [event=COMPANIES_CHANGE_EVENT]
   * @memberof CompaniesService
   */
  public emit(event: string = COMPANIES_CHANGE_EVENT): void {
    const { term } = this;
    if (this._emitter.listenerCount(event)) {
      this._emitter.emit(event, event, term);
    }
  }

  /**
   * Toggle
   * @memberof CompaniesService
   */
  public toggle(): void {
    const { open } = this;
    this.open = !open;
  }

  /**
   * Submit
   * @memberof CompaniesService
   */
  public submit(): void {
    CoreConfig.company.dName = this.term;
    this.mode = CompaniesListMode.NONE;
    this.valid = true;
    this.open = false;
    this.populated = false;
    this.emit();
    this.emit(COMPANIES_SELECT_EVENT);
  }

  /**
   * Restore
   * @memberof CompaniesService
   */
  public restore(): void {
    CoreConfig.company.clear();
    this.mode = CompaniesListMode.NONE;
    this.term = CoreConfig.company.dName;
    this.candidate = null;
    this.valid = true;
    this.open = false;
    this.populated = false;
    this.emit();
    this.emit(COMPANIES_SELECT_EVENT);
    this.emit(COMPANIES_RESET_EVENT);
  }

  /**
   * Mode - getter
   * @memberof CompaniesService
   */
  public get mode() {
    return this._mode;
  }

  /**
   * Mode - setter
   * @memberof CompaniesService
   */
  public set mode(mode) {
    this._mode = mode;
  }

  /**
   * Term - getter
   * @memberof CompaniesService
   */
  public get term() {
    return this._term;
  }

  /**
   * Term - setter
   * @memberof CompaniesService
   */
  public set term(term) {
    this._term = term;
  }

  /**
   * Candidate - getter
   * @memberof CompaniesService
   */
  public get candidate() {
    return this._candidate;
  }

  /**
   * Candidate - setter
   * @memberof CompaniesService
   */
  public set candidate(candidate) {
    this._candidate = candidate;
  }

  /**
   * Valid - getter
   * @memberof CompaniesService
   */
  public get valid() {
    return this._valid;
  }

  /**
   * Valid - setter
   * @memberof CompaniesService
   */
  public set valid(valid) {
    this._valid = valid;
  }

  /**
   * Open - getter
   * @memberof CompaniesService
   */
  public get open() {
    return this._open;
  }

  /**
   * Open - setter
   * @memberof CompaniesService
   */
  public set open(open) {
    this._open = open;
  }

  /**
   * Matches - getter
   * @memberof CompaniesService
   */
  public get matches() {
    return this._matches;
  }

  /**
   * Matches - setter
   * @memberof CompaniesService
   */
  public set matches(matches) {
    this._matches = matches;
  }

  /**
   * Populated - getter
   * @memberof CompaniesService
   */
  public get populated() {
    return this._populated;
  }

  /**
   * Populated - setter
   * @memberof CompaniesService
   */
  public set populated(populated) {
    this._populated = populated;
  }

  /**
   * Client - getter
   * @memberof CompaniesService
   */
  public get client() {
    return this._client;
  }

  /**
   * Term Equals Config Static DNAME - getter
   * @readonly
   * @type {boolean}
   * @memberof CompaniesService
   */
  public get term_equals_static(): boolean {
    return this.term === CoreConfigCompany.DNAME;
  }

  /**
   * Term Equals Config dName - getter
   * @readonly
   * @type {boolean}
   * @memberof CompaniesService
   */
  public get term_equals_dname(): boolean {
    return this.term === CoreConfig.company.dName;
  }
}
