import { FetchPolicy } from '@apollo/client';
import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';

import { QUERY_TUNNELS } from './models';

const fetchPolicy: FetchPolicy = 'no-cache';

/**
 * Get Tunnels List - list
 */
export const GetTunnelsListQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_TUNNELS,
    variables: { company: { dName: CoreConfig.company.dName } },
    fetchPolicy,
  };
});
