import { DEFAULT_NO_DATA, DEFAULT_ERROR_MESSAGE } from 'App/constants/';

import { TableEmptyInstanceShape } from '../interfaces/';

/**
 * Table Empty Instance
 * @export
 * @class TableEmptyInstance
 * @implements {TableEmptyInstanceShape}
 */
export class TableEmptyInstance implements TableEmptyInstanceShape {
  /**
   * Looking
   * @private
   * @type {boolean}
   * @memberof TableEmptyInstance
   */
  private _looking: boolean = true;

  /**
   * Errored
   * @private
   * @type {boolean}
   * @memberof TableEmptyInstance
   */
  private _errored: boolean = true;

  /**
   * Message
   * @private
   * @type {string}
   * @memberof TableEmptyInstance
   */
  private _message: string = null!;
  
  /**
   * Empty
   * @private
   * @type {string}
   * @memberof TableEmptyInstance
   */
  private _empty: string = null!;
  
  /**
   * Error
   * @private
   * @type {string}
   * @memberof TableEmptyInstance
   */
  private _error: string = null!;

  /**
   * Creates an instance of TableEmptyInstance.
   * @param {string} message
   * @param {string} [empty=DEFAULT_NO_DATA]
   * @memberof TableEmptyInstance
   */
  constructor(message: string, empty: string = DEFAULT_NO_DATA, error: string = DEFAULT_ERROR_MESSAGE) {
    this._message = message;
    this._empty = empty;
  }
  
  /**
   * Looking - getter
   * @readonly
   * @memberof TableEmptyInstance
   */
  public get looking() {
    return this._looking;
  }

  /**
   * Looking - setter
   * @readonly
   * @memberof TableEmptyInstance
   */
  public set looking(looking) {
    this._looking = looking;
    if (looking) {
      this.errored = false;
    }
  }

  /**
   * Errored - getter
   * @readonly
   * @memberof TableEmptyInstance
   */
   public get errored() {
    return this._errored;
  }

  /**
   * Looking - setter
   * @readonly
   * @memberof TableEmptyInstance
   */
  public set errored(errored) {
    this._errored = errored;
    if (errored) {
      this.looking = false;
    }
  }
  
  /**
   * Message - getter
   * @readonly
   * @memberof TableEmptyInstance
   */
  public get message() {
    return this._message;
  }

  /**
   * Empty - getter
   * @readonly
   * @memberof TableEmptyInstance
   */
  public get empty() {
    return this._empty;
  }

  /**
   * Error - getter
   * @readonly
   * @memberof TableEmptyInstance
   */
  public get error() {
    return this._error;
  }

  /**
   * Error - setter
   * @readonly
   * @memberof TableEmptyInstance
   */
  public set error(error) {
    this._error = error;
  }

  /**
   * Show - getter
   * @readonly
   * @memberof TableEmptyInstance
   */
  public get show() {
    return this.looking || this.errored;
  }

  /**
   * Text - getter
   * @readonly
   * @memberof TableEmptyInstance
   */
  public get text() {
    if (this.looking) {
      return this.message;
    }
    if (this.errored) {
      return this.error;
    }
    return this.empty;
  }
} 