import gql from 'graphql-tag';

import { WAFProfileFrag, ResponderPolicyFrag, NetworkControlsFrag, AppSecThresholdFrag, TrustedSourceFrag, PaginationFrag, BotProfileFrag } from './fragments';

export const QUERY_POLICIES = gql`
  query GetPoliciesList($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      policies {
        id
        name
      }
    }
  }
`;

export const QUERY_POLICY = gql`
  query GetPolicy(
    $company: CompanyFilterInput,
    $policy: PolicyFilterInput
  ) {
    company(filter: $company) {
      id
      dName
      proxyPackage {
        wafEnabled
        botEnabled
      }
      proxies {
        id
        name
        policies {
          id
        }
      }
      policies(filter: $policy) {
        id
        name
        updatedAt
        proxies {
          id
          name
          policies {
            id
          }
        }
        wafProfile {
          ...WAFProfileFields
        }
        botProfile {
          ...BotProfileFields
        }
        responderPolicies {
          ...ResponderPolicyeFields
        }
        networkControls {
          ...NetworkControlsFields
        }
        appSecThresholds {
          ...AppSecThresholdFields
        }
        trustedSources {
          ...TrustedSourceFields
        }
      }
    }
  }
  ${WAFProfileFrag}
  ${BotProfileFrag}
  ${ResponderPolicyFrag}
  ${NetworkControlsFrag}
  ${AppSecThresholdFrag}
  ${TrustedSourceFrag}
`;

export const QUERY_POLICY_BY_PROFILE = gql`
  query GetPolicyByProfile(
    $company: CompanyFilterInput,
    $policy: PolicyFilterInput
  ) {
    company(filter: $company) {
      id
      policies(filter: $policy) {
        id
        name
      }
    }
  }
`;

export const QUERY_BASIC_POLICY = gql`
  query GetBasicPolicy(
    $company: CompanyFilterInput,
    $policy: PolicyFilterInput
  ) {
    company(filter: $company) {
      id
      policies(filter: $policy) {
        id
        wafProfile {
          ...WAFProfileFields
        }
        botProfile {
          ...BotProfileFields
        }
        responderPolicies {
          ...ResponderPolicyeFields
        }
        networkControls {
          ...NetworkControlsFields
        }
        appSecThresholds {
          ...AppSecThresholdFields
        }
      }
    }
  }
  ${WAFProfileFrag}
  ${BotProfileFrag}
  ${ResponderPolicyFrag}
  ${NetworkControlsFrag}
  ${AppSecThresholdFrag}
`;

export const QUERY_BASE_WAF_SIGNATURES = gql`
  query GetBaseWAFSignatures(
    $filter: WAFSignatureFilterInput,
    $sortBy: [BaseWAFSignatureSortBy!] = [{ dimension: CREATED_AT, direction: DESCENDING }],
    $page: UnsignedInt32! = 1,
    $perPage: UnsignedInt32! = 10
  ) {
    baseWAFSignatures(filter: $filter, sortBy: $sortBy, page: $page, perPage: $perPage) {
      signatures {
        results {
          id
          category
          createdAt
          defaultAction
          description
          refs
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_BASE_WAF_SIGNATURES_LAST_CHECKED = gql`
  query GetBaseWAFSignaturesLastChecked {
    baseWAFSignatures {
      lastCheckedTime
    }
  }
`;

export const QUERY_BASE_BOT_SIGNATURES = gql`
  query GetBaseBotSignatures(
    $filter: BotSignatureFilterInput, 
    $sortBy: [BotSignatureSortBy!], 
    $page: UnsignedInt32!, 
    $perPage: UnsignedInt32! = 10
  ) {
    baseBotSignatures(filter: $filter, sortBy: $sortBy, page: $page, perPage: $perPage) {
      results {
        id
        category
        description
        version
        botType
        defaultEnabled
        defaultAction
      }
      pageInfo {
        ...PaginationFields
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_XSS_LEARNING_RULES = gql`
  query GetXSSLearningRules(
    $company: CompanyFilterInput, 
    $policy: PolicyFilterInput, 
    $page: UnsignedInt32! = 1, 
    $perPage: UnsignedInt32! = 10
  ) {
    company(filter: $company) {
      id
      policies(filter: $policy) {
        id
        learningRules {
          crossSiteScriptingRules(page: $page, perPage: $perPage) {
            results {
              count
              rule {
                name
                enabled
                url
                location
                valueType
                valueExpression
              }
            }
            pageInfo {
              ...PaginationFields
            }
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_COOKIE_CONSISTENCY_LEARNING_RULES = gql`
  query GetCookieConsistencyLearningRules(
    $company: CompanyFilterInput, 
    $policy: PolicyFilterInput, 
    $page: UnsignedInt32! = 1, 
    $perPage: UnsignedInt32! = 10
  ) {
    company(filter: $company) {
      id
      policies(filter: $policy) {
        id
        learningRules {
          cookieConsistencyRules(page: $page, perPage: $perPage) {
            results {
              count
              rule {
                enabled
                cookieName
              }
            }
            pageInfo {
              ...PaginationFields
            }
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_FIELD_FORMAT_LEARNING_RULES = gql`
  query GetFieldFormatLearningRules(
    $company: CompanyFilterInput, 
    $policy: PolicyFilterInput, 
    $page: UnsignedInt32! = 1, 
    $perPage: UnsignedInt32! = 10
  ) {
    company(filter: $company) {
      id
      policies(filter: $policy) {
        id
        learningRules {
          fieldFormatRules(page: $page, perPage: $perPage) {
            results {
              count
              rule {
                enabled
                formFieldName
                actionURL
                fieldType
                minLength
                maxLength
              }
            }
            pageInfo {
              ...PaginationFields
            }
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_FIELD_CONSISTENCY_LEARNING_RULES = gql`
  query GetFieldConsistencyLearningRules(
    $company: CompanyFilterInput, 
    $policy: PolicyFilterInput, 
    $page: UnsignedInt32! = 1, 
    $perPage: UnsignedInt32! = 10
  ) {
    company(filter: $company) {
      id
      policies(filter: $policy) {
        id
        learningRules {
          fieldConsistencyRules(page: $page, perPage: $perPage) {
            results {
              count
              rule {
                enabled
                formFieldName
                actionURL
              }
            }
            pageInfo {
              ...PaginationFields
            }
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_CSRF_SETTINGS_LEARNING_RULES = gql`
  query GetCSRFLearningRules(
    $company: CompanyFilterInput, 
    $policy: PolicyFilterInput, 
    $page: UnsignedInt32! = 1, 
    $perPage: UnsignedInt32! = 10
  ) {
    company(filter: $company) {
      id
      policies(filter: $policy) {
        id
        learningRules {
          csrfSettingsRules(page: $page, perPage: $perPage) {
            results {
              count
              rule {
                enabled
                formOriginURL
                formActionURL
              }
            }
            pageInfo {
              ...PaginationFields
            }
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_CONTENT_TYPE_LEARNING_RULES = gql`
  query GetContentTypeLearningRules(
    $company: CompanyFilterInput,
    $policy: PolicyFilterInput,
    $page: UnsignedInt32! = 1,
    $perPage: UnsignedInt32! = 10
  ) {
    company(filter: $company) {
      id
      policies(filter: $policy) {
        id
        learningRules {
          contentTypeRules(page: $page, perPage: $perPage) {
            results {
              count
              rule {
                enabled
                contentType
              }
            }
            pageInfo {
              ...PaginationFields
            }
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_HTML_SQL_INJECTION_LEARNING_RULES = gql`
  query GetHTMLSQLInjectionLearningRules(
    $company: CompanyFilterInput,
    $policy: PolicyFilterInput,
    $page: UnsignedInt32! = 1,
    $perPage: UnsignedInt32! = 10
  ) {
    company(filter: $company) {
      id
      policies(filter: $policy) {
        id
        learningRules {
          htmlSQLInjectionRules(page: $page, perPage: $perPage) {
            results {
              count
              rule {
                name
                enabled
                url
                location
                valueType
                valueExpression
              }
            }
            pageInfo {
              ...PaginationFields
            }
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_WSI_SETTINGS_LEARNING_RULES = gql`
  query GetWSISettingsLearningRules(
    $company: CompanyFilterInput,
    $policy: PolicyFilterInput,
    $page: UnsignedInt32! = 1,
    $perPage: UnsignedInt32! = 10
  ) {
    company(filter: $company) {
      id
      policies(filter: $policy) {
        id
        learningRules {
          wsiSettingsRules(page: $page, perPage: $perPage) {
            results {
              count
              rule {
                enabled
                ruleID
                code
                description
              }
            }
            pageInfo {
              ...PaginationFields
            }
          }
        }
      }
    }
  }
  ${PaginationFrag}
`;




export const MUTATION_POLICY_CREATE = gql`
  mutation CreatePolicy($input: CreatePolicyInput!) {
    createPolicy(input: $input) {
      policy {
        id
        name
      }
    }
  }
`;

export const MUTATION_POLICY_UPDATE = gql`
  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      policy {
        id
        name
      }
    }
  }
`;

export const MUTATION_POLICY_DELETE = gql`
  mutation DeletePolicy($input: DeletePolicyInput!) {
    deletePolicy(input: $input) {
      deletedPolicyID
    }
  }
`;

export const MUTATION_POLICY_PROXY_UPDATE = gql`
  mutation PolicyUpdateProxy($input: UpdateProxyInput!) {
    updateProxy(input: $input) {
      proxy {
        id
      }
    }
  }
`;
