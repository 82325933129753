import { CoreGraphErrorHandler } from '@neustar/core-ui';

/**
 * Network Errors Handler
 * @export
 * @class NetworkErrorHandler
 */
export class NetworkErrorHandler {
  /**
   * Tick
   * @private
   * @type {number}
   * @memberof NetworkErrorHandler
   */
  private tick: number = 0;

  /**
   * Limit
   * @private
   * @type {number}
   * @memberof NetworkErrorHandler
   */
  private limit: number;

  /**
   * Creates an instance of NetworkErrorHandler.
   * @param {number} [limit=3]
   * @memberof NetworkErrorHandler
   */
  constructor(limit: number = 3) {
    this.limit = limit;
  }

  /**
   * Handler
   * @param {*} errors
   * @memberof NetworkErrorHandler
   */
  public async handler(errors: any): Promise<any> {
    const { networkError } = errors;
    if (networkError) {
      const { limit } = this;
      if (this.tick < limit) {
        this.tick++;
      } else {
        this.tick = 0;
        return CoreGraphErrorHandler(errors);
      }
    } else {
      return CoreGraphErrorHandler(errors);
    }
  }
}
