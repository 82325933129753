
import { CoreGraphSocketProvider, CoreGraphLinkProvider } from '@neustar/core-ui';

import { TRAFFIC_URL as uri } from 'Graph';

/**
 * Traffic Provider
 * @export
 * @return {CoreGraphProviderClassType} 
 */
export const TrafficProvider: CoreGraphProviderClassType = CoreGraphLinkProvider({ uri });

/**
 * Traffic polling Provider
 * @export
 * @return {CoreGraphProviderClassType} 
 */
export const TrafficPollingProvider: CoreGraphProviderClassType = CoreGraphLinkProvider({ uri, silent: true });

/**
 * Traffic Socket Provider
 * @export
 * @return {CoreGraphProviderClassType} 
 */
export const TrafficSocketProvider: CoreGraphProviderClassType = CoreGraphSocketProvider({ uri });