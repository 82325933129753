import React from 'react';

import CompaniesSelectorBase from './SelectorBase';

import SelectorListSwitch from './SelectorListSwitch';

import { MergeClassNames } from '@neustar/core-ui/lib/utils/';

/**
 * Selector List Component
 * @export
 * @class CompaniesSelectorListComponent
 * @extends {CompaniesSelectorBase}
 */
export default class CompaniesSelectorListComponent extends CompaniesSelectorBase {
  /**
   * Render
   * @returns
   * @memberof CompaniesSelectorListComponent
   */
  public render() {
    const { context: { open } } = this;
    const clz = MergeClassNames('', { active: open });
    return (
      <dl className={clz}>
        <SelectorListSwitch />
      </dl>
    );
  }

  /**
   * Subscriber
   * @protected
   * @memberof CompaniesSelectorListComponent
   */
  protected Subscriber = () => {
    this.forceUpdate();
  };
}
