import jwtDecode from 'jwt-decode';

import { CoreAuth } from '@neustar/core-ui';

/**
 * Get Decoded Token
 * @export
 * @return {*}  {HashMap<any>}
 */
export function GetDecodedToken(): HashMap<any> {
  return jwtDecode(CoreAuth.token) as HashMap<any>;
}
