import React from 'react';
import { cloneDeep } from 'lodash';

import CompaniesSelectorBase from './SelectorBase';

import ListItem from './ListItem';
import { CompaniesListItemsProps, CompaniesResultsFilterType } from '../interfaces/';
import { COMPANIES_LIST_ITEMS_PROPS } from '../constants';

/**
 * List Items Component
 * @export
 * @class ListItemsComponent
 * @extends {CompaniesSelectorBase<CompaniesListItemsProps, CompaniesListItemState>}
 */
export default class ListItemsComponent extends CompaniesSelectorBase<CompaniesListItemsProps> {
  /**
   * Default Props
   * @static
   * @type {CompaniesListItemsProps}
   * @memberof ListSwitchComponent
   */
  public static defaultProps: CompaniesListItemsProps = cloneDeep(COMPANIES_LIST_ITEMS_PROPS);

  /**
   * Render
   * @memberof ListItemsComponent
   */
  public render() {
    const { props: { items } } = this;

    if (!items) {
      return null;
    }

    return items.map((item: CompaniesResultsFilterType, ndx: number) => <ListItem key={`cli.${ndx}`} item={item} />);
  }

  /**
   * Subscriber
   * @protected
   * @memberof ListItemsComponent
   */
  protected Subscriber = null;
}
