import { CoreGraphQueryConsumer } from '@neustar/core-ui';

import { QUERY_NODES, QUERY_TUNNEL_TRAFFIC } from './models';
import { FetchPolicy } from '@apollo/client';

const fetchPolicy: FetchPolicy = 'no-cache';

/**
 * Get Tunnel Traffic Query
 */
export const GetTunnelTrafficQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_TUNNEL_TRAFFIC,
    fetchPolicy
  };
});

/**
 * Get Nodes Traffic Query
 */
export const GetNodesQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_NODES,
    fetchPolicy
  };
});