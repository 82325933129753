export const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const TIMESTAMP_PROPS = {
  format: TIMESTAMP_FORMAT.slice(0),
  utc: true
}

export const UTC_TIMESTAMP_FORMAT = `${TIMESTAMP_FORMAT}Z`;

export const UTC_TOOLTIP = 'UTC Date & Time';

export const UTC_HELP = 'Date & Time represent UTC values.';
export const UTC_OFFSET_HELP = 'Date & Time represent UTC values adjusted for your timezone.';
