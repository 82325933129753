import { CompaniesResultsFilterType } from '../interfaces/';
import { IsEmpty } from '@neustar/core-ui/lib/utils/';

/**
 * Companies Results Filter
 * @export
 * @param {Array<CompaniesResultsFilterType>} src
 * @returns {Array<CompaniesResultsFilterType>}
 */
export const CompaniesResultsFilter = (src: Array<CompaniesResultsFilterType>): Array<CompaniesResultsFilterType> => {
  return src.filter(({ enabled, dName }: HashMap<any>) => enabled && !IsEmpty(dName));
};
