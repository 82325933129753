import { FEDERATION_URL as uri } from 'Graph';
import { CoreGraphLinkProvider } from '@neustar/core-ui';

/**
 * Federation Provider
 * @export
 * @return {CoreGraphProviderClassType} 
 */
export const FederationProvider: CoreGraphProviderClassType = CoreGraphLinkProvider({ uri });

/**
 * Federation Polling Provider
 * @export
 * @return {CoreGraphProviderClassType} 
 */
export const FederationPollingProvider: CoreGraphProviderClassType = CoreGraphLinkProvider({ uri, silent: true });


