import gql from 'graphql-tag';

export const QUERY_RUNBOOK = gql`
  query GetRunbook($companyFilter: CompanyFilterInput) {
    company(filter: $companyFilter) {
      runBook {
        htmlBody
        url
      }
    }
  }
`;
