import gql from 'graphql-tag';

import { PaginationFrag } from '../fragments';

export const QUERY_REPORTS_CONFIGS_BASE = gql`
  query GetExecutiveReportsBase(
    $company: CompanyFilterInput
  ) {
    company(filter: $company) {
      id
    }
  }
`;

export const QUERY_REPORTS_ONE_TIME_CONFIGS = gql`
  query GetExecutiveReportsOnceConfig(
    $company: CompanyFilterInput
    $filter: ExecutiveReportConfigurationFilterInput
    $page: UnsignedInt32! = 1
    $perPage: UnsignedInt32! = 10
  ) {
    company(
      filter: $company
    ) {
      id
      dName
      oneTimeExecutiveReportConfigurations(
        filter: $filter, 
        page: $page, 
        perPage: $perPage
      ) {
        results {
          id
          name
          description
          enabled
          createdAt
          reportType
          from
          to
          includeDDOS
          includeWAF
          includeBot
          notification {
            emailList
          }
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_REPORTS_RECURRING_CONFIGS = gql`
  query GetExecutiveReportsRecurringConfig(
    $company: CompanyFilterInput
    $filter: ExecutiveReportConfigurationFilterInput
    $page: UnsignedInt32! = 1
    $perPage: UnsignedInt32! = 10
  ) {
    company(
      filter: $company
    ) {
      id
      dName
      recurringExecutiveReportConfigurations(
        filter: $filter,
        page: $page, 
        perPage: $perPage
      ) {
        results {
          id
          name
          description
          to
          from
          reportType
          createdAt
          period
          includeWAF
          includeBot
          includeDDOS
          notification {
            emailList
          }
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;


export const QUERY_REPORT_ARCHIVES = gql`
  query GetExecutiveReportArchives(
    $company: CompanyFilterInput
    $filter: ExecutiveReportFilterInput
    $page: UnsignedInt32! = 1
    $perPage: UnsignedInt32! = 10
  ) {
    company(
      filter: $company
    ) {
      id
      dName
      executiveReports(
        filter: $filter,
        page: $page,
        perPage: $perPage
      ) {
        results {
          id
          status
          statusDetails
          generatedTimestamp
          configuration {
            ... on OneTimeExecutiveReportConfiguration {
              id
              name
              description
              reportType
              from
              to
              includeWAF
              includeBot
              includeDDOS
            }
            ... on RecurringExecutiveReportConfiguration {
              id
              name
              description
              reportType
              period
              includeWAF
              includeBot
              includeDDOS
            }
          }
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_REPORTS_DETAIL = gql`
  query GetExecutiveReportsDetail(
    $company: CompanyFilterInput
    $filter: ExecutiveReportConfigurationFilterInput
  ) {
    company(filter: $company) {
      id
      dName
      ONE_TIME: oneTimeExecutiveReportConfigurations(
        filter: $filter
      ) {
        results {
          id
          name
        }
      }
      RECURRING: recurringExecutiveReportConfigurations(
        filter: $filter
      ) {
        results {
          id
          name
        }
      }
    }
  }
`;

export const QUERY_REPORT_RECURRING_CONFIG = gql`
  query GetExecutiveReportRecurringConfig(
    $company: CompanyFilterInput
    $filter: ExecutiveReportConfigurationFilterInput
  ) {
    company(filter: $company) {
      id
      dName
      recurringExecutiveReportConfigurations(
        filter: $filter
      ) {
        results {
          id
          name
          description
          enabled
          period
          from
          to
          includeDDOS
          includeWAF
          includeBot
          notification {
            emailList
          }
        }
      }
    }
  }
`;

export const QUERY_REPORT_ONE_TIME_CONFIG = gql`
  query GetExecutiveReportOneTimeConfigQuery(
    $company: CompanyFilterInput
    $filter: ExecutiveReportConfigurationFilterInput
  ) {
    company(
      filter: $company
    ) {
      id
      dName
      oneTimeExecutiveReportConfigurations(
        filter: $filter
      ) {
        results {
          id
          name
          description
          enabled
          from
          to
          includeDDOS
          includeWAF
          includeBot
          notification {
            emailList
          }
        }
      }
    }
  }
`;

export const QUERY_REPORTS_PDF = gql`
  query GetExecReportPDFQuery(
    $company: CompanyFilterInput
    $filter: ExecutiveReportFilterInput
  ) {
    company(
      filter: $company
    ) {
      id
      executiveReports(
        filter: $filter
      ) {
        results {
          id
          reportBody
        }
      }
    }
  }
`;

export const QUERY_REPORT_CONFIG_EMAILS = gql`
  query GetExecutiveReportsConfigurationEmailsQuery(
    $company: CompanyFilterInput
  ) {
    company(
      filter: $company
    ) {
      id
      recurringExecutiveReportConfigurations {
        results {
          notification {
            emailList
          }
        }
      }
      oneTimeExecutiveReportConfigurations {
        results {
          notification {
            emailList
          }
        }
      }
    }
  }
`;


export const MUTATION_RECURRING_EXECUTIVE_REPORT_CONFIG_CREATE = gql`
  mutation CreateRecurringExecutiveReportConfiguration($input: CreateRecurringExecutiveReportConfigurationInput!) {
    createRecurringExecutiveReportConfiguration(input: $input) {
      configuration {
        id
        name
      }
    }
  }
`;

export const MUTATION_ONE_TIME_EXECUTIVE_REPORT_CONFIG_CREATE = gql`
  mutation CreateOneTimeExecutiveReportConfiguration($input: CreateOneTimeExecutiveReportConfigurationInput!) {
    createOneTimeExecutiveReportConfiguration(input: $input) {
      configuration {
        id
        name
      }
    }
  }
`;

export const MUTATION_RECURRING_EXECUTIVE_REPORT_CONFIG_UPDATE = gql`
  mutation UpdateRecurringExecutiveReportConfiguration($input: UpdateRecurringExecutiveReportConfigurationInput!) {
    updateRecurringExecutiveReportConfiguration(input: $input) {
      configuration {
        id
        name
      }
    }
  }
`;

export const MUTATION_ONE_TIME_EXECUTIVE_REPORT_CONFIG_DELETE = gql`
  mutation DeleteOneTimeExecutiveReportConfiguration($input: DeleteOneTimeExecutiveReportConfigurationInput!) {
    deleteOneTimeExecutiveReportConfiguration(input: $input) {
      deletedOneTimeExecutiveReportConfigurationID
    }
  }
`;

export const MUTATION_RECURRING_EXECUTIVE_REPORT_CONFIG_DELETE = gql`
  mutation DeleteRecurringExecutiveReportConfiguration($input: DeleteRecurringExecutiveReportConfigurationInput!) {
    deleteRecurringExecutiveReportConfiguration(input: $input) {
      deletedRecurringExecutiveReportConfigurationID
    }
  }
`;

export const MUTATION_EXECUTIVE_REPORT_DELETE = gql`
  mutation DeleteExecutiveReport($input: DeleteExecutiveReportInput!) {
    deleteExecutiveReport(input: $input) {
      deletedExecutiveReportID
    }
  }
`;
