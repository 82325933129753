import { NotificationsProvider, GET_NOTIFICATIONS_UNREAD, MUTATION_MARK_ALL_NOTIFICATIONS_READ } from 'Graph';
import { WithCoreNotifications } from '@neustar/core-ui';
import { CORE_EPOCH_MINUTE } from '@neustar/core-ui/lib/utils';

/**
 * Notifications Plugin
 * @export
 * @returns {Promise<boolean>}
 */
export default async function (): Promise<boolean> {
  try {
    /**
     * WithCoreNotifications
     * @note
     * - These arguments replace core-ui's default Apollo structures with
     * Restful Versions that structures specific to the current framework (hybrid).
     */
    WithCoreNotifications(
      NotificationsProvider,
      GET_NOTIFICATIONS_UNREAD,
      MUTATION_MARK_ALL_NOTIFICATIONS_READ,
      CORE_EPOCH_MINUTE,
      `/manage/notifications/view`,
      `/manage/notifications/view`,
      'count.totalItems',
      'company.notificationRecords',
      NotificationsVariableResolver,
      false
    );

    return true;
  } catch (err: any) {
    return Promise.reject(err);
  }
}

function NotificationsVariableResolver(collection) {
  return { input: { userID: null } };
}
