import { CoreGraphQueryConsumer } from '@neustar/core-ui';

import { QUERY_CHECK_COMPANY, QUERY_SEARCH_COMPANY, QUERY_GET_COMPANIES_NESTED, QUERY_CORPORATE_LINEAGE } from './models';

/**
 * Check Company
 */
export const CheckCompanyQuery = CoreGraphQueryConsumer(() => {
	return {
		query: QUERY_CHECK_COMPANY
	};
});

/**
 * Search Companies
 */
export const SearchCompaniesQuery = CoreGraphQueryConsumer(() => {
	return {
		query: QUERY_SEARCH_COMPANY
	};
});

/**
 * Get Companies - list
 */
export const GetCompaniesNestedQuery = CoreGraphQueryConsumer(() => {
	return {
		query: QUERY_GET_COMPANIES_NESTED
	};
});

/**
 * Get Corporate Lineage - list
 */
export const GetCorporateLineageQuery = CoreGraphQueryConsumer(() => {
	return {
		query: QUERY_CORPORATE_LINEAGE
	};
});

