import moment from 'moment';

import { TrafficServiceTypes, TimeUnit } from 'Graph';
import { TrafficDimension } from 'Graph/TrafficService/types';

import { RANGE_OPTIONS, TIMESTAMP_FORMAT, TRAFFIC_LIMIT_DAYS } from '../constants/';

import { WithinDayOf, WithinHourOf } from '@neustar/core-ui/lib/utils/';

/**
 * Build Range FromPreset
 * @export
 * @param {number} index
 * @returns {string}
 */
export function BuildRangeFromPreset(index: number): string {
  const {
    meta: { base, factor },
  } = RANGE_OPTIONS[index];

  const end: Date = new Date();
  const start: Date = new Date(end.getTime() - base * factor);
  const $end: string = StripMillisecondsFromISO(end.toISOString());
  const $start: string = StripMillisecondsFromISO(start.toISOString());
  return [$start, $end].join();
}

/**
 * String Milliseconds From ISO
 * @export
 * @param {string} iso
 * @returns {string}
 */
export function StripMillisecondsFromISO(iso: string): string {
  return iso.replace(/\.\d+([\w]{1})$/, '$1');
}

/**
 * Set Time Interval
 * @export
 * @param {string | number | Date} from
 * @param {string | number | Date} to
 * @returns
 */
export function SetTimeInterval(from: string | number | Date, to: string | number | Date) {
  if (WithinDayOf(from, to)) {
    if (WithinHourOf(from, to)) {
      const unit = TimeUnit.MINUTE;
      const interval = 5;
      return { unit, interval };
    }
    const unit = TimeUnit.HOUR;
    const interval = 1;
    return { unit, interval };
  }
  const unit = TimeUnit.DAY;
  const interval = 1;
  return { unit, interval };
}

/**
 * Group By Date Range
 * @export
 * @param {string | number | Date} from
 * @param {string | number | Date} to
 * @returns {Omit<TrafficGroupBy, 'includeRollup'>}
 */
export function GroupByDateRange(from: string | number | Date, to: string | number | Date): Omit<TrafficServiceTypes.TrafficGroupBy, 'includeRollup'> {
  let diff: number;
  let dimension: TrafficDimension = null;
  let timeMultiplier: any = null;

  const $from = moment(from).utc();
  const $to = moment(to).utc();

  // start diff
  diff = $to.diff($from, 'days', true);

  // kick off @ > 180 days
  dimension = TrafficDimension.DAY;
  timeMultiplier = 1;

  if (diff <= 90) {
    dimension = TrafficDimension.HOUR;
    timeMultiplier = 6;

    // @ 30 days
    if (diff <= 30) {
      dimension = TrafficDimension.HOUR;
      timeMultiplier = 6;

      // @ 7 days
      if (diff <= 7) {
        dimension = TrafficDimension.HOUR;
        timeMultiplier = 1;

        // switching to hours diff
        diff = $to.diff($from, 'hours', true);

        // @ 24 hours
        if (diff <= 24) {
          dimension = TrafficDimension.MINUTE;
          timeMultiplier = 10;

          // @ 12 hours
          if (diff <= 12) {
            dimension = TrafficDimension.MINUTE;
            timeMultiplier = 5;

            // @ 3 hours
            if (diff <= 3) {
              dimension = TrafficDimension.MINUTE;
              timeMultiplier = 1;

              // @ 1 hour
              if (diff <= 1) {
                dimension = TrafficDimension.MINUTE;
                timeMultiplier = 1;
              }
            }
          }
        }
      }
    }
  }

  return { dimension, timeMultiplier };
}

/**
 * Get UTC Days Ago To ISO
 * @export
 * @param {number} [days=0]
 * @param {*} [now=new Date()]
 * @returns {string}
 */
export function GetUTCDaysAgoToISO(days: number = 0, now = new Date()): string {
  const date = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds()));
  date.setUTCDate(date.getUTCDate() - days);
  return date.toISOString().replace(/\.\d+(\w+)$/, '$1');
}

/**
 * Make UTC From Date
 * @export
 * @param {(string | number | Date)} date
 * @returns {*}  {string}
 */
export function MakeUTCFromDate(date: string | number | Date): string {
  const utc: string = moment(date).utc().format(TIMESTAMP_FORMAT);
  return `${utc}Z`;
}

/**
 * Make UTC Time From Date
 * @export
 * @param {(string | number | Date)} date
 * @returns {*}  {number}
 */
export function MakeUTCTimeFromDate(date: string | number | Date): number {
  return moment(date).utc().valueOf();
}

/**
 * Get UTC From Limit
 * @export
 * @returns {Date}  {Date}
 */
export function GetUTCFromLimit(): Date {
  const date: Date = moment.utc(new Date()).toDate();
  date.setUTCDate(date.getUTCDate() - TRAFFIC_LIMIT_DAYS + 1);
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  return date;
}
