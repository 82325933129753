import gql from 'graphql-tag';

import { PaginationFrag } from '../fragments';

export const QUERY_USERS = gql`
  query GetUsers($company: CompanyFilterInput, $page: UnsignedInt32! = 1, $perPage: UnsignedInt32! = 100) {
    company(filter: $company) {
      id
      users(filter: { includeDisabled: true }, page: $page, perPage: $perPage) {
        results {
          id
          enabled
          userName
          email
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const QUERY_USER_LIST = gql`
  query GetUserList(
    $company: CompanyFilterInput
    $filter: CompanyUsersFilterInput = { emailSubstring: null, userNameSubstring: null, includeDisabled: true, includeDescendants: false }
    $page: UnsignedInt32! = 1
    $perPage: UnsignedInt32! = 10
  ) {
    company(filter: $company) {
      dName
      users(filter: $filter, page: $page, perPage: $perPage) {
        results {
          id
          userName
          email
          lastLogin
          enabled
          company {
            id
            dName
            corporateName
            isReseller
          }
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;

const USER_FRAGMENT = gql`
  fragment UserParts on User {
    id
    createdAt
    updatedAt
    enabled
    userName
    firstName
    lastName
    company {
      dName
      corporateName
    }
    email
    lastLogin
    jobTitle
    phone
    mobile
    roles
  }
`;

export const QUERY_USER_DETAIL = gql`
  ${USER_FRAGMENT}
  query UserDetail($userId: String!) {
    user(filter: { id: $userId, includeDisabled: true }) {
      ...UserParts
    }
  }
`;

export const MUTATION_USER_UPDATE = gql`
  ${USER_FRAGMENT}
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...UserParts
      }
    }
  }
`;

export const MUTATION_USER_DELETE = gql`
  mutation UserDelete($userId: String!) {
    deleteUser(input: { id: $userId }) {
      deletedUserID
    }
  }
`;

export const MUTATION_USER_CREATE = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
      }
    }
  }
`;

export const MUTATION_USER_SEND_ACTIVATION_EMAIL = gql`
  mutation SendUserActivationEmail($userId: String!) {
    sendUserActivationEmail(input: { id: $userId }) {
      email
    }
  }
`;

export const MUTATION_USER_SEND_PW_RESET_EMAIL = gql`
  mutation SendPasswordReset($userId: String!) {
    sendUserPasswordResetEmail(input: { id: $userId }) {
      email
    }
  }
`;

export const QUERY_USER_LOGS = gql`
  query UserActivityLogs($userId: String!, $startTime: Time!, $types: String!) {
    userLogs(filter: { id: $userId, startTime: $startTime, types: $types }) {
      date
      type
      description
    }
  }
`;
