import gql from 'graphql-tag';

export const PaginationFrag = gql`
  fragment PaginationFields on Pagination {
    pageNumber
    itemsPerPage
    totalItems
  }
`;

export const EventsMitigationTrafficByTimeFrag = gql`
  fragment EventsMitigationTrafficByTimeFields on TrafficData {
    field
    metric
    values {
      ... on TrafficByTime {
        ts
        v
      }
    }
  }
`;

export const AlertsFieldsFrag = gql`
  fragment AlertsFields on Alert {
    id
    type
    company {
      dName
    }
    start
    end
    severity
  }
`;

export const AlertsListFieldsFrag = gql`
  fragment AlertsListFields on Alert {
    ... on DDOSAlert {
      ...AlertsFields
      details {
        ... on DDOSDNAAlertDetails {
          hostAddress
        }
        ... on DDOSOnNetworkAlertDetails {
          hostAddress
        }
        ... on DDOSTunnelAlertDetails {
          nodeName
          ifAlias
          status
          meta {
            tunnelDestination
          }
        }
      }
    }
    ... on OpenHybridAlert {
      ...AlertsFields
    }
    ... on AppSecAlert {
      ...AlertsFields
      destinationIP {
        string
      }
    }
  }
  ${AlertsFieldsFrag}
`;

export const AlertsDetailsTrafficFieldsFrag = gql`
  fragment AlertsDetailsTrafficFields on AlertDetailsTraffic {
    misuse_types {
      name
      unit
      points {
        x
        y
      }
    }
    patterns {
      source
      protocol
      tcp_flags
      source_ports {
        high
        low
      }
      destination
      destination_ports {
        high
        low
      }
      router
      max_traffic
      max_traffic_unit
    }
    source_prefixes {
      name
      unit
      points {
        x
        y
      }
    }
    destination_prefixes {
      name
      unit
      points {
        x
        y
      }
    }
    source_tcp_ports {
      name
      unit
      points {
        x
        y
      }
    }
    destination_tcp_ports {
      name
      unit
      points {
        x
        y
      }
    }
    source_udp_ports {
      name
      unit
      points {
        x
        y
      }
    }
    destination_udp_ports {
      name
      unit
      points {
        x
        y
      }
    }
    source_asns {
      name
      unit
      points {
        x
        y
      }
    }
    destination_asns {
      name
      unit
      points {
        x
        y
      }
    }
    source_countries {
      name
      unit
      points {
        x
        y
      }
    }
    protocols {
      name
      unit
      points {
        x
        y
      }
    }
    tcp_flags {
      name
      unit
      points {
        x
        y
      }
    }
    icmp_types {
      name
      unit
      points {
        x
        y
      }
    }
    packet_sizes {
      low
      high
      pct95_value
      avg_value
      max_value
    }
    characterization {
      source_prefixes
      destination_prefixes
      source_tcp_ports
      destination_tcp_ports
      source_udp_ports
      destination_udp_ports
      source_asns
      destination_asns
      source_countries {
        code
        name
      }
      protocols
      tcp_flags
    }
  }
`;

export const UserFrag = gql`
  fragment UserFields on User {
    id
    createdAt
    updatedAt
    enabled
    userName
    firstName
    lastName
    company {
      dName
      corporateName
    }
    email
    lastLogin
    jobTitle
    phone
    mobile
    roles
  }
`;

export const ManagedObjectsFieldsFrag = gql`
  fragment ManagedObjectsFields on ManagedObject {
    id
    name
    types
    elementType
    elements {
      ... on ManagedObjectElementGroup {
        values
        tag
      }
      ... on ManagedObjectElementSimple {
        value
      }
    }
  }
`;

export const ManagedObjectHostDetectionFieldsFrag = gql`
  fragment ManagedObjectHostDetectionFields on ManagedObject {
    sharedHostDetectionSettings {
      custom
      description
      enabled
      fastFloodEnabled
      highSeverityDuration
      hostDetection {
        enabled
        highSeverityRate
        misuseType
        name
        triggerRate
      }
      name
      numberOfManagedObjects
    }
  }
`;

const TrafficDataFields = gql`
  fragment TrafficDataFields on TrafficOutput {
    data {
      field
      metric
      values {
        ... on TrafficByTime {
          ts
          v
          __typename
        }
      }
    }
  }
`;

export const cleanTrafficData = gql`
  fragment cleanTrafficData on Company {
    traffic(from: $from, to: $to, field: OUT_BITS_PER_SECOND, metric: MAX) {
      ...TrafficDataFields
    }
  }
  ${TrafficDataFields}
`;

export const mitigatedTrafficData = gql`
  fragment mitigatedTrafficData on Company {
    traffic(from: $from, to: $to, field: MITIGATED_BITS_PER_SECOND, metric: MAX) {
      ...TrafficDataFields
    }
  }
  ${TrafficDataFields}
`;
