import gql from 'graphql-tag';

import { PaginationFrag } from '../fragments';

export const QUERY_AUDITLOGS = gql`
  query GetAuditlogs(
    $company: CompanyFilterInput
    $filter: AuditLogTransactionFilterInput
    $from: Time
    $to: Time
    $page: UnsignedInt32! = 1
    $perPage: UnsignedInt32! = 10
    $sortBy: [AuditLogTransactionSortBy!] = [{ dimension: TIMESTAMP, direction: DESCENDING }]
  ) {
    company(filter: $company) {
      id
      auditLogTransactions(
        filter: $filter, 
        from: $from, 
        to: $to, 
        page: $page, 
        perPage: $perPage
        sortBy: $sortBy, 
      ) {
        results {
          id
          timestamp
          userID
          apiClientID
          user {
            id
            userName
            email
          }
          apiClient {
            id
            name
            description
          }
          applicationID
          messages
          status
          description
          operations {
            id
            timestamp
            action
            status
            description
            serviceID
            callerType
            resourceID
            resourceType
            messages
            company {
              dName
            }
            image {
              oldObj
              newObj
            }
          }
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;
