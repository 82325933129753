import React from 'react';
import { cloneDeep } from 'lodash';

import CompaniesSelectorBase from './SelectorBase';

import Candidate from './Candidate';
import { CompaniesResultsFilter } from '../factories/';
import { CompaniesCandidatesProps, CompaniesResultsFilterType } from '../interfaces/';
import { COMPANIES_CANDIDATES_PROPS } from '../constants';

/**
 * Candidates Component
 * @export
 * @class CandidatesComponent
 * @extends {CompaniesSelectorBase<CompaniesListItemProps}>}
 */
export default class CandidatesComponent extends CompaniesSelectorBase<CompaniesCandidatesProps> {
  /**
   * Default Props
   * @static
   * @type {CompaniesCandidatesProps}
   * @memberof ListSwitchComponent
   */
  public static defaultProps: CompaniesCandidatesProps = cloneDeep(COMPANIES_CANDIDATES_PROPS);

  /**
   * Render
   * @memberof CandidatesComponent
   */
  public render() {
    const { props: { items } } = this;

    if (!items) {
      return null;
    }

    const results: Array<CompaniesResultsFilterType> = CompaniesResultsFilter(items);

    if (!results.length) {
      return null;
    }

    return results.map((item: CompaniesResultsFilterType, ndx: number) => <Candidate key={`cli_${ndx}`} item={item} />);
  }

  /**
   * Subscriber
   * @protected
   * @memberof CandidatesComponent
   */
  protected Subscriber = null;
}
