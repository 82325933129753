import gql from 'graphql-tag';

import { TrafficByKeyFrag } from '../fragments';

export const QUERY_TRAFFIC_NODES = gql`
  query GetTrafficNodesQuery(
    $fields: [TrafficField!]! = [IN_BITS_PER_SECOND, OUT_BITS_PER_SECOND]
    $metrics: [TrafficMetric!]! = [PERCENTILE_95]
    $from: Time!
    $to: Time
    $groupBy: [TrafficGroupBy!] = [{ dimension: NODE }]
    $filter: TrafficFilterInput
  ) {
    traffic(fields: $fields, metrics: $metrics, from: $from, to: $to, groupBy: $groupBy, filter: $filter) {
      ...TrafficByKeyFields
    }
  }
  ${TrafficByKeyFrag}
`;

export const SUBSCRIPTION_TRAFFIC_NODES = gql`
  subscription GetTrafficNodesSubscription(
    $fields: [TrafficField!]! = [IN_BITS_PER_SECOND, OUT_BITS_PER_SECOND]
    $metrics: [TrafficMetric!]! = [PERCENTILE_95]
    $timeSpanMinutes: UnsignedInt32! = 60
    $groupBy: [TrafficGroupBy!] = [{ dimension: NODE }]
    $filter: TrafficFilterInput
  ) {
    traffic(fields: $fields, metrics: $metrics, timeSpanMinutes: $timeSpanMinutes, groupBy: $groupBy, filter: $filter) {
      ...TrafficByKeyFields
    }
  }
  ${TrafficByKeyFrag}
`;

export const QUERY_TRAFFIC_NODES_TOTALS = gql`
  query GetTrafficNodesTotalsQuery(
    $fields: [TrafficField!]! = [IN_BITS_PER_SECOND, OUT_BITS_PER_SECOND]
    $metrics: [TrafficMetric!]! = [PERCENTILE_95]
    $from: Time!
    $to: Time
    $filter: TrafficFilterInput
  ) {
    traffic(fields: $fields, metrics: $metrics, from: $from, to: $to, filter: $filter) {
      ...TrafficByKeyFields
    }
  }
  ${TrafficByKeyFrag}
`;

export const SUBSCRIPTION_TRAFFIC_NODES_TOTALS = gql`
  subscription GetTrafficNodesTotalsSubscription(
    $fields: [TrafficField!]! = [IN_BITS_PER_SECOND, OUT_BITS_PER_SECOND]
    $metrics: [TrafficMetric!]! = [PERCENTILE_95]
    $timeSpanMinutes: UnsignedInt32! = 60
    $filter: TrafficFilterInput
  ) {
    traffic(fields: $fields, metrics: $metrics, timeSpanMinutes: $timeSpanMinutes, filter: $filter) {
      ...TrafficByKeyFields
    }
  }
  ${TrafficByKeyFrag}
`;
