import { TrafficMetric, TrafficField } from 'Graph';

import { RawEventShape, EventDetailShape, RawEventMitigationsCountermeasureShape } from 'App/interfaces/';
import { EventsActiveEnum, ProviderTypeEnum, ProviderTypeCountermeasuresEnum } from 'App/enums/';

import { MakeDuration } from 'App/factories/';

import { GetDeepHashMap, ArrayUnique, IsEmpty } from '@neustar/core-ui/lib/utils/';

/**
 * Make Event Detail
 * @export
 * @param {RawEventShape} { id, company, start, end, destinationIPs, mitigations }
 * @returns {EventDetailShape}
 */
export function MakeEventDetail(event: RawEventShape): EventDetailShape {
  const { id, company, activePrefixes, historicalPrefixes } = event;
  const destinationIPs = ArrayUnique((activePrefixes || []).concat(historicalPrefixes || [])).filter(n => n);
  const { start, end, duration } = MakeDuration(event.start, event.end);
  const status: EventsActiveEnum = end ? EventsActiveEnum.ENDED : start ? EventsActiveEnum.ONGOING : null;
  const mitigations: Array<HashMap<any>> = GetDeepHashMap(event, 'mitigations.results', [], true);
  let providers: Array<string> = [];

  if (mitigations.length) {
    mitigations.reduce((d: Array<string>, { provider }: HashMap<any>) => {
      if (!IsEmpty(provider)) {
        d.push(provider);
      }
      return d;
    }, providers);
    providers = ArrayUnique(providers);
  }

  return { id, company, start, end, duration, destinationIPs, status, providers, mitigations };
}

/**
 * Compile Table Events
 * @export
 * @param {RawEventShape[]} raw
 * @returns {EventDetailShape[]}
 */
export function CompileTableEvents(raw: RawEventShape[]): EventDetailShape[] {
  return raw.reduce((d: EventDetailShape[], event: RawEventShape): EventDetailShape[] => {
    d.push(MakeEventDetail(event));
    return d;
  }, [] as EventDetailShape[]);
}

/**
 * Get Countermeasures By Provider
 * @export
 * @param {ProviderTypeEnum} provider
 * @param {*HashMap<any>} mitigation
 * @returns {Array<HashMap<any>>}
 */
export function GetCountermeasuresByProvider(provider: ProviderTypeEnum, mitigation: HashMap<any>): Array<HashMap<any>> {
  const prop: string = ProviderTypeCountermeasuresEnum[provider];
  return mitigation[prop];
}

/**
 * Compile Events Table Countermeasures
 * @export
 * @param {RawEventMitigationsCountermeasureShape[]} src
 * @param {boolean} [is_bits
 * @returns {HashMap<any>} {HashMap<any>}
 */
export function CompileEventsTableCountermeasures(src: RawEventMitigationsCountermeasureShape[], is_bits: boolean = false): HashMap<any> {
  const collection: HashMap<any> = {};

  const ProcessCountermeasures = (provider: ProviderTypeEnum, countermeasures: Array<HashMap<any>>): void => {
    if (Array.isArray(countermeasures)) {
      if (!collection[provider]) {
        collection[provider] = {};
      }

      countermeasures.forEach(({ name, violationsDetails, trafficData }: HashMap<any>): void => {
        if (violationsDetails || trafficData) {

          // name = name.replace(/\s+/g, '_');

          // set property as 0
          if (!IsEmpty(violationsDetails) || !IsEmpty(trafficData)) {
            if (!collection[provider][name]) {
              collection[provider][name] = 0;
            }
          }
    
          // count waf/bot values
          if (!IsEmpty(violationsDetails)) {
            violationsDetails.forEach(({ value }: HashMap<number>) => {
              collection[provider][name] += value;
            });
          }
    
          // count ddos values
          if (!IsEmpty(trafficData)) {
            trafficData.forEach(({ field, metric, value }: HashMap<any>) => {
              if (metric === TrafficMetric.AVERAGE) {
                const use: boolean = (is_bits && field === TrafficField.MITIGATED_BITS_PER_SECOND) || (!is_bits && field === TrafficField.MITIGATED_PACKETS_PER_SECOND);
                if (use) {
                  collection[provider][name] += value;
                }
              }
            });
          }
        }
      });
    }
  };

  (src||[]).forEach(({ provider, WAFMitigationCountermeasures, BotMitigationCountermeasures, DDOSMitigationCountermeasures }: RawEventMitigationsCountermeasureShape): void => {
    ProcessCountermeasures(provider, WAFMitigationCountermeasures);
    ProcessCountermeasures(provider, BotMitigationCountermeasures);
    ProcessCountermeasures(provider, DDOSMitigationCountermeasures);
  });

  return collection;
}

/**
 * Get Events Table Mitigation Providers
 * @export
 * @param {RawEventMitigationsCountermeasureShape[]} src
 * @returns {*}  {ProviderTypeEnum[]}
 */
export function GetEventsTableMitigationProviders(src: RawEventMitigationsCountermeasureShape[]): ProviderTypeEnum[] {
  const providers: ProviderTypeEnum[] = [];
  (src||[]).reduce((d: ProviderTypeEnum[], { provider }: RawEventMitigationsCountermeasureShape) => {
    d.push(provider);
    return d;
  }, providers);
  return ArrayUnique(providers);
}