import gql from 'graphql-tag';

export const QUERY_TRAFFIC_PERCENTILE95 = gql`
  query GetPercentile95TrafficQuery(
    $fields: [TrafficField!]! = [OUT_BITS_PER_SECOND]
    $metrics: [TrafficMetric!]! = [PERCENTILE_95]
    $from: Time!
    $to: Time
    $filter: TrafficFilterInput
  ) {
    traffic(fields: $fields, metrics: $metrics, from: $from, to: $to, filter: $filter) {
      ... on TrafficOutput {
        data {
          value
        }
      }
    }
  }
`;

export const SUBSCRIPTION_TRAFFIC_PERCENTILE95 = gql`
  subscription GetPercentile95TrafficSubscription(
    $fields: [TrafficField!]! = [OUT_BITS_PER_SECOND]
    $metrics: [TrafficMetric!]! = [PERCENTILE_95]
    $timeSpanMinutes: UnsignedInt32! = 60
    $filter: TrafficFilterInput
  ) {
    traffic(fields: $fields, metrics: $metrics, timeSpanMinutes: $timeSpanMinutes, filter: $filter) {
      ... on TrafficOutput {
        data {
          value
        }
      }
    }
  }
`;
