import '@nux/components';
import { NuxStylesThemes } from '@nux/styles';
import { NuxOverride } from '@nux/core';
import { CoreConfigMeta } from '@neustar/core-ui';

import './nux.scss';

/**
 * Nux Plugin
 * @export
 * @returns {Promise<boolean|Error>}
 */
export default async function NuxPlugin(): Promise<boolean | Error> {
  try {
    if (CoreConfigMeta.WHITELABELED) {
      NuxOverride.buttons.config({ angled: false });
    }

    const theme = CoreConfigMeta.NUXTHEME || (CoreConfigMeta.THEME === 'theme-light' ? 'light' : 'dark');

    NuxStylesThemes.use(theme);

    return true;
  } catch (err: any) {
    throw Error(err);
  }
}
