import { FetchPolicy } from '@apollo/client';

import { CoreConfig, CoreGraphQueryConsumer } from '@neustar/core-ui';
import { CORE_EPOCH_MINUTE } from '@neustar/core-ui/lib/utils/';

import {
  QUERY_ANALYTICS_BASE,
  QUERY_IP_INFO,
  QUERY_WAF_ENRICHMENT_LOGS,
  QUERY_WAF_ENRICHMENT_TYPE,
  QUERY_METHOD_PER_VIP,
  QUERY_BOT_ENRICHMENT_LOGS,
  QUERY_BOT_ENRICHMENT_TYPE,
} from './models';

const pollInterval: number = CORE_EPOCH_MINUTE;
const fetchPolicy: FetchPolicy = 'no-cache';

export const GetAnalyticsBaseQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_ANALYTICS_BASE,
    variables: { company: { dName: CoreConfig.company.dName } },
    fetchPolicy,
  };
});

export const GetApplicationProxiesQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_ANALYTICS_BASE,
    variables: { company: { dName: CoreConfig.company.dName } },
  };
});

export const GetIpInfoQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_IP_INFO,
    variables: { address: { ipv4Address: null } },
  };
});

export const GetWafEnrichmentLogsQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_WAF_ENRICHMENT_LOGS,
    variables: { company: { dName: CoreConfig.company.dName } },
    pollInterval: pollInterval,
    fetchPolicy,
  };
});

export const GetBotEnrichmentLogsQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_BOT_ENRICHMENT_LOGS,
    variables: { company: { dName: CoreConfig.company.dName } },
    pollInterval: pollInterval,
    fetchPolicy,
  };
});

export const GetWafEnrichmentTypeQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_WAF_ENRICHMENT_TYPE,
    variables: { company: { dName: CoreConfig.company.dName } },
    pollInterval: pollInterval,
    fetchPolicy,
  };
});

export const GetBotEnrichmentTypeQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_BOT_ENRICHMENT_TYPE,
    variables: { company: { dName: CoreConfig.company.dName } },
    pollInterval: pollInterval,
    fetchPolicy,
  };
});

export const GetMethodPerVIPQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_METHOD_PER_VIP,
    variables: {},
  };
});
