import React, { MouseEvent } from 'react';

import CompaniesSelectorBase from './SelectorBase';

import { CoreInputLabel, CoreDefaultButton } from '@neustar/core-ui';
import { IsEmpty } from '@neustar/core-ui/lib/utils/';

/**
 * Selector Buttons Component
 * @export
 * @class CompaniesSelectorButtonsComponent
 * @extends {CCompaniesSelectorBase}
 */
export default class CompaniesSelectorButtonsComponent extends CompaniesSelectorBase {
  /**
   * Render
   * @memberof CompaniesSelectorButtonsComponent
   */
  public render() {
    const { OnSelectClick, OnRestoreClick, restore_disabled, context: { term_equals_dname } } = this;

    return (
      <CoreInputLabel key={`company.search.selectors.${Date.now()}`} htmlFor="_">
        <CoreDefaultButton title="Use" className="icon check" onClick={OnSelectClick} disabled={term_equals_dname}/>
        <CoreDefaultButton title="Restore Default" className="icon stop" onClick={OnRestoreClick} disabled={restore_disabled}/>
      </CoreInputLabel>
    );
  }
  
  /**
   * On Select Click
   * @protected
   * @memberof CompaniesSelectorButtonsComponent
   */
  protected OnSelectClick = (evt: MouseEvent<HTMLButtonElement>): void => {
    this.context.submit();
  };
  
  /**
   * On Restore Click
   * @protected
   * @memberof CompaniesSelectorButtonsComponent
   */
  protected OnRestoreClick = (evt: MouseEvent<HTMLButtonElement>): void => {
    this.context.restore();
  };

  /**
   * Subscriber
   * @protected
   * @memberof CompaniesSelectorButtonsComponent
   */
  protected Subscriber = (): void => {
    this.forceUpdate();
  };
  
  /**
   * Restore Disabled - getter
   * @readonly
   * @protected
   * @type {boolean}
   * @memberof CompaniesSelectorButtonsComponent
   */
  protected get restore_disabled(): boolean {
    const { context: { candidate, term_equals_static } } = this;
    if (IsEmpty(candidate)) {
      return term_equals_static;
    }
    return false;
  }
}
