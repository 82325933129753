import { IsSSOQuery } from 'App/factories/';

import { IsAuthenticatedAsync } from '@neustar/core-ui';
import { SearchToHashMap } from '@neustar/core-ui/lib/utils/';

enum SSOReasonEnum {
  INVALID_ROLE = `invalid_role`,
  PERMISSION_DENIED = `permission_denied`,
}

const DEFAULT_MESSAGE: HashMap<string> = { title: 'Federated SSO Log in Error', message: 'Please contact support or your systems administrator to help resolve.' };

const MESSAGES: HashMap<HashMap<string>> = {
  [SSOReasonEnum.INVALID_ROLE]: DEFAULT_MESSAGE,
  [SSOReasonEnum.PERMISSION_DENIED]: DEFAULT_MESSAGE,
};

/**
 * SSO Plugin
 * @export
 * @returns {Promise<boolean|Error>}
 */
export default async function SSOPlugin(): Promise<boolean | Error> {
  try {
    const qobj: HashMap<string> = SearchToHashMap((window as any).location.search);

    if (IsSSOQuery(qobj)) {
      await IsAuthenticatedAsync().catch(async () => {
        const { reason: REASON } = qobj;

        if (REASON) {
          const reason: string = REASON.toLowerCase().replace(/\s+/g, '_');

          switch (reason) {
            case SSOReasonEnum.INVALID_ROLE:
              const { title: T1, message: M1 } = MESSAGES[SSOReasonEnum.INVALID_ROLE];
              await (window as any).alert(M1, T1);
              break;
            case SSOReasonEnum.PERMISSION_DENIED:
              const { title: T2, message: M2 } = MESSAGES[SSOReasonEnum.PERMISSION_DENIED];
              await (window as any).alert(M2, T2);
              break;
          }
        }
      });
    }

    return true;
  } catch (err: any) {
    throw Error(err);
  }
}
