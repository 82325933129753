export const SEARCH_BY_COLUMN_PROPS = {
  auto: true,
  tooltip: false,
  labels: [],
  strict: true,
  min: 3
};

export const SEARCH_BY_COLUMN_STATE = {
  selected: ''
};
