import gql from 'graphql-tag';
import { cleanTrafficData, mitigatedTrafficData } from '../fragments';

export const QUERY_TRAFFIC = gql`
  query GetTraffic($from: Time!, $to: Time, $filter: CompanyFilterInput) {
    clean: company(filter: $filter) {
      ...cleanTrafficData
    }
    mitigated: company(filter: $filter) {
      ...mitigatedTrafficData
    }
  }
  ${cleanTrafficData}
  ${mitigatedTrafficData}
`;

export const QUERY_PERCENTILE_95 = gql`
  query GetPercentile95($from: Time!, $to: Time, $filter: CompanyFilterInput) {
    company(filter: $filter) {
      traffic(from: $from, to: $to, field: OUT_BITS_PER_SECOND, metric: PERCENTILE_95) {
        data {
          value
        }
      }
    }
  }
`;

export const QUERY_NODES = gql`
  query GetNodes($from: Time!, $to: Time, $filter: CompanyFilterInput) {
    company(filter: $filter) {
      traffic(from: $from, to: $to, field: OUT_BITS_PER_SECOND, metric: PERCENTILE_95, groupBy: {dimension: NODE}) {
        data {
          field
          metric
          values {
            ... on TrafficByKey {
              k
              v
            }
          }
        }
      }
    }
  }
`;

export const QUERY_TUNNEL_TRAFFIC = gql`
  query GetTunnelTraffic($from: Time!, $to: Time, $filter: CompanyFilterInput) {
    company(filter: $filter) {
      traffic(from: $from, to: $to, field: OUT_BITS, metric: SUM, groupBy: {dimension: INTERFACE_PATH}) {
        data {
          values {
            ... on TrafficByKey {
              interfacePath: k
              v
            }
          }
        }
      }
      tunnels {
        id
        interfacePath
        description
        tunnelSource
        tunnelDestination
        networkNodes {
          iataCode
        }
        type
      }
      status(groupBy: {dimension: INTERFACE_PATH}) {
        data {
          groupedBy
          values {
            ... on StatusByKey {
              interfacePath: k
              status
            }
          }
        }
      }
    }
  }
`;
