import { ProviderTypeEnum, EventsActiveEnum } from 'App/enums/';

export const EVENTS_PARAMS_STATE = {
  lookup: false
};

export const EVENT_DURATION_PROPS = {
  start: '',
  end: '',
  status: EventsActiveEnum.ONGOING
};

export const ATTACK_PROVIDER_TYPE_MAP = {
  [ProviderTypeEnum.WAFMitigation]: 'waf',
  [ProviderTypeEnum.DDOSMitigation]: 'ddos',
  [ProviderTypeEnum.BotMitigation]: 'bot'
};

export const ATTACK_PROVIDER_LABEL_MAP = {
  [ProviderTypeEnum.WAFMitigation]: 'WAF',
  [ProviderTypeEnum.DDOSMitigation]: 'DDoS',
  [ProviderTypeEnum.BotMitigation]: 'Bot',
};