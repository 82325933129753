import { FetchPolicy, ErrorPolicy } from '@apollo/client';
import { CoreConfig, CoreGraphQueryConsumer, CoreGraphMutationConsumer } from '@neustar/core-ui';

import { QUERY_CERTIFICATES, MUTATION_CERTIFICATE_CREATE, MUTATION_CERTIFICATE_DELETE } from './models';

const fetchPolicy: FetchPolicy = 'no-cache';
const errorPolicy: ErrorPolicy = 'all';

/**
 * Get Certificates - list
 */
export const GetCertificatesQuery = CoreGraphQueryConsumer(() => {
  return {
    query: QUERY_CERTIFICATES,
    variables: { company: { dName: CoreConfig.company.dName } },
    fetchPolicy,
  };
});

/**
 * Post Create Certificate - mutation
 */
export const PostCreateCertificateMutation = CoreGraphMutationConsumer(() => {
  return {
    mutation: MUTATION_CERTIFICATE_CREATE,
    variables: { company: { dName: CoreConfig.company.dName } },
    errorPolicy,
  };
});

/**
 * Post Delete Certificate - mutation
 */
export const PostDeleteCertificateMutation = CoreGraphMutationConsumer(() => {
  return {
    mutation: MUTATION_CERTIFICATE_DELETE,
    variables: { company: { dName: CoreConfig.company.dName } },
    errorPolicy,
  };
});
