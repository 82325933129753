import gql from 'graphql-tag';

import { PaginationFrag } from '../fragments';

export * from '../fragments';

export const RelaxationRulesFrag = gql`
  fragment HTMLSQLInjectionRelaxationRulesFields on HTMLSQLInjectionRelaxationRulesWithPagination {
    results {
      name
      enabled
      isNameRegex
      url
      location
      valueType
      isValueExpressionRegex
      valueExpression
    }
  }
  fragment XMLXSSRelaxationRulesFields on XMLXSSRelaxationRulesWithPagination {
    results {
      name
      enabled
      isNameRegex
      location
    }
  }
  fragment XMLSQLInjectionRelaxationRulesFields on XMLSQLInjectionRelaxationRulesWithPagination {
    results {
      name
      enabled
      isNameRegex
      location
    }
  }
  fragment HTMLXSSRelaxationRulesFields on HTMLXSSRelaxationRulesWithPagination {
    results {
      name
      enabled
      isNameRegex
      url
      location
      valueType
      isValueExpressionRegex
      valueExpression
    }
  }
  fragment CommandInjectionRelaxationRulesFields on CommandInjectionRelaxationRulesWithPagination {
    results {
      name
      enabled
      isNameRegex
      url
      location
      valueType
      isValueExpressionRegex
      valueExpression
    }
  }
  fragment JSONXSSRelaxationRulesFields on JSONXSSRelaxationRulesWithPagination {
    results {
      enabled
      exemptURL
    }
  }
  fragment JSONSQLInjectionRelaxationRulesFields on JSONSQLInjectionRelaxationRulesWithPagination {
    results {
      enabled
      exemptURL
    }
  }
`;


export const BotBindingsFrag = gql`
  fragment BotBlacklistBindings on BotBlackListTypesWithPagination {
    results {
      type
      active
      value
      response
      action
      expressionMatch {
        field
        fieldValue
        operand
        operandValue
      }
    }
  }
  fragment BotWhitelistBindings on BotWhiteListTypesWithPagination {
    results {
      type
      active
      value
      response
      expressionMatch {
        field
        fieldValue
        operand
        operandValue
      }
    }
  }
  fragment BotIPReputationCategories on BotIPReputationCategoriesWithPagination {
    results {
      type
      active
      response
      action
    }
  }
  fragment BotRateLimitResources on BotRateLimitResourcesWithPagination {
    results {
      type
      active
      cookieName
      urlPath
      rate
      period
      response
      action
    }
  }
  fragment BotTPSResources on BotTPSResourcesWithPagination {
    results {
      type
      fixedThreshold
      percentageThreshold
      response
      action
    }
  }
  fragment BotCAPTCHAResources on BotCAPTCHAResourcesWithPagination {
    results {
      urlPath
      active
      waitTime
      gracePeriod
      mutePeriod
      requestLengthLimit
      retryAttempts
      response
      action
    }
  }
  fragment BotSignatures on ConfiguredBaseBotSignature {
    signature {
      id
      botType
      category
      defaultAction
      defaultEnabled
      description
      version
    }
    enabled
    action
  }
  fragment BotTrapInsertionURLs on BotTrapBinding {
    urlPath
    active
  }
`;

export const AppSecThresholdFrag = gql`
  fragment AppSecThresholdFields on AppSecThreshold {
    dimension
    key
    count
    bucketDurationSeconds
  }
`;

export const PartialThresholdFrag = gql`
  fragment PartialThresholdFields on AppSecThreshold {
    count
    bucketDurationSeconds
  }
`;

export const NetworkControlsFrag = gql`
  fragment NetworkControlsFields on NetworkControls {
    blockedCountries
    ipFilterList {
      results {
        cidr
        isBlocked
      }
    }
  }
`;

export const LearningRulesFrag = gql`
  fragment LearningRulesFields on LearningRules {
    cookieConsistencyRules {
      results {
        count
        rule {
          enabled
          cookieName
        }
      }
    }
    fieldFormatRules {
      results {
        count
        rule {
          enabled
          formFieldName
          actionURL
          fieldType
          minLength
          maxLength
        }
      }
    }
    fieldConsistencyRules {
      results {
        count
        rule {
          enabled
          formFieldName
          actionURL
        }
      }
    }
    csrfSettingsRules {
      results {
        count
        rule {
          enabled
          formOriginURL
          formActionURL
        }
      }
    }
    contentTypeRules {
      results {
        count
        rule {
          enabled
          contentType
        }
      }
    }
    htmlSQLInjectionRules {
      results {
        count
        rule {
          name
          enabled
          url
          location
          valueType
          valueExpression
        }
      }
    }
    crossSiteScriptingRules {
      results {
        count
        rule {
          name
          enabled
          url
          location
          valueType
          valueExpression
        }
      }
    }
    wsiSettingsRules {
      results {
        count
        rule {
          enabled
          ruleID
          code
          description
        }
      }
    }
  }
`;

export const ResponderPolicyFrag = gql`
  fragment ResponderPolicyeFields on ResponderPolicy {
    name
    action
    response
    responderMatches {
      field
      operand
      value
    }
  }
`;

export const TrustedSourceFrag = gql`
  fragment TrustedSourceFields on TrustedSourcesWithPagination {
    results {
      enabled
      cidr
      description
    }
  }
`;

export const SignatureRequestRuleFrag = gql`
  fragment SignatureRequestRuleFields on SignatureRequestRule {
    area
    cookieRule {
      cookieNameFormat
      cookieName
    }
    headerRule {
      headerNameFormat
      headerName
    }
    formFieldSignatureRule {
      fieldNameFormat
      fieldName
      urlFormat
      url
    }
    matchFormat
    match
  }
`;

export const SignatureResponseRuleFrag = gql`
  fragment SignatureResponseRuleFields on SignatureResponseRule {
    area
    matchFormat
    match
  }
`;

export const CustomWAFSignatureFrag = gql`
  fragment CustomWAFSignatureFields on CustomWAFSignature {
    category
    description
    action
    requestRules {
      ...SignatureRequestRuleFields
    }
    responseRules {
      ...SignatureResponseRuleFields
    }
  }
  ${SignatureRequestRuleFrag}
  ${SignatureResponseRuleFrag}
`;

export const ConfiguredBaseWAFSignatureFrag = gql`
  fragment ConfiguredBaseWAFSignatureFields on ConfiguredBaseWAFSignaturesWithPagination {
    results {
      signature {
        defaultAction
        id
        category
        description
        refs
        createdAt
      }
      action
    }
    pageInfo {
      ...PaginationFields
    }
  }
  ${PaginationFrag}
`;

export const ConfiguredBaseWAFSignaturesWithPaginationFrag = gql`
  fragment ConfiguredBaseWAFSignaturesWithPaginationFields on ConfiguredBaseWAFSignaturesWithPagination {
    ...ConfiguredBaseWAFSignatureFields
  }
  ${ConfiguredBaseWAFSignatureFrag}
`;

export const CustomWAFSignaturesWithPaginationFrag = gql`
  fragment CustomWAFSignaturesWithPaginationFields on CustomWAFSignaturesWithPagination {
    results {
      ...CustomWAFSignatureFields
    }
    pageInfo {
      ...PaginationFields
    }
  }
  ${CustomWAFSignatureFrag}
  ${PaginationFrag}
`;

export const WAFSignaturesFrag = gql`
  fragment WAFSignaturesFields on WAFSignatures {
    configuredBaseSignatures {
      ...ConfiguredBaseWAFSignaturesWithPaginationFields
    }
    customSignatures {
      ...CustomWAFSignaturesWithPaginationFields
    }
  }
  ${ConfiguredBaseWAFSignaturesWithPaginationFrag}
  ${CustomWAFSignaturesWithPaginationFrag}
`;

export const WAFProfileFrag = gql`
  fragment WAFProfileFields on WAFProfile {
    enabled
    signatures {
      ...WAFSignaturesFields
    }
    semicolonFieldSeparator {
      enabled
    }
    postBody {
      limit
      threshold {
        ...PartialThresholdFields
      }
    }
    bufferOverflow {
      action
      maxURLLength
      maxHeaderLength
      maxCookieLength
      threshold {
        ...PartialThresholdFields
      }
    }
    contentType {
      action
      learn
      relaxationRules {
        results {
          enabled
          contentType
        }
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    cookieConsistency {
      action
      learn
      relaxationRules {
        results {
          isRegex
          enabled
          cookieName
        }
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    crossSiteScripting {
      action
      learn
      checkCompleteURLs
      relaxationRules {
        ...HTMLXSSRelaxationRulesFields
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    csrfSettings {
      action
      learn
      relaxationRules {
        results {
          enabled
          formOriginURL
          formActionURL
        }
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    denyURL {
      action
      regexRules {
        results {
          type
          enabled
          denyURL
        }
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    fieldFormat {
      action
      learn
      type
      minLength
      maxLength
      enforcementRules {
        results {
          enabled
          isFormFieldRegex
          formFieldName
          actionURL
          fieldType
          minLength
          maxLength
        }
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    fieldConsistency {
      action
      learn
      sessionlessFieldConsistency
      fieldConsistencyExemptions {
        results {
          enabled
          isFormFieldRegex
          formFieldName
          actionURL
        }
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    htmlSQLInjection {
      action
      learn
      checkSQLWildChars
      sqlInjectionType
      sqliGrammar
      exemptCommentsWith
      relaxationRules {
        ...HTMLSQLInjectionRelaxationRulesFields
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    httpRFCProfile {
      action
      threshold {
        ...PartialThresholdFields
      }
    }
    wsiSettings {
      action
      learn
      standards {
        results {
          enabled
          ruleID
        }
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    xmlCrossSiteScripting {
      action
      relaxationRules {
        ...XMLXSSRelaxationRulesFields
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    xmlFormat {
      action
      threshold {
        ...PartialThresholdFields
      }
    }
    xmlSOAPFault {
      action
      threshold {
        ...PartialThresholdFields
      }
    }
    xmlSQLInjection {
      action
      checkSQLWildChars
      sqlInjectionType
      exemptCommentsWith
      relaxationRules {
        ...XMLSQLInjectionRelaxationRulesFields
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    commandInjection {
      action
      commandInjectionType
      relaxationRules {
        ...CommandInjectionRelaxationRulesFields
      }
      threshold {
        ...PartialThresholdFields
      }
    }
    jsonSettings {
      jsonCrossSiteScriptingSettings {
        action
        relaxationRules {
          ...JSONXSSRelaxationRulesFields
        }
        threshold {
          ...PartialThresholdFields
        }
      }
      jsonDenialOfServiceSettings {
        action
        enforcementRule {
          enabled
          jsonMaxArrayLength
          jsonMaxContainerDepth
          jsonMaxDocumentLength
          jsonMaxObjectKeyCount
          jsonMaxObjectKeyLength
          jsonMaxStringLength
        }
        threshold {
          ...PartialThresholdFields
        }
      }
      jsonSQLInjectionSettings {
        action
        relaxationRules {
          ...JSONSQLInjectionRelaxationRulesFields
        }
        sqlInjectionType
        sqliGrammar
        threshold {
          ...PartialThresholdFields
        }
      }
    }
  }
  ${PartialThresholdFrag}
  ${AppSecThresholdFrag}
  ${RelaxationRulesFrag}
  ${WAFSignaturesFrag}
`;


export const BotProfileFrag = gql`
  fragment BotProfileFields on BotProfile {
    enabled
    blackList {
      enabled
      types {
        ...BotBlacklistBindings
      }
    }
    whiteList {
      enabled
      types {
        ...BotWhitelistBindings
      }
    }
    ipReputation {
      enabled
      categories {
        ...BotIPReputationCategories
      }
    }
    rateLimit {
      enabled
      resources {
        ...BotRateLimitResources
      }
    }
    deviceFingerprint {
      enabled
      response
      action
    }
    botTrap {
      enabled
      response
      action
      insertionURLs {
        ...BotTrapInsertionURLs
      }
    }
    tps {
      enabled
      resources {
        ...BotTPSResources
      }
    }
    captcha {
      resources {
        ...BotCAPTCHAResources
      }
    }
    signatures {
      enabled
      configuredBaseSignatures {
        ...BotSignatures
      }
    }
  }
  ${BotBindingsFrag}
`;
