import React from 'react';

import '../NewExperienceToggle.scss';

import { NewExperienceToggleContext } from '../contexts';
import { NewExperienceToggleComponent } from '../components';
import { NewExperienceToggleContainerProps, NewExperienceToggleServiceShape } from '../interfaces';
import { NewExperienceToggleService } from '../services';

/**
 * New Experience Toggle Component
 * @param {<NewExperienceToggleContainerProps>}
 * @returns {JSX.Element}
 */
const NewExperienceToggleContainer: React.FC<NewExperienceToggleContainerProps> = ({ onChange, storageName }): React.JSX.Element => {
  const value: NewExperienceToggleServiceShape = new NewExperienceToggleService(storageName);
  return (
    <NewExperienceToggleContext.Provider value={value}>
      <NewExperienceToggleComponent onChange={onChange} />
    </NewExperienceToggleContext.Provider>
  );
}

export default NewExperienceToggleContainer;
