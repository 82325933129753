import gql from 'graphql-tag';

import { PaginationFrag } from '../fragments';

/**
 * These models purposely have no CompanyFilterInput
 * applied so that admins may not mistakenly traverse
 * the workflow on a company not their own.
 */
export const QUERY_FEDERATED_SSO = gql`
  query GetFederatedSSO($company: CompanyFilterInput = null) {
    company(filter: $company) {
      id
      ssoPackage {
        config {
          id
          createdAt
          updatedAt
          auth0ConnectionID
          connectionName
          status
          domain
          signInURL
          sslCertificate
          signatureRequest
          userIDAttributes
          protocolBinding
          signatureAlgorithm
          signatureDigestAlgorithm
          requestTemplate
        }
        idpInfo {
          connectionName
          entityID
          postBackURL
        }
      }
    }
  }
`;

export const QUERY_FEDERATED_SSO_USER_PURGE_LIST = gql`
  query GetFederatedSSOUserPurgeList($company: CompanyFilterInput = null, $page: UnsignedInt32 = 1, $perPage: UnsignedInt32 = 100) {
    company(filter: $company) {
      id
      userPurgeList(page: $page, perPage: $perPage) {
        results {
          loginID
          name
          companyDName
          roles
        }
        pageInfo {
          ...PaginationFields
        }
      }
    }
  }
  ${PaginationFrag}
`;

export const MUTATION_CREATE_SAML_CONNECTION = gql`
  mutation CreateSAMLConnection($input: CreateSAMLInput!) {
    createSAMLConnection(input: $input) {
      config {
        id
        status
      }
    }
  }
`;

export const MUTATION_UPDATE_SAML_CONNECTION = gql`
  mutation UpdateSAMLConnection($input: UpdateSAMLInput!) {
    updateSAMLConnection(input: $input) {
      config {
        id
        status
      }
    }
  }
`;

export const MUTATION_COMMIT_SAML_CONNECTION = gql`
  mutation CommitSAMLConnection($input: SAMLInput!) {
    commitSAMLConnection(input: $input) {
      config {
        id
        status
      }
    }
  }
`;
export const MUTATION_DELETE_SAML_CONNECTION = gql`
  mutation DeleteSAMLConnection($input: SAMLInput!) {
    deleteSAMLConnection(input: $input) {
      config {
        id
        status
      }
    }
  }
`;
