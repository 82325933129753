/* istanbul ignore file */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './core-ui.scss';

import reportWebVitals from './reportWebVitals';

import App from './App';
import { AuthPlugin, SSOPlugin, PermissionsPlugin, SitePlugin, DomainPlugin, FusionPlugin, NavigationPlugin, HeaderPlugin, NotificationsPlugin, NuxPlugin } from './Plugins/';
import { CoreBootLoader } from '@neustar/core-ui';

CoreBootLoader(App, AuthPlugin, SSOPlugin, PermissionsPlugin, SitePlugin, DomainPlugin, NavigationPlugin, HeaderPlugin, NotificationsPlugin, FusionPlugin, NuxPlugin);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== 'production') {
  reportWebVitals((...args: any[]) => {
    console.log('web-vitals', args);
  });
}
