import React from 'react';
import * as jsdiff from 'diff';

const addedBgColor: string = '#1abd5c';

const removedBgColor: string = '#B71D22';

export function JSONDiff(oldObj: string | object, newObj: string | object, options?: jsdiff.JsonOptions) {
  const diff = jsdiff.diffJson(oldObj, newObj, options);
  return diff.map(function (part, ndx) {
    const style = {
      backgroundColor: part.added ? addedBgColor : part.removed ? removedBgColor : '',
    };
    return (
      <span key={`json.diff.${ndx}`} style={style}>
        {part.value}
      </span>
    );
  });
}
