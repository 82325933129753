import React, { Component, FormEvent, KeyboardEvent, ChangeEvent } from 'react';
import { cloneDeep } from 'lodash';

import { SEARCH_BY_COLUMN_PROPS, SEARCH_BY_COLUMN_STATE } from '../constants';
import { SearchByColumnProps, SearchByColumnState } from '../interfaces/';

import CoreTableContext from '@neustar/core-ui/lib/shared/modules/tables/context';

import { CoreFormGroup, CoreFormGroupInline, CoreInputLabel, CoreFormElementSearch, CoreFormElementRadio, CoreTooltip, CoreHelp } from '@neustar/core-ui';

import { MergeClassNames, AsyncComponentTimeout, WaitForDelay, IsNull } from '@neustar/core-ui/lib/utils/';

/**
 * Table Search By Column Component
 * @export
 * @class TableSearchByColumnContainer
 * @extends {Component<SearchByColumnProps, SearchByColumnState>}
 */
export default class TableSearchByColumnContainer extends Component<SearchByColumnProps, SearchByColumnState> {
  /**
   * Context
   * @static
   * @type {Readonly<typeof CoreTableContext>}
   * @memberof TableSearchByColumnContainer
   */
  public static contextType: Readonly<typeof CoreTableContext> = CoreTableContext;

  /**
   * State
   * @type {SearchByColumnState}
   * @memberof TableSearchByColumnContainer
   */
  public readonly state: SearchByColumnState = cloneDeep(SEARCH_BY_COLUMN_STATE);

  /**
   * Default Props
   * @static
   * @type {SearchByColumnProps}
   * @memberof TableSearchByColumnContainer
   */
  public static defaultProps: SearchByColumnProps = cloneDeep(SEARCH_BY_COLUMN_PROPS);

  /**
   * Watch
   * @private
   * @type {Required<AsyncComponentTimeout>}
   * @memberof TableSearchByColumnContainer
   */
  private watch: Required<AsyncComponentTimeout> = new AsyncComponentTimeout();

  /**
   * Abort Controller Listener
   * @private
   * @type {AbortController}
   * @memberof TableSearchByColumnContainer
   */
  private _listener: AbortController;

  /**
   * Fields
   * @protected
   * @type {string[]}
   * @memberof TableSearchByColumnContainer
   */
  protected fields: string[] = [];

  /**
   * Options
   * @protected
   * @type {CoreFormOptionsType}
   * @memberof TableSearchByColumnContainer
   */
  protected options: CoreFormOptionsType = [];

  /**
   * Term
   * @protected
   * @type {string}
   * @memberof TableSearchByColumnContainer
   */
  protected term: string = '';

  /**
   * Creates an instance of TableSearchByColumnContainer.
   * @param {SearchByColumnProps} props
   * @param {CoreTableServiceType} context
   * @memberof TableSearchByColumnContainer
   */
  constructor(props: SearchByColumnProps, context: CoreTableServiceType) {
    super(props);
    this.fields = context.search.fields.slice(0);
    let label: string;
    this.fields.reduce((d: CoreFormOptionsType, value: string, ndx: number) => {
      label = props.labels[ndx] || undefined;
      d.push({ label, value });
      return d;
    }, this.options);
  }

  /**
   * LiefCycle Hook
   * @memberof TableSearchByColumnContainer
   */
  public componentDidMount() {
    const {
      context: { mode, search },
    } = this;
    const { searchable, term } = search;

    this._listener = new AbortController();

    try {
      /* istanbul ignore next */
      if (mode !== 'connected') {
        throw new ReferenceError('Table must be configured with mode=[connected].');
      }

      /* istanbul ignore next */
      if (!searchable) {
        throw new ReferenceError('Table must be configured with search params for this component.');
      }

      const selected: string = this.fields[0];

      WaitForDelay().then(() => {
        if (!this.listener.signal.aborted) {
          search.update(term, [selected]);
          this.setState({ selected });
        }
      });
    } catch (err: any) {
      /* istanbul ignore next */
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`DEVELOPER ERROR:: ${err.message || err}`);
      }
    }
  }

  /**
   * LiefCycle Hook
   * @memberof TableSearchByColumnContainer
   */
  public componentWillUnmount() {
    const { listener } = this;
    if (listener) {
      listener.abort();
    }
    this.watch.clear();
  }

  /**
   * Render
   * @returns
   * @memberof TableSearchByColumnContainer
   */
  public render() {
    const {
      OnInput,
      OnKeyDown,
      context,
      fields,
      options,
      state: { selected },
      props: { auto, min, labels, placeholder: PLACEHOLDER, tooltip: TOOLTIP, className },
    } = this;
    const placeholder: string = PLACEHOLDER || 'filter...';
    const clz = MergeClassNames(className, {});
    const tooltip: string = fields.length > 1 ? `fields: ${fields.join(', ')}` : `field: ${fields[0]}`;
    const HasLabels: boolean = labels.length > 0;

    return (
      <CoreFormGroup className="table-search">
        <CoreFormGroupInline>
          <CoreFormGroup>
            <CoreInputLabel>
              <CoreFormElementSearch
                name="term"
                onInput={OnInput}
                onKeyDown={OnKeyDown}
                className={clz}
                placeholder={placeholder}
                autoComplete="off"
                key={`table.term.${Date.now()}`}
              />
              {TOOLTIP ? <CoreTooltip tooltip={tooltip} /> : null}
            </CoreInputLabel>
            {HasLabels ? null : <>{auto ? <AutoSearchOnHelp min={min} /> : <AutoSearchOffHelp min={min} />}</>}
          </CoreFormGroup>
          {HasLabels ? (
            <CoreFormGroup>
              <label className="input-label radio as-micro-buttons" htmlFor="_">
                {options.map(({ label, value }: CoreFormOptionType, ndx: number) => {
                  const checked: boolean = value === selected ? true : false;
                  const clz = MergeClassNames('', { checked });
                  return (
                    <label key={`rb.${ndx}`} className={clz}>
                      <span>{label}</span>
                      <CoreFormElementRadio
                        name="field"
                        value={value}
                        checked={checked}
                        onChange={({ target: { value: selected } }: ChangeEvent<HTMLInputElement>) => {
                          const { term } = context.search;
                          WaitForDelay().then(() => {
                            if (!this.listener.signal.aborted) {
                              this.setState({ selected });
                              context.search.update(term, [selected]);
                              context.process();
                            }
                          });
                        }}
                      />
                    </label>
                  );
                })}
              </label>
            </CoreFormGroup>
          ) : null}
        </CoreFormGroupInline>
      </CoreFormGroup>
    );
  }

  /**
   * On Input
   * @protected
   * @memberof TableSearchByColumnContainer
   */
  /* istanbul ignore next */
  protected OnInput = (evt: FormEvent<HTMLInputElement>, value: any): void => {
    const {
      context,
      state: { selected },
      props: { auto, min, strict },
    } = this;

    if (auto) {
      const delay: number = value.length >= min ? 500 : strict ? null : 1000;
      if (IsNull(delay)) {
        if (value === '') {
          if (!this.listener.signal.aborted) {
            context.search.update(value, [selected]);
            context.pagination.update(0);
            context.process();
          }
        }
      } else {
        this.watch.delay(delay).then(() => {
          if (!this.listener.signal.aborted) {
            context.search.update(value, [selected]);
            context.pagination.update(0);
            context.process();
          }
        });
      }
    } else {
      this.term = value.length >= min ? value : '';
    }
  };

  /**
   * On Key Down
   * @protected
   * @memberof TableSearchByColumnContainer
   */
  protected OnKeyDown = (evt: KeyboardEvent<HTMLInputElement>): void => {
    const {
      context,
      term,
      state: { selected },
      props: { auto },
    } = this;
    const { key } = evt;

    if (!!~[13, 'Enter'].indexOf(key)) {
      evt.preventDefault();
      evt.stopPropagation();

      if (!auto) {
        if (!this.listener.signal.aborted) {
          context.search.update(term, [selected]);
          context.pagination.update(0);
          context.process();
        }
      }
    }
  };

  /**
   * Listener - getter
   * @readonly
   * @memberof TableSearchByColumnContainer
   */
  public get listener() {
    return this._listener;
  }
}

/**
 * Auto Search Off Help
 * @returns {*}
 */
const AutoSearchOffHelp = ({ min }: GenericPropType) => {
  return (
    <CoreHelp title={`Auto Searching is off`}>
      <p>Click the &ldquo;Enter&rdquo; key to search on term.</p>
      <p>Search term must be at least {min} characters.</p>
    </CoreHelp>
  );
};
AutoSearchOffHelp.displayName = 'CoreHelp';

/**
 * Auto Search On Help
 * @returns {*}
 */
const AutoSearchOnHelp = ({ min }: GenericPropType) => {
  return (
    <CoreHelp title={`Auto Searching is on`}>
      <p>Search will occur as you type.</p>
    </CoreHelp>
  );
};
AutoSearchOnHelp.displayName = 'CoreHelp';
