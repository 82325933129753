import gql from 'graphql-tag';

import { CompanyCertificatesFrag, CompanyProxiesFrag } from './fragments';

export const QUERY_CERTIFICATES = gql`
  query GetCertificates($company: CompanyFilterInput) {
    company(filter: $company) {
      id
      ...CompanyProxiesFields
      ...CompanyCertificatesFields
    }
  }
  ${CompanyProxiesFrag}
  ${CompanyCertificatesFrag}
`;

export const MUTATION_CERTIFICATE_CREATE = gql`
  mutation CreateCertificate($input: CreateCertificateInput!) {
    createCertificate(input: $input) {
      certificate {
        id
        name
      }
    }
  }
`;

export const MUTATION_CERTIFICATE_DELETE = gql`
  mutation DeleteCertificate($input: DeleteCertificateInput!) {
    deleteCertificate(input: $input) {
      deletedCertificateID
    }
  }
`;
