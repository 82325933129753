/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The status of a contract negotiated with customer.
 */
export enum ActivationStatus {
  ACTIVATED = 'ACTIVATED',
  EDIT_UNDER_REVIEW = 'EDIT_UNDER_REVIEW',
  PENDING_CUSTOMER_SIGNATURE = 'PENDING_CUSTOMER_SIGNATURE',
  SUBMISSION_UNDER_REVIEW = 'SUBMISSION_UNDER_REVIEW',
}

export enum AlertClassification {
  FALSE_POSITIVE = 'FALSE_POSITIVE',
  FLASH_CROWD = 'FLASH_CROWD',
  NETWORK_FAILURE = 'NETWORK_FAILURE',
  POSSIBLE_ATTACK = 'POSSIBLE_ATTACK',
  TRIVIAL = 'TRIVIAL',
  VERIFIED_ATTACK = 'VERIFIED_ATTACK',
}

/**
 * One of the sorted order fields.
 */
export enum AlertDimension {
  END = 'END',
  SEVERITY = 'SEVERITY',
  START = 'START',
}

export enum AlertType {
  APP_SEC = 'APP_SEC',
  BGP_DOWN = 'BGP_DOWN',
  CLOUD_SIGNAL_FAULT = 'CLOUD_SIGNAL_FAULT',
  CLOUD_SIGNAL_MITIGATION_REQUEST = 'CLOUD_SIGNAL_MITIGATION_REQUEST',
  DNA = 'DNA',
  FLOW_DOWN = 'FLOW_DOWN',
  ON_NETWORK = 'ON_NETWORK',
  OPEN_HYBRID = 'OPEN_HYBRID',
  SNMP_DOWN = 'SNMP_DOWN',
  TUNNEL = 'TUNNEL',
}

/**
 * Algorithm used for signature request.
 */
export enum Algorithm {
  SHA1 = 'SHA1',
  SHA256 = 'SHA256',
}

/**
 * Allowed list of values for results to be grouped by.
 */
export enum AppDataAggregateByField {
  METHOD = 'METHOD',
  VIP = 'VIP',
}

/**
 * Allowed list of request types for any given query.
 */
export enum AppDataField {
  CONNECTIONS = 'CONNECTIONS',
  FAILURES = 'FAILURES',
  INVALIDS = 'INVALIDS',
  VALIDS = 'VALIDS',
}

/**
 * Allowed values for a WAF Application Security (AppSec) Threshold Configuration Dimension.
 */
export enum AppSecThresholdDimension {
  BUFFER_OVERFLOW = 'BUFFER_OVERFLOW',
  COMMAND = 'COMMAND',
  CONTENT_TYPE = 'CONTENT_TYPE',
  COOKIE = 'COOKIE',
  CSRF_TAG = 'CSRF_TAG',
  DENY_URL = 'DENY_URL',
  FIELD_CONSISTENCY = 'FIELD_CONSISTENCY',
  FIELD_FORMAT = 'FIELD_FORMAT',
  INVALID_RFC = 'INVALID_RFC',
  JSON_COMMAND = 'JSON_COMMAND',
  JSON_DENIAL_OF_SERVICE = 'JSON_DENIAL_OF_SERVICE',
  JSON_SQL = 'JSON_SQL',
  JSON_XSS = 'JSON_XSS',
  MALFORMED_REQUEST_ERROR = 'MALFORMED_REQUEST_ERROR',
  POST_BODY_LIMIT = 'POST_BODY_LIMIT',
  REQUEST_BY_ASN = 'REQUEST_BY_ASN',
  REQUEST_BY_COUNTRY = 'REQUEST_BY_COUNTRY',
  REQUEST_BY_METHOD = 'REQUEST_BY_METHOD',
  REQUEST_BY_SOURCE_IP = 'REQUEST_BY_SOURCE_IP',
  REQUEST_BY_URI = 'REQUEST_BY_URI',
  REQUEST_BY_URI_SOURCE_IP = 'REQUEST_BY_URI_SOURCE_IP',
  REQUEST_BY_USER_AGENT = 'REQUEST_BY_USER_AGENT',
  RESPONSE_BY_COUNTRY = 'RESPONSE_BY_COUNTRY',
  RESPONSE_BY_SOURCE_IP = 'RESPONSE_BY_SOURCE_IP',
  RESPONSE_BY_STATUS = 'RESPONSE_BY_STATUS',
  RESPONSE_BY_URI_STATUS = 'RESPONSE_BY_URI_STATUS',
  SIGNATURE_MATCH = 'SIGNATURE_MATCH',
  SQL = 'SQL',
  XML_ERROR_NOT_WELL_FORMED = 'XML_ERROR_NOT_WELL_FORMED',
  XML_ERROR_SOAP_FAULT = 'XML_ERROR_SOAP_FAULT',
  XML_SQL = 'XML_SQL',
  XML_WSI = 'XML_WSI',
  XML_XSS = 'XML_XSS',
  XSS = 'XSS',
}

/**
 * Allowed values for a policy level Application Security (AppSec) Threshold Configuration Dimension Input.
 */
export enum AppSecThresholdDimensionInput {
  REQUEST_BY_ASN = 'REQUEST_BY_ASN',
  REQUEST_BY_COUNTRY = 'REQUEST_BY_COUNTRY',
  REQUEST_BY_METHOD = 'REQUEST_BY_METHOD',
  REQUEST_BY_SOURCE_IP = 'REQUEST_BY_SOURCE_IP',
  REQUEST_BY_URI = 'REQUEST_BY_URI',
  REQUEST_BY_URI_SOURCE_IP = 'REQUEST_BY_URI_SOURCE_IP',
  REQUEST_BY_USER_AGENT = 'REQUEST_BY_USER_AGENT',
  RESPONSE_BY_COUNTRY = 'RESPONSE_BY_COUNTRY',
  RESPONSE_BY_SOURCE_IP = 'RESPONSE_BY_SOURCE_IP',
  RESPONSE_BY_STATUS = 'RESPONSE_BY_STATUS',
  RESPONSE_BY_URI_STATUS = 'RESPONSE_BY_URI_STATUS',
}

/**
 * Allowed list of values indicating what type of action caused an audit log transaction to be written.
 */
export enum AuditLogAction {
  CHANGE_STATE = 'CHANGE_STATE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
}

/**
 * Allowed list of values indicating the result of the action that caused an audit log transaction to be written.
 */
export enum AuditLogResultStatus {
  ERROR = 'ERROR',
  INFO = 'INFO',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
}

/**
 * How to sort audit log transactions.
 */
export enum AuditLogTransactionDimension {
  TIMESTAMP = 'TIMESTAMP',
}

export enum BGPCleanTrafficDeliveryMechanism {
  DIRECT_CONNECT = 'DIRECT_CONNECT',
  LOAD_BALANCED = 'LOAD_BALANCED',
  REDUNDANT = 'REDUNDANT',
  SINGLE_GRE = 'SINGLE_GRE',
}

export enum BGPMitigationTriggerMechanism {
  ROUTE_TRIGGERED_MITIGATION = 'ROUTE_TRIGGERED_MITIGATION',
  ROUTE_TRIGGERED_SUPPRESSION = 'ROUTE_TRIGGERED_SUPPRESSION',
  STANDARD = 'STANDARD',
}

export enum BGPServiceType {
  ANYCAST_GRE = 'ANYCAST_GRE',
  BGP_IP = 'BGP_IP',
  BGP_TE = 'BGP_TE',
  DIRECT_CONNECT = 'DIRECT_CONNECT',
  DIRECT_CONNECT_TE = 'DIRECT_CONNECT_TE',
}

/**
 * Specifies the type of BGP traffic routing configured for the company.
 */
export enum BGPTrafficRouting {
  BGP_IP = 'BGP_IP',
  BGP_TE = 'BGP_TE',
  DIRECT_CONNECT = 'DIRECT_CONNECT',
  DIRECT_CONNECT_TE = 'DIRECT_CONNECT_TE',
  GRE = 'GRE',
}

/**
 * Allowed values for sorting the Signature list.
 */
export enum BaseWAFSignatureDimension {
  CATEGORY = 'CATEGORY',
  CREATED_AT = 'CREATED_AT',
  DESCRIPTION = 'DESCRIPTION',
}

/**
 * A list of values for sorting the DDoS blocked IP log.
 */
export enum BlockedIPLogDimension {
  ASN = 'ASN',
  BLACKLISTED = 'BLACKLISTED',
  BLOCKED_IP = 'BLOCKED_IP',
  CITY = 'CITY',
  COUNTERMEASURE = 'COUNTERMEASURE',
  COUNTRY = 'COUNTRY',
  CUSTOMER = 'CUSTOMER',
  DEST_PORT = 'DEST_PORT',
  DEVICE_NAME = 'DEVICE_NAME',
  EVENT_TIME_STAMP = 'EVENT_TIME_STAMP',
  FIRST_EVENT_TIME_STAMP = 'FIRST_EVENT_TIME_STAMP',
  INTERNAL_MITIGATION_NAME = 'INTERNAL_MITIGATION_NAME',
  NODE_NAME = 'NODE_NAME',
  PROTOCOL = 'PROTOCOL',
  REASON = 'REASON',
  RULE = 'RULE',
  SRC_PORT = 'SRC_PORT',
}

/**
 * Allowed list of black list actions.
 */
export enum BotBlackListAction {
  DROP = 'DROP',
  REDIRECT = 'REDIRECT',
  RESET = 'RESET',
}

/**
 * Allowed list of black list types.
 */
export enum BotBlackListType {
  EXPRESSION = 'EXPRESSION',
  IPV4 = 'IPV4',
  SUBNET = 'SUBNET',
}

/**
 * Allowed list of black and white list expression fields.
 */
export enum BotBlackWhiteListExpressionField {
  COOKIE = 'COOKIE',
  HEADER = 'HEADER',
  HOSTNAME = 'HOSTNAME',
  URL = 'URL',
}

/**
 * Allowed list of black and white list expression operands.
 */
export enum BotBlackWhiteListExpressionOperand {
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  DOES_NOT_EQUAL = 'DOES_NOT_EQUAL',
  ENDS_WITH = 'ENDS_WITH',
  EQUALS = 'EQUALS',
  STARTS_WITH = 'STARTS_WITH',
}

/**
 * Allowed list of CAPTCHA actions.
 */
export enum BotCAPTCHAAction {
  DROP = 'DROP',
  REDIRECT = 'REDIRECT',
  RESET = 'RESET',
}

/**
 * Allowed list of device fingerprint actions.
 */
export enum BotDeviceFingerprintAction {
  DROP = 'DROP',
  MITIGATION = 'MITIGATION',
  REDIRECT = 'REDIRECT',
  RESET = 'RESET',
}

/**
 * Allowed list of IP reputation actions.
 */
export enum BotIPReputationAction {
  DROP = 'DROP',
  MITIGATION = 'MITIGATION',
  REDIRECT = 'REDIRECT',
  RESET = 'RESET',
}

/**
 * Allowed list of IP reputation types.
 */
export enum BotIPReputationType {
  BOTNETS = 'BOTNETS',
  DOS = 'DOS',
  IP = 'IP',
  MOBILE_THREATS = 'MOBILE_THREATS',
  PHISHING = 'PHISHING',
  PROXY = 'PROXY',
  REPUTATION = 'REPUTATION',
  SCANNERS = 'SCANNERS',
  SPAM_SOURCES = 'SPAM_SOURCES',
}

/**
 * Allowed list of rate limit actions.
 */
export enum BotRateLimitAction {
  DROP = 'DROP',
  REDIRECT = 'REDIRECT',
  RESET = 'RESET',
}

/**
 * Allowed list of rate limit types.
 */
export enum BotRateLimitType {
  SESSION = 'SESSION',
  SOURCE_IP = 'SOURCE_IP',
  URL = 'URL',
}

/**
 * Allowed list of countermeasure responses.
 */
export enum BotResponse {
  ACTION_AND_LOG = 'ACTION_AND_LOG',
  LOG = 'LOG',
  NONE = 'NONE',
}

/**
 * Allowed list of bot signature actions.
 */
export enum BotSignatureAction {
  DROP_AND_LOG = 'DROP_AND_LOG',
  LOG = 'LOG',
  NONE = 'NONE',
  REDIRECT_AND_LOG = 'REDIRECT_AND_LOG',
  RESET_AND_LOG = 'RESET_AND_LOG',
}

/**
 * Allowed values for sorting the bot signature list.
 */
export enum BotSignatureDimension {
  CATEGORY = 'CATEGORY',
  NAME = 'NAME',
}

/**
 * Allowed list of TPS actions.
 */
export enum BotTPSAction {
  DROP = 'DROP',
  MITIGATION = 'MITIGATION',
  REDIRECT = 'REDIRECT',
  RESET = 'RESET',
}

/**
 * Allowed list of TPS types.
 */
export enum BotTPSType {
  GEOLOCATION = 'GEOLOCATION',
  HOST = 'HOST',
  REQUEST_URL = 'REQUEST_URL',
  SOURCE_IP = 'SOURCE_IP',
}

/**
 * Allowed list of bot trap actions.
 */
export enum BotTrapAction {
  DROP = 'DROP',
  REDIRECT = 'REDIRECT',
  RESET = 'RESET',
}

/**
 * Allowed list of bot types.
 */
export enum BotType {
  BAD_BOT = 'BAD_BOT',
  GOOD_BOT = 'GOOD_BOT',
}

/**
 * Allowed list of Bot violation log sort fields.
 */
export enum BotViolationLogDimension {
  DESTINATION_IP = 'DESTINATION_IP',
  DOMAIN = 'DOMAIN',
  NODE_IATA_CODE = 'NODE_IATA_CODE',
  SIGNATURE_NAME = 'SIGNATURE_NAME',
  SOURCE_COUNTRY = 'SOURCE_COUNTRY',
  SOURCE_IP = 'SOURCE_IP',
  TIMESTAMP = 'TIMESTAMP',
  URI = 'URI',
}

/**
 * Allowed list of Bot violation log group by fields.
 */
export enum BotViolationLogGroupByField {
  DESTINATION_IP = 'DESTINATION_IP',
  DOMAIN = 'DOMAIN',
  PROFILE = 'PROFILE',
  SIGNATURE_NAME = 'SIGNATURE_NAME',
  SOURCE_COUNTRY = 'SOURCE_COUNTRY',
  SOURCE_IP = 'SOURCE_IP',
  URI = 'URI',
}

/**
 * Allowed list of Bot violation log types.
 */
export enum BotViolationLogType {
  ALL = 'ALL',
  INFO = 'INFO',
  VIOLATION = 'VIOLATION',
}

/**
 * Allowed list of white list responses.
 */
export enum BotWhiteListResponse {
  LOG = 'LOG',
  NONE = 'NONE',
}

/**
 * Allowed list of white list types.
 */
export enum BotWhiteListType {
  EXPRESSION = 'EXPRESSION',
  IPV4 = 'IPV4',
  SUBNET = 'SUBNET',
}

/**
 * Allowed list of values indicating what type of caller is recording an audit log transaction.
 */
export enum CallerType {
  APPLICATION = 'APPLICATION',
  SERVICE = 'SERVICE',
}

/**
 * Allowed list of TLS cipher user selection mode.
 */
export enum CipherSelectionMode {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
}

/**
 * Allowed list of command injection types.
 */
export enum CommandInjectionType {
  COMMAND_KEYWORD = 'COMMAND_KEYWORD',
  COMMAND_SPECIAL_CHARACTER = 'COMMAND_SPECIAL_CHARACTER',
  COMMAND_SPECIAL_CHARACTER_AND_KEYWORD = 'COMMAND_SPECIAL_CHARACTER_AND_KEYWORD',
  COMMAND_SPECIAL_CHARACTER_OR_KEYWORD = 'COMMAND_SPECIAL_CHARACTER_OR_KEYWORD',
}

/**
 * Allowed list of values for command injection value types.
 */
export enum CommandInjectionValueType {
  KEYWORD = 'KEYWORD',
  SPECIAL_STRING = 'SPECIAL_STRING',
}

/**
 * Allowed list of types of comment which can be exmpted.
 */
export enum CommentExemption {
  ANSI = 'ANSI',
  ANSI_NESTED = 'ANSI_NESTED',
  CHECK_ALL = 'CHECK_ALL',
  NESTED = 'NESTED',
}

/**
 * List of possible ConfigurationChange statuses.
 */
export enum ConfigurationChangeStatus {
  COMPLETED_FAILURE = 'COMPLETED_FAILURE',
  COMPLETED_SUCCESS = 'COMPLETED_SUCCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  UNKNOWN = 'UNKNOWN',
}

/**
 * ISO 3166-1 alpha-2 country codes
 */
export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  FX = 'FX',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TP = 'TP',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  XK = 'XK',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW',
}

/**
 * Detected DNA attack.
 */
export enum DNAAlertSubType {
  AUTO_CLASSIFY_RESTART = 'AUTO_CLASSIFY_RESTART',
  DOS_HOST_DETECTION = 'DOS_HOST_DETECTION',
  MO_PROFILED = 'MO_PROFILED',
  NETWORK = 'NETWORK',
  ROUTER_PROFILED = 'ROUTER_PROFILED',
  TRAFFIC_AUTO_MITIGATION = 'TRAFFIC_AUTO_MITIGATION',
}

/**
 * Allowed list of values for the Deny URL Regex Rule Type.
 */
export enum DenyURLType {
  ACCESS_ATTACKS = 'ACCESS_ATTACKS',
  ALL = 'ALL',
  APACHE_POSSIBLE_DIRECTORY_INDEX_DISCLOSURE_VULNERABILITY = 'APACHE_POSSIBLE_DIRECTORY_INDEX_DISCLOSURE_VULNERABILITY',
  CODE_RED = 'CODE_RED',
  COMMAND_INJECTION_ATTACK = 'COMMAND_INJECTION_ATTACK',
  CUSTOM = 'CUSTOM',
  DEBUG_ATTACKS = 'DEBUG_ATTACKS',
  FRONT_PAGE_SERVER_EXTENSIONS_BUFFER_OVERFLOW_1 = 'FRONT_PAGE_SERVER_EXTENSIONS_BUFFER_OVERFLOW_1',
  FRONT_PAGE_SERVER_EXTENSIONS_BUFFER_OVERFLOW_2 = 'FRONT_PAGE_SERVER_EXTENSIONS_BUFFER_OVERFLOW_2',
  FRONT_PAGE_SERVER_EXTENSIONS_PATH_DISCLOSURE_VULNERABILITY = 'FRONT_PAGE_SERVER_EXTENSIONS_PATH_DISCLOSURE_VULNERABILITY',
  HTR_SOURCE_DISCLOSURE = 'HTR_SOURCE_DISCLOSURE',
  IIS_EXECUTABLE_FILE_PARSING_VULNERABILITY_1 = 'IIS_EXECUTABLE_FILE_PARSING_VULNERABILITY_1',
  IIS_EXECUTABLE_FILE_PARSING_VULNERABILITY_2 = 'IIS_EXECUTABLE_FILE_PARSING_VULNERABILITY_2',
  IIS_EXECUTABLE_FILE_PARSING_VULNERABILITY_3 = 'IIS_EXECUTABLE_FILE_PARSING_VULNERABILITY_3',
  INDEX_SERVER_BUFFER_OVERFLOW = 'INDEX_SERVER_BUFFER_OVERFLOW',
  MICROSOFT_IIS_UNC_MAPPED_VIRTUAL_HOST_VULNERABILITY = 'MICROSOFT_IIS_UNC_MAPPED_VIRTUAL_HOST_VULNERABILITY',
  MICROSOFT_IIS_UNC_PATH_DISCLOSURE_VULNERABILITY = 'MICROSOFT_IIS_UNC_PATH_DISCLOSURE_VULNERABILITY',
  NETSCAPE_ENTERPRISE_SERVER_DIRECTORY_INDEXING_VULNERABILITY = 'NETSCAPE_ENTERPRISE_SERVER_DIRECTORY_INDEXING_VULNERABILITY',
  NETSCAPE_ENTERPRISE_SERVER_WEB_PUBLISHING_VULNERABILITY = 'NETSCAPE_ENTERPRISE_SERVER_WEB_PUBLISHING_VULNERABILITY',
  NIMBDA_3 = 'NIMBDA_3',
  NIMBDA_4 = 'NIMBDA_4',
  PASSWORD_FILE_ATTACKS = 'PASSWORD_FILE_ATTACKS',
  PRINTER_BUFFER_OVERFLOW = 'PRINTER_BUFFER_OVERFLOW',
  SCRIPT_EXPLOIT = 'SCRIPT_EXPLOIT',
  SYSTEM_COMMAND_ATTACKS = 'SYSTEM_COMMAND_ATTACKS',
  UNIX_CORE_FILE_ATTACKS = 'UNIX_CORE_FILE_ATTACKS',
  UNIX_FILE_ATTACKS = 'UNIX_FILE_ATTACKS',
  WEB_HITS_SOURCE_DISCLOSURE = 'WEB_HITS_SOURCE_DISCLOSURE',
  WSDL_SCANNING_ATTACK_DOT_WSDL = 'WSDL_SCANNING_ATTACK_DOT_WSDL',
  WSDL_SCANNING_ATTACK_QUERY_PARAM_WSDL = 'WSDL_SCANNING_ATTACK_QUERY_PARAM_WSDL',
  WSDL_SCANNING_ATTACK_SLASH_WSDL = 'WSDL_SCANNING_ATTACK_SLASH_WSDL',
}

/**
 * One of the sorted order fields.
 */
export enum EventDimension {
  END = 'END',
  START = 'START',
}

/**
 * Periodicity of report generation.
 */
export enum ExecutiveReportPeriod {
  CALENDAR_MONTHLY = 'CALENDAR_MONTHLY',
  PRIOR_BIWEEKLY = 'PRIOR_BIWEEKLY',
  PRIOR_MONTHLY = 'PRIOR_MONTHLY',
  PRIOR_QUARTERLY = 'PRIOR_QUARTERLY',
  PRIOR_WEEKLY = 'PRIOR_WEEKLY',
}

/**
 * Periodicity of report generation supplied as input.
 */
export enum ExecutiveReportPeriodInput {
  CALENDAR_MONTHLY = 'CALENDAR_MONTHLY',
  PRIOR_BIWEEKLY = 'PRIOR_BIWEEKLY',
  PRIOR_MONTHLY = 'PRIOR_MONTHLY',
  PRIOR_QUARTERLY = 'PRIOR_QUARTERLY',
  PRIOR_WEEKLY = 'PRIOR_WEEKLY',
}

/**
 * Status of report generation.
 */
export enum ExecutiveReportStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  NOTIFICATION_FAILED = 'NOTIFICATION_FAILED',
  REPORT_FAILED = 'REPORT_FAILED',
  SUCCESS = 'SUCCESS',
}

/**
 * Type of report.
 */
export enum ExecutiveReportType {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

/**
 * A feature purchased by a customer.
 */
export enum FeatureType {
  ADDITIONAL_ROUTER = 'ADDITIONAL_ROUTER',
  ADDITIONAL_ROUTER_DNA = 'ADDITIONAL_ROUTER_DNA',
  ASSURANCE_1 = 'ASSURANCE_1',
  ASSURANCE_3 = 'ASSURANCE_3',
  BGP_ALWAYS_ON = 'BGP_ALWAYS_ON',
  BGP_ON_DEMAND = 'BGP_ON_DEMAND',
  DDOS_AND_APPLICATION_SECURITY = 'DDOS_AND_APPLICATION_SECURITY',
  HYBRID_APPLIANCE_ONLY = 'HYBRID_APPLIANCE_ONLY',
  PROXY = 'PROXY',
  UNLIMITED_MITIGATIONS = 'UNLIMITED_MITIGATIONS',
}

/**
 * Allowed list of field format types.
 */
export enum FieldFormatType {
  ALPHA = 'ALPHA',
  ALPHANUMERIC = 'ALPHANUMERIC',
  ANY = 'ANY',
  INTEGER = 'INTEGER',
  NO_HTML = 'NO_HTML',
}

/**
 * One of the sorted order fields.
 */
export enum FilterListDimension {
  DESCRIPTION = 'DESCRIPTION',
  LIST_TYPE = 'LIST_TYPE',
  NAME = 'NAME',
  USED_IN_AUTOMITIGATION = 'USED_IN_AUTOMITIGATION',
  USED_IN_TEMPLATE = 'USED_IN_TEMPLATE',
}

/**
 * Types of Filter Lists.
 */
export enum FilterListType {
  black_white = 'black_white',
  inline = 'inline',
  ip_address = 'ip_address',
  ip_location = 'ip_location',
  ipv6_address = 'ipv6_address',
  ipv6_black_white = 'ipv6_black_white',
}

/**
 * Identifies the Filter List as Inline or User defined.
 */
export enum FilterType {
  INLINE = 'INLINE',
  USER = 'USER',
}

/**
 * Allowed list of values for an HTML location.
 */
export enum HTMLLocation {
  COOKIE = 'COOKIE',
  FORM_FIELD = 'FORM_FIELD',
  HEADER = 'HEADER',
}

/**
 * Allowed list of values for an HTML location in case of HTML XSS.
 */
export enum HTMLXSSLocation {
  COOKIE = 'COOKIE',
  FORM_FIELD = 'FORM_FIELD',
  HEADER = 'HEADER',
  URL = 'URL',
}

/**
 * Allowed list of values for HTTP RFC Profile action.
 */
export enum HTTPRFCProfileAction {
  BLOCK = 'BLOCK',
  BYPASS = 'BYPASS',
}

/**
 * IP version
 */
export enum IPVersion {
  IPV4 = 'IPV4',
  IPV6 = 'IPV6',
}

/**
 * The type of traffic association criteria for an element.
 */
export enum ManagedObjectElementType {
  ADVANCED = 'ADVANCED',
  APP_ID = 'APP_ID',
  AS_REGEX = 'AS_REGEX',
  CIDR_BLOCKS = 'CIDR_BLOCKS',
  CIDR_GROUPS = 'CIDR_GROUPS',
  CIDR_V6_BLOCKS = 'CIDR_V6_BLOCKS',
  COMMUNITY = 'COMMUNITY',
  DDOS_DEVICE_PORTS = 'DDOS_DEVICE_PORTS',
  EXTENDED_COMMUNITY = 'EXTENDED_COMMUNITY',
  INTERFACE = 'INTERFACE',
  PEER_AS = 'PEER_AS',
  PROFILED_INTERFACE_GROUP = 'PROFILED_INTERFACE_GROUP',
  SUB_AS = 'SUB_AS',
}

/**
 * Types of Managed Objects.
 */
export enum ManagedObjectType {
  BGP = 'BGP',
  DNA = 'DNA',
  PROXY = 'PROXY',
}

/**
 * List of supported values for Minimum TLS versions.
 */
export enum MinTLSVersion {
  V_1_0 = 'V_1_0',
  V_1_1 = 'V_1_1',
  V_1_1_PFS = 'V_1_1_PFS',
  V_1_2 = 'V_1_2',
  V_1_3 = 'V_1_3',
}

/**
 * Misuse type setting.
 */
export enum MisuseTypeSetting {
  CHARGEN_AMP = 'CHARGEN_AMP',
  CLDAP_AMP = 'CLDAP_AMP',
  DNS = 'DNS',
  DNS_AMP = 'DNS_AMP',
  ICMP = 'ICMP',
  IPFRAG = 'IPFRAG',
  IPNULL = 'IPNULL',
  IPPRIV = 'IPPRIV',
  L2TP_AMP = 'L2TP_AMP',
  MDNS_AMP = 'MDNS_AMP',
  MEMCACHED_AMP = 'MEMCACHED_AMP',
  MSSQL_AMP = 'MSSQL_AMP',
  NETBIOS_AMP = 'NETBIOS_AMP',
  NTP_AMP = 'NTP_AMP',
  RIPV1_AMP = 'RIPV1_AMP',
  RPCBIND_AMP = 'RPCBIND_AMP',
  SNMP_AMP = 'SNMP_AMP',
  SSDP_AMP = 'SSDP_AMP',
  TCPACK = 'TCPACK',
  TCPNULL = 'TCPNULL',
  TCPRST = 'TCPRST',
  TCPSYN = 'TCPSYN',
  TCPSYNACK = 'TCPSYNACK',
  TOTAL = 'TOTAL',
  UDP = 'UDP',
}

/**
 *  One of the sorted order fields.
 */
export enum MitigationDimension {
  END = 'END',
  START = 'START',
}

/**
 *  Allowed mitigation type values.  These types are also sometimes called providers
 */
export enum MitigationType {
  BOT = 'BOT',
  DDOS = 'DDOS',
  WAF = 'WAF',
}

/**
 * NotificationCategory field values that can be included.
 */
export enum NotificationCategory {
  BOT_MITIGATION_END = 'BOT_MITIGATION_END',
  BOT_MITIGATION_START = 'BOT_MITIGATION_START',
  CERTIFICATE_EXPIRATION = 'CERTIFICATE_EXPIRATION',
  CLOUD_FIREWALL_APPROVED = 'CLOUD_FIREWALL_APPROVED',
  CLOUD_FIREWALL_REJECTED = 'CLOUD_FIREWALL_REJECTED',
  CLOUD_FIREWALL_SUBMITTED = 'CLOUD_FIREWALL_SUBMITTED',
  DDOS_ALERT_HIGH_END = 'DDOS_ALERT_HIGH_END',
  DDOS_ALERT_HIGH_START = 'DDOS_ALERT_HIGH_START',
  DDOS_ALERT_LOW_END = 'DDOS_ALERT_LOW_END',
  DDOS_ALERT_LOW_START = 'DDOS_ALERT_LOW_START',
  DDOS_ALERT_MEDIUM_END = 'DDOS_ALERT_MEDIUM_END',
  DDOS_ALERT_MEDIUM_START = 'DDOS_ALERT_MEDIUM_START',
  DDOS_MITIGATION_END = 'DDOS_MITIGATION_END',
  DDOS_MITIGATION_START = 'DDOS_MITIGATION_START',
  INTERFACE_STATUS = 'INTERFACE_STATUS',
  WAF_ALERT_END = 'WAF_ALERT_END',
  WAF_ALERT_START = 'WAF_ALERT_START',
  WAF_MITIGATION_END = 'WAF_MITIGATION_END',
  WAF_MITIGATION_START = 'WAF_MITIGATION_START',
  WAF_SIGNATURE = 'WAF_SIGNATURE',
}

/**
 * NotificationDimension the order that we can order notification configurations by.
 */
export enum NotificationDimension {
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
}

/**
 * Protocol Binding used.
 */
export enum Protocol {
  HTTP_POST = 'HTTP_POST',
  HTTP_REDIRECT = 'HTTP_REDIRECT',
}

/**
 * Load-balancing options.
 */
export enum ProxyLoadBalanceMethod {
  DESTINATION_IP_HASH = 'DESTINATION_IP_HASH',
  DOMAIN_HASH = 'DOMAIN_HASH',
  LEAST_BANDWIDTH = 'LEAST_BANDWIDTH',
  LEAST_CONNECTION = 'LEAST_CONNECTION',
  LEAST_PACKETS = 'LEAST_PACKETS',
  LEAST_REQUEST = 'LEAST_REQUEST',
  LEAST_RESPONSE_TIME = 'LEAST_RESPONSE_TIME',
  LRTM = 'LRTM',
  ROUND_ROBIN = 'ROUND_ROBIN',
  SOURCE_DEST_IP_HASH = 'SOURCE_DEST_IP_HASH',
  SOURCE_IP_HASH = 'SOURCE_IP_HASH',
  SOURCE_IP_SOURCE_PORT_HASH = 'SOURCE_IP_SOURCE_PORT_HASH',
  URL_HASH = 'URL_HASH',
}

/**
 * Type of session persistence to apply to requests.
 */
export enum ProxyLoadBalancePersistenceType {
  COOKIE_INSERT = 'COOKIE_INSERT',
  SOURCE_IP = 'SOURCE_IP',
}

/**
 * Allowed list of values for the front end protocol of a proxy.
 */
export enum ProxyProtocol {
  DNS = 'DNS',
  DNS_TCP = 'DNS_TCP',
  HTTP = 'HTTP',
  SSL = 'SSL',
  SSL_BRIDGE = 'SSL_BRIDGE',
  TCP = 'TCP',
  UDP = 'UDP',
}

/**
 * Possible states of a proxy provisioning.
 */
export enum ProxyProvisioningStatus {
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
}

/**
 * Defines type of proxy virtual IP offering.
 */
export enum ProxyType {
  CLOUD = 'CLOUD',
  HARDWARE = 'HARDWARE',
  NONE = 'NONE',
}

/**
 * Allowed values for the responder action.
 */
export enum ResponderAction {
  DROP = 'DROP',
  LOG = 'LOG',
  REDIRECT_TO = 'REDIRECT_TO',
  RESPOND_WITH = 'RESPOND_WITH',
}

/**
 * Allowed values for the responder match field.
 */
export enum ResponderField {
  CONTENT_TYPE = 'CONTENT_TYPE',
  COOKIE_SET_NAMES = 'COOKIE_SET_NAMES',
  DESTINATION_PORT = 'DESTINATION_PORT',
  HEADER_SECTION = 'HEADER_SECTION',
  HEADER_SECTION_SIZE = 'HEADER_SECTION_SIZE',
  HOSTNAME = 'HOSTNAME',
  QUERY_STRING = 'QUERY_STRING',
  REFERER_URL = 'REFERER_URL',
  REQUEST_SIZE = 'REQUEST_SIZE',
  SOURCE_IP = 'SOURCE_IP',
  URL_PATH = 'URL_PATH',
  X_FORWARDED_FOR = 'X_FORWARDED_FOR',
}

export enum ResponderLogDimension {
  DESTINATION_IP = 'DESTINATION_IP',
  RESPONDER_ACTION = 'RESPONDER_ACTION',
  RESPONDER_NAME = 'RESPONDER_NAME',
  SOURCE_IP = 'SOURCE_IP',
  TIMESTAMP = 'TIMESTAMP',
  URI = 'URI',
}

export enum ResponderLogGroupByField {
  DESTINATION_IP = 'DESTINATION_IP',
  RESPONDER_ACTION = 'RESPONDER_ACTION',
  RESPONDER_NAME = 'RESPONDER_NAME',
  SOURCE_IP = 'SOURCE_IP',
  URI = 'URI',
}

export enum ResponderLogType {
  NETWORK_CONTROL = 'NETWORK_CONTROL',
  RESPONDER = 'RESPONDER',
}

/**
 * Allowed values for the responder match operand.
 */
export enum ResponderOperand {
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAIN = 'DOES_NOT_CONTAIN',
  DOES_NOT_EQUAL = 'DOES_NOT_EQUAL',
  ENDS_WITH = 'ENDS_WITH',
  EQUALS = 'EQUALS',
  GREATER_THAN = 'GREATER_THAN',
  IN_SUBNET = 'IN_SUBNET',
  LESS_THAN = 'LESS_THAN',
  NOT_IN_SUBNET = 'NOT_IN_SUBNET',
  RATE_LIMIT = 'RATE_LIMIT',
  STARTS_WITH = 'STARTS_WITH',
}

/**
 * Status of SAML configuration.
 */
export enum SAMLConfigStatus {
  COMMIT_DONE = 'COMMIT_DONE',
  IDP_CONNECTION_FAILED = 'IDP_CONNECTION_FAILED',
  IDP_CONNECTION_OK = 'IDP_CONNECTION_OK',
  IN_PROGRESS = 'IN_PROGRESS',
}

/**
 * Allowed list of injection types.
 */
export enum SQLInjectionType {
  NONE = 'NONE',
  SQL_KEYWORD = 'SQL_KEYWORD',
  SQL_SPECIAL_CHARACTER = 'SQL_SPECIAL_CHARACTER',
  SQL_SPECIAL_CHARACTER_AND_KEYWORD = 'SQL_SPECIAL_CHARACTER_AND_KEYWORD',
  SQL_SPECIAL_CHARACTER_OR_KEYWORD = 'SQL_SPECIAL_CHARACTER_OR_KEYWORD',
}

/**
 * Allowed values for SessionlessFieldConsistency.
 * To use Sessionless Field Consistency on all web forms use ON.
 * To use it only for forms submitted with the HTTP POST method, select POST_ONLY.
 */
export enum SessionlessFieldConsistency {
  OFF = 'OFF',
  ON = 'ON',
  POST_ONLY = 'POST_ONLY',
}

export enum Severity {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
}

/**
 * Allowed values for a custom signature match format.
 */
export enum SignatureMatchFormat {
  LITERAL = 'LITERAL',
  PCRE = 'PCRE',
}

/**
 * Allowed values for a custom signature request area.
 */
export enum SignatureRequestArea {
  HTTP_COOKIE = 'HTTP_COOKIE',
  HTTP_FORM_FIELD = 'HTTP_FORM_FIELD',
  HTTP_HEADER = 'HTTP_HEADER',
  HTTP_METHOD = 'HTTP_METHOD',
  HTTP_ORIGIN_URL = 'HTTP_ORIGIN_URL',
  HTTP_POST_BODY = 'HTTP_POST_BODY',
  HTTP_RAW_URL = 'HTTP_RAW_URL',
  HTTP_URL = 'HTTP_URL',
}

/**
 * Allowed values for a custom signature response area.
 */
export enum SignatureResponseArea {
  HTTP_RESPONSE_BODY = 'HTTP_RESPONSE_BODY',
  HTTP_RESPONSE_HEADER = 'HTTP_RESPONSE_HEADER',
  HTTP_SET_COOKIE = 'HTTP_SET_COOKIE',
  HTTP_STATUS_CODE = 'HTTP_STATUS_CODE',
  HTTP_STATUS_MESSAGE = 'HTTP_STATUS_MESSAGE',
}

/**
 * Allowed values for a custom signature rule format.
 */
export enum SignatureRuleFormat {
  ANY = 'ANY',
  LITERAL = 'LITERAL',
  PCRE = 'PCRE',
}

/**
 *  Allowed sort direction values.
 */
export enum SortDirection {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

/**
 * State possible status of an entity.
 */
export enum Status {
  DOWN = 'DOWN',
  NA = 'NA',
  UP = 'UP',
}

/**
 * Different entities to group status by.
 */
export enum StatusDimension {
  INTERFACE_PATH = 'INTERFACE_PATH',
}

/**
 * Allowed list of TLS cipher suites.
 */
export enum TLSCipher {
  TLS1_2_ECDHE_RSA_AES128_GCM_SHA256 = 'TLS1_2_ECDHE_RSA_AES128_GCM_SHA256',
  TLS1_2_ECDHE_RSA_AES256_GCM_SHA384 = 'TLS1_2_ECDHE_RSA_AES256_GCM_SHA384',
  TLS1_2_ECDHE_RSA_AES_128_SHA256 = 'TLS1_2_ECDHE_RSA_AES_128_SHA256',
  TLS1_2_ECDHE_RSA_AES_256_SHA384 = 'TLS1_2_ECDHE_RSA_AES_256_SHA384',
  TLS1_3_AES128_GCM_SHA256 = 'TLS1_3_AES128_GCM_SHA256',
  TLS1_3_AES256_GCM_SHA384 = 'TLS1_3_AES256_GCM_SHA384',
  TLS1_3_CHACHA20_POLY1305_SHA256 = 'TLS1_3_CHACHA20_POLY1305_SHA256',
  TLS1_AES_128_CBC_SHA = 'TLS1_AES_128_CBC_SHA',
  TLS1_AES_256_CBC_SHA = 'TLS1_AES_256_CBC_SHA',
  TLS1_ECDHE_RSA_AES128_SHA = 'TLS1_ECDHE_RSA_AES128_SHA',
  TLS1_ECDHE_RSA_AES256_SHA = 'TLS1_ECDHE_RSA_AES256_SHA',
}

/**
 * Allowed values for time unit.
 */
export enum TimeUnit {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MINUTE = 'MINUTE',
}

/**
 * Field values that can be included in `TrafficData`.
 */
export enum TrafficField {
  IN_BITS = 'IN_BITS',
  IN_BITS_PER_SECOND = 'IN_BITS_PER_SECOND',
  IN_PACKETS = 'IN_PACKETS',
  IN_PACKETS_PER_SECOND = 'IN_PACKETS_PER_SECOND',
  MITIGATED_BITS = 'MITIGATED_BITS',
  MITIGATED_BITS_PER_SECOND = 'MITIGATED_BITS_PER_SECOND',
  MITIGATED_PACKETS = 'MITIGATED_PACKETS',
  MITIGATED_PACKETS_PER_SECOND = 'MITIGATED_PACKETS_PER_SECOND',
  OUT_BITS = 'OUT_BITS',
  OUT_BITS_PER_SECOND = 'OUT_BITS_PER_SECOND',
  OUT_PACKETS = 'OUT_PACKETS',
  OUT_PACKETS_PER_SECOND = 'OUT_PACKETS_PER_SECOND',
}

/**
 * Metrics that can be reported for fields.
 */
export enum TrafficMetric {
  AVERAGE = 'AVERAGE',
  MAX = 'MAX',
  MIN = 'MIN',
  PERCENTILE_50 = 'PERCENTILE_50',
  PERCENTILE_95 = 'PERCENTILE_95',
  SUM = 'SUM',
}

export enum TrafficRateUnit {
  BPS = 'BPS',
  PPS = 'PPS',
}

/**
 * Tunnel type.
 */
export enum TunnelType {
  DIRECT_CONNECT = 'DIRECT_CONNECT',
  DIRECT_CONNECT_TE = 'DIRECT_CONNECT_TE',
  GRE = 'GRE',
  GRE_TE = 'GRE_TE',
}

/**
 * Allowed list of user roles.
 */
export enum UserRole {
  PRIMARY_ADMIN = 'PRIMARY_ADMIN',
  READ_ONLY = 'READ_ONLY',
  TECHNICAL_USER = 'TECHNICAL_USER',
}

/**
 * VServer state value.
 */
export enum VServerState {
  DOWN = 'DOWN',
  UNAVAILABLE = 'UNAVAILABLE',
  UP = 'UP',
}

/**
 * Allowed list of values for value types.
 */
export enum ValueType {
  KEYWORD = 'KEYWORD',
  SPECIAL_STRING = 'SPECIAL_STRING',
  WILDCHAR = 'WILDCHAR',
}

/**
 * Violation log sort dimensions.
 */
export enum ViolationLogDimension {
  DESTINATION_IP = 'DESTINATION_IP',
  DOMAIN = 'DOMAIN',
  SIGNATURE_NAME = 'SIGNATURE_NAME',
  SITE = 'SITE',
  SOURCE_COUNTRY = 'SOURCE_COUNTRY',
  SOURCE_IP = 'SOURCE_IP',
  TIMESTAMP = 'TIMESTAMP',
  URI = 'URI',
}

/**
 * Allowed list of violation log group by fields.
 */
export enum ViolationLogGroupByField {
  DESTINATION_IP = 'DESTINATION_IP',
  DOMAIN = 'DOMAIN',
  PROFILE = 'PROFILE',
  SIGNATURE_NAME = 'SIGNATURE_NAME',
  SOURCE_CONTINENT = 'SOURCE_CONTINENT',
  SOURCE_COUNTRY = 'SOURCE_COUNTRY',
  SOURCE_IP = 'SOURCE_IP',
  URI = 'URI',
}

/**
 * Allowed list of actions for a given WAF countermeasure.
 */
export enum WAFAction {
  BLOCK_AND_LOG = 'BLOCK_AND_LOG',
  LOG = 'LOG',
  NONE = 'NONE',
}

/**
 * Allowed list of WSI standard rule IDs.
 */
export enum WSIStandardRuleID {
  ALL_SOAPBIND_FAULTS_DESCRIBED = 'ALL_SOAPBIND_FAULTS_DESCRIBED',
  ALL_SOAPBIND_HEADERS_INCLUDED = 'ALL_SOAPBIND_HEADERS_INCLUDED',
  BODY_CHILDREN_NAMESPACED = 'BODY_CHILDREN_NAMESPACED',
  FAULTCODE_VALID_CONTENT = 'FAULTCODE_VALID_CONTENT',
  FAULT_IF_INVALID_ENVELOPE = 'FAULT_IF_INVALID_ENVELOPE',
  HTTP_REQUEST_INCLUDES_VALID_SOAPACTION_HEADER = 'HTTP_REQUEST_INCLUDES_VALID_SOAPACTION_HEADER',
  MESSAGE_SERIALIZATION = 'MESSAGE_SERIALIZATION',
  MUST_ACCEPT_FAULT_MESSAGES = 'MUST_ACCEPT_FAULT_MESSAGES',
  MUST_UNDERSTAND_ATTR_VALID = 'MUST_UNDERSTAND_ATTR_VALID',
  MUST_USE_HTTP_1_1_OR_1_0 = 'MUST_USE_HTTP_1_1_OR_1_0',
  MUST_USE_HTTP_POST = 'MUST_USE_HTTP_POST',
  NO_ARRAYTYPE_ENCODING_ATTR = 'NO_ARRAYTYPE_ENCODING_ATTR',
  NO_DOT_NOTATION_FAULTCODE = 'NO_DOT_NOTATION_FAULTCODE',
  NO_ENVELOPE_FOLLOWING_BODY = 'NO_ENVELOPE_FOLLOWING_BODY',
  ONE_WAY_RESPONSE_MUST_BE_EMPTY = 'ONE_WAY_RESPONSE_MUST_BE_EMPTY',
  RPC_LITERAL_NO_ENCODING_STYLE_ATTRS_ON_BODY_GRANDCHILD = 'RPC_LITERAL_NO_ENCODING_STYLE_ATTRS_ON_BODY_GRANDCHILD',
  RPC_LITERAL_NO_INVALID_NIL_ATTR = 'RPC_LITERAL_NO_INVALID_NIL_ATTR',
  RPC_LITERAL_PART_ACCESSORS_NO_NAMESPACE = 'RPC_LITERAL_PART_ACCESSORS_NO_NAMESPACE',
  RPC_LITERAL_VALID_RESPONSE = 'RPC_LITERAL_VALID_RESPONSE',
  SHOULD_USE_HTTP_1_1 = 'SHOULD_USE_HTTP_1_1',
  SOAP_ACTION_HTTP_HEADER_VALID = 'SOAP_ACTION_HTTP_HEADER_VALID',
  SOAP_ENCODING_STYLE_ATTRS_NOT_ON_BODY_CHILD = 'SOAP_ENCODING_STYLE_ATTRS_NOT_ON_BODY_CHILD',
  SOAP_ENCODING_STYLE_VALID_ATTRS = 'SOAP_ENCODING_STYLE_VALID_ATTRS',
  SOAP_FAULT_CHILDREN_NOT_UNQUALIFIED = 'SOAP_FAULT_CHILDREN_NOT_UNQUALIFIED',
  SOAP_FAULT_VALID_CHILDREN = 'SOAP_FAULT_VALID_CHILDREN',
  VALID_ENVELOPE_HEADER_BODY_ATTRS = 'VALID_ENVELOPE_HEADER_BODY_ATTRS',
  VALID_ENVELOPE_NAMESPACE = 'VALID_ENVELOPE_NAMESPACE',
  VALID_HTTP_RESPONSE_IF_FAULT = 'VALID_HTTP_RESPONSE_IF_FAULT',
  VALID_HTTP_RESPONSE_IF_NO_FAULT = 'VALID_HTTP_RESPONSE_IF_NO_FAULT',
}

/**
 * Allowed list of values for an XML location.
 */
export enum XMLLocation {
  ATTRIBUTE = 'ATTRIBUTE',
  ELEMENT = 'ELEMENT',
}

/**
 * Allowed list of actions for XML SOAP Fault countermeasure.
 */
export enum XMLSOAPFaultAction {
  BLOCK_AND_LOG = 'BLOCK_AND_LOG',
  LOG = 'LOG',
  NONE = 'NONE',
  REMOVE = 'REMOVE',
}

/**
 * Allowed list of values for value types in XSS.
 */
export enum XSSValueType {
  ATTRIBUTE = 'ATTRIBUTE',
  PATTERN = 'PATTERN',
  TAG = 'TAG',
}

/**
 * The filters that can be applied to scope the list of alerts.
 */
export interface AlertFilterInput {
  active?: boolean | null;
  id?: string | null;
  isInfrastructure?: boolean | null;
  providerID?: string | null;
  severities?: Severity[] | null;
  types?: AlertType[] | null;
}

/**
 * AlertSortBy sorting.
 */
export interface AlertSortBy {
  dimension: AlertDimension;
  direction: SortDirection;
}

/**
 * Define how the results should be grouped.
 */
export interface AppDataAggregateByInput {
  field: AppDataAggregateByField;
}

/**
 * Input required if extra criteria is needed to constrain the queried results.
 */
export interface AppDataFilterInput {
  vips?: IPAddressInput[] | null;
}

/**
 * Specify a policy-level Application Security (AppSec) Threshold.
 */
export interface AppSecThresholdInput {
  bucketDurationSeconds: any;
  count: any;
  dimension: AppSecThresholdDimensionInput;
  key: string;
}

/**
 * Define a virtual server's back-end server.
 */
export interface ApplicationServiceInput {
  monitor: boolean;
  origin: string;
  port: any;
  protocol: ProxyProtocol;
}

/**
 * Input required if extra criteria is needed to constrain the queried results.
 */
export interface AuditLogTransactionFilterInput {
  action?: AuditLogAction | null;
  apiClientID?: string | null;
  applicationID?: string | null;
  resourceID?: string | null;
  resourceType?: string | null;
  transactionResultStatus?: AuditLogResultStatus | null;
  userID?: string | null;
}

/**
 * Audit Log Transactions log sorting.
 */
export interface AuditLogTransactionSortBy {
  dimension: AuditLogTransactionDimension;
  direction: SortDirection;
}

/**
 * Signature sorting input.
 */
export interface BaseWAFSignatureSortBy {
  dimension: BaseWAFSignatureDimension;
  direction: SortDirection;
}

/**
 * Filters queries for DDoS blocked IP logs.
 */
export interface BlockedIPLogFilterInput {
  asn?: any | null;
  blockedIPSubstring?: string | null;
  countermeasureSubstring?: string | null;
  countrySubstring?: string | null;
  customerSubstring?: string | null;
  eventTime: BlockedIPLogTimeInput;
  firstEventTime?: BlockedIPLogTimeInput | null;
}

/**
 * DDoS blocked IP log sorting.
 */
export interface BlockedIPLogSortBy {
  dimension: BlockedIPLogDimension;
  direction: SortDirection;
}

/**
 * Timestamp filter.
 */
export interface BlockedIPLogTimeInput {
  from: any;
  to?: any | null;
}

/**
 * A black list binding.
 */
export interface BotBlackListBindingInput {
  action?: BotBlackListAction | null;
  active: boolean;
  expressionMatch?: BotBlackWhiteListExpressionMatchInput | null;
  response: BotResponse;
  type: BotBlackListType;
  value?: string | null;
}

/**
 * A black and white list expression match.
 */
export interface BotBlackWhiteListExpressionMatchInput {
  field: BotBlackWhiteListExpressionField;
  fieldValue?: string | null;
  operand: BotBlackWhiteListExpressionOperand;
  operandValue: string;
}

/**
 * A CAPTCHA binding.
 */
export interface BotCAPTCHABindingInput {
  action?: BotCAPTCHAAction | null;
  active: boolean;
  gracePeriod: any;
  mutePeriod: any;
  requestLengthLimit: any;
  response: BotResponse;
  retryAttempts: any;
  urlPath: string;
  waitTime: any;
}

/**
 * An IP reputation binding.
 */
export interface BotIPReputationBindingInput {
  action?: BotIPReputationAction | null;
  active: boolean;
  response: BotResponse;
  type: BotIPReputationType;
}

/**
 * A rate limit binding.
 */
export interface BotRateLimitBindingInput {
  action?: BotRateLimitAction | null;
  active: boolean;
  cookieName?: string | null;
  period: any;
  rate: any;
  response: BotResponse;
  type: BotRateLimitType;
  urlPath?: string | null;
}

/**
 * Filter a list of bot signatures.
 */
export interface BotSignatureFilterInput {
  category?: string | null;
  name?: string | null;
  search?: string | null;
}

/**
 * Sort options for the bot signature list.
 */
export interface BotSignatureSortBy {
  dimension: BotSignatureDimension;
  direction: SortDirection;
}

/**
 * A TPS binding.
 */
export interface BotTPSBindingInput {
  action?: BotTPSAction | null;
  fixedThreshold?: any | null;
  percentageThreshold?: any | null;
  response: BotResponse;
  type: BotTPSType;
}

/**
 * A bot trap binding.
 */
export interface BotTrapBindingInput {
  active: boolean;
  urlPath: string;
}

/**
 * A Bot violation log filter input.
 */
export interface BotViolationLogFilterInput {
  action?: string | null;
  all?: string | null;
  destinationIP?: IPAddressInput | null;
  domain?: string | null;
  host?: string | null;
  httptxID?: string | null;
  logType?: BotViolationLogType | null;
  nodeIATACode?: string | null;
  profile?: string | null;
  reason?: string | null;
  signatureName?: string | null;
  sourceCity?: string | null;
  sourceCountryName?: string | null;
  sourceIP?: IPAddressInput | null;
  timestamp?: string | null;
  uri?: string | null;
  userAgent?: string | null;
}

/**
 * A Bot violation log group by input.
 */
export interface BotViolationLogGroupByInput {
  direction?: SortDirection | null;
  field: BotViolationLogGroupByField;
  timeInterval?: TimeInterval | null;
}

/**
 * Bot violation log sorting.
 */
export interface BotViolationLogSortBy {
  dimension: BotViolationLogDimension;
  direction: SortDirection;
}

/**
 * A white list binding.
 */
export interface BotWhiteListBindingInput {
  active: boolean;
  expressionMatch?: BotBlackWhiteListExpressionMatchInput | null;
  response: BotWhiteListResponse;
  type: BotWhiteListType;
  value?: string | null;
}

/**
 * A CSRF relaxation rule. Form tagging must be enabled to use this feature.
 */
export interface CSRFRelaxationRuleInput {
  enabled: boolean;
  formActionURL: string;
  formOriginURL: string;
}

/**
 * Input of Category Configuration.
 */
export interface CategoryConfigurationInput {
  category: NotificationCategory;
  email: boolean;
  slack?: boolean | null;
  webhook?: boolean | null;
}

/**
 * Associate a certificate with a virtual server.
 */
export interface CertificateBindingInput {
  certificateID: string;
}

/**
 * Input of Channel configuration.
 */
export interface ChannelConfigurationInput {
  email?: EmailConfigurationInput | null;
  slack?: SlackConfigurationInput | null;
  webhook?: WebhookConfigurationInput | null;
}

/**
 * A Command injection relaxation rule input.
 */
export interface CommandInjectionRelaxationRuleInput {
  enabled: boolean;
  isNameRegex: boolean;
  isValueExpressionRegex?: boolean | null;
  location?: HTMLLocation | null;
  name: string;
  url: string;
  valueExpression?: string | null;
  valueType?: CommandInjectionValueType | null;
}

/**
 * Filters queries for companies.
 */
export interface CompanyFilterInput {
  dName?: string | null;
}

/**
 * Specify how to search Company users.
 */
export interface CompanyUsersFilterInput {
  emailSubstring?: string | null;
  includeDescendants?: boolean | null;
  includeDisabled?: boolean | null;
  userNameSubstring?: string | null;
}

/**
 * Configure an action for a base Bot signature.
 */
export interface ConfiguredBaseBotSignatureInput {
  action: BotSignatureAction;
  enabled: boolean;
  id: string;
}

/**
 * Configure an action for a base WAF signature.
 */
export interface ConfiguredBaseWAFSignatureInput {
  action: WAFAction;
  id: string;
}

/**
 * A content type relaxation rule.
 */
export interface ContentTypeRelaxationRuleInput {
  contentType: string;
  enabled: boolean;
}

/**
 * A cookie consistency relaxation rule.
 */
export interface CookieConsistencyRelaxationRuleInput {
  cookieName?: string | null;
  enabled: boolean;
  isRegex: boolean;
}

/**
 * Create a WAF custom signature cookie rule.
 */
export interface CookieSignatureRuleInput {
  cookieName?: string | null;
  cookieNameFormat: SignatureRuleFormat;
}

/**
 * Create a black list countermeasure.
 */
export interface CreateBotBlackListInput {
  enabled: boolean;
  types?: BotBlackListBindingInput[] | null;
}

/**
 * Create a CAPTCHA countermeasure.
 */
export interface CreateBotCAPTCHAInput {
  resources?: BotCAPTCHABindingInput[] | null;
}

/**
 * Create a device fingerprint countermeasure.
 */
export interface CreateBotDeviceFingerprintInput {
  action?: BotDeviceFingerprintAction | null;
  enabled: boolean;
  response: BotResponse;
}

/**
 * Create an IP reputation countermeasure.
 */
export interface CreateBotIPReputationInput {
  categories?: BotIPReputationBindingInput[] | null;
  enabled: boolean;
}

/**
 * Create a new bot profile.
 */
export interface CreateBotProfileInput {
  blackList?: CreateBotBlackListInput | null;
  botTrap?: CreateBotTrapInput | null;
  captcha?: CreateBotCAPTCHAInput | null;
  deviceFingerprint?: CreateBotDeviceFingerprintInput | null;
  enabled: boolean;
  ipReputation?: CreateBotIPReputationInput | null;
  rateLimit?: CreateBotRateLimitInput | null;
  signatures?: CreateBotSignaturesInput | null;
  tps?: CreateBotTPSInput | null;
  whiteList?: CreateBotWhiteListInput | null;
}

/**
 * Create a rate limit countermeasure.
 */
export interface CreateBotRateLimitInput {
  enabled: boolean;
  resources?: BotRateLimitBindingInput[] | null;
}

/**
 * Create bot signatures.
 */
export interface CreateBotSignaturesInput {
  configuredBaseSignatures?: ConfiguredBaseBotSignatureInput[] | null;
  enabled: boolean;
}

/**
 * Create a TPS countermeasure.
 */
export interface CreateBotTPSInput {
  enabled: boolean;
  resources?: BotTPSBindingInput[] | null;
}

/**
 * Create a bot trap countermeasure.
 */
export interface CreateBotTrapInput {
  action?: BotTrapAction | null;
  enabled: boolean;
  insertionURLs?: BotTrapBindingInput[] | null;
  response: BotResponse;
}

/**
 * Create a white list countermeasure.
 */
export interface CreateBotWhiteListInput {
  enabled: boolean;
  types?: BotWhiteListBindingInput[] | null;
}

/**
 * Create a buffer overflow countermeasure.
 */
export interface CreateBufferOverflowInput {
  action: WAFAction;
  maxCookieLength: any;
  maxHeaderLength: any;
  maxURLLength: any;
  threshold?: ThresholdInput | null;
}

/**
 * Create a cross-site request forgery countermeasure.
 */
export interface CreateCSRFSettingsInput {
  action: WAFAction;
  learn: boolean;
  relaxationRules?: CSRFRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Create a certificate.
 */
export interface CreateCertificateInput {
  certPEM: string;
  companyDName?: string | null;
  keyPEM: string;
  keyPass?: string | null;
  name: string;
}

/**
 * Create a command injection countermeasure.
 */
export interface CreateCommandInjectionInput {
  action: WAFAction;
  commandInjectionType: CommandInjectionType;
  relaxationRules?: CommandInjectionRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Create a content type countermeasure.
 */
export interface CreateContentTypeInput {
  action: WAFAction;
  learn: boolean;
  relaxationRules?: ContentTypeRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Create a cookie consistency countermeasure.
 */
export interface CreateCookieConsistencyInput {
  action: WAFAction;
  learn: boolean;
  relaxationRules?: CookieConsistencyRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Create a deny URL countermeasure.
 */
export interface CreateDenyURLInput {
  action: WAFAction;
  regexRules?: DenyURLRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Create a field format countermeasure.
 */
export interface CreateFieldFormatInput {
  action: WAFAction;
  enforcementRules?: FieldFormatEnforcementRuleInput[] | null;
  learn: boolean;
  maxLength: any;
  minLength: any;
  threshold?: ThresholdInput | null;
  type: FieldFormatType;
}

/**
 * Create a form field consistency countermeasure.
 */
export interface CreateFormFieldConsistencyInput {
  action: WAFAction;
  fieldConsistencyExemptions?: FormFieldConsistencyRuleInput[] | null;
  learn: boolean;
  sessionlessFieldConsistency: SessionlessFieldConsistency;
  threshold?: ThresholdInput | null;
}

/**
 * Create an HTML SQL injection countermeasure.
 */
export interface CreateHTMLSQLInjectionInput {
  action: WAFAction;
  checkSQLWildChars: boolean;
  exemptCommentsWith: CommentExemption;
  learn: boolean;
  relaxationRules?: HTMLSQLInjectionRelaxationRuleInput[] | null;
  sqlInjectionType: SQLInjectionType;
  sqliGrammar: boolean;
  threshold?: ThresholdInput | null;
}

/**
 * Create an HTML cross-site scripting countermeasure.
 */
export interface CreateHTMLXSSInput {
  action: WAFAction;
  checkCompleteURLs: boolean;
  learn: boolean;
  relaxationRules?: HTMLXSSRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Create an HTTP RFC Profile countermeasure.
 */
export interface CreateHTTPRFCProfileInput {
  action: HTTPRFCProfileAction;
  threshold?: ThresholdInput | null;
}

/**
 * Create a JSON command injection Settings Input.
 */
export interface CreateJSONCommandInjectionSettingsInput {
  action: WAFAction;
  commandInjectionType: CommandInjectionType;
  relaxationRules?: JSONCommandInjectionRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Create a JSON cross-site scripting settings input to protect applications from  XSS Attacks through JSON requests
 */
export interface CreateJSONCrossSiteScriptingSettingsInput {
  action: WAFAction;
  relaxationRules?: JSONXSSRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Create a JSON Denial of Service Settings input to protect applications from Denial of Service Attacks through JSON requests
 */
export interface CreateJSONDenialOfServiceSettingsInput {
  action: WAFAction;
  enforcementRule?: JSONDoSEnforcementRuleInput | null;
  threshold?: ThresholdInput | null;
}

/**
 * Create a JSON SQL Injection Settings input to protect applications from SQL Injection attacks through JSON requests
 */
export interface CreateJSONSQLInjectionSettingsInput {
  action: WAFAction;
  relaxationRules?: JSONSQLInjectionRelaxationRuleInput[] | null;
  sqlInjectionType: SQLInjectionType;
  sqliGrammar: boolean;
  threshold?: ThresholdInput | null;
}

/**
 * Create a JSON Security Settings input to protect JSON Applications
 */
export interface CreateJSONSettingsInput {
  jsonCommandInjectionSettings?: CreateJSONCommandInjectionSettingsInput | null;
  jsonCrossSiteScriptingSettings?: CreateJSONCrossSiteScriptingSettingsInput | null;
  jsonDenialOfServiceSettings?: CreateJSONDenialOfServiceSettingsInput | null;
  jsonSQLInjectionSettings?: CreateJSONSQLInjectionSettingsInput | null;
}

/**
 * Create network controls.
 */
export interface CreateNetworkControlsInput {
  blockedCountries?: CountryCode[] | null;
  ipFilterList?: IPFilterInput[] | null;
}

/**
 * Input Configuration to create a one time reporting job.
 */
export interface CreateOneTimeExecutiveReportConfigurationInput {
  dName: string;
  description?: string | null;
  enabled?: boolean | null;
  from: any;
  includeBot?: boolean | null;
  includeDDOS?: boolean | null;
  includeWAF?: boolean | null;
  name: string;
  notification?: ExecutiveReportNotificationDetailsInput | null;
  to: any;
}

/**
 * Create a POST BODY limit countermeasure.
 */
export interface CreatePOSTBodyInput {
  limit: any;
  threshold?: ThresholdInput | null;
}

/**
 * Create a Policy.
 */
export interface CreatePolicyInput {
  appSecThresholds?: AppSecThresholdInput[] | null;
  botProfile?: CreateBotProfileInput | null;
  companyDName?: string | null;
  name: string;
  networkControls?: CreateNetworkControlsInput | null;
  responderPolicies?: ResponderPolicyInput[] | null;
  trustedSources?: TrustedSourceInput[] | null;
  wafProfile?: CreateWAFProfileInput | null;
}

/**
 * Create a proxy.
 */
export interface CreateProxyInput {
  companyDName?: string | null;
  ipVersion: IPVersion;
  name: string;
  policyIDs?: string[] | null;
  vServers?: VServerInput[] | null;
}

/**
 * Input for a create recurring report configuration operation.
 */
export interface CreateRecurringExecutiveReportConfigurationInput {
  dName: string;
  description?: string | null;
  enabled?: boolean | null;
  from?: any | null;
  includeBot?: boolean | null;
  includeDDOS?: boolean | null;
  includeWAF?: boolean | null;
  name: string;
  notification?: ExecutiveReportNotificationDetailsInput | null;
  period: ExecutiveReportPeriodInput;
  to?: any | null;
}

/**
 * Input needed to create a SAML connection in Auth0.
 */
export interface CreateSAMLInput {
  dName: string;
  domain?: string[] | null;
  protocolBinding?: Protocol | null;
  requestTemplate?: string | null;
  signInURL: string;
  signatureAlgorithm?: Algorithm | null;
  signatureDigestAlgorithm?: Algorithm | null;
  signatureRequest?: boolean | null;
  sslCertificate: string;
  userIDAttributes?: string | null;
}

/**
 * Create a Semicolon field separator countermeasure.
 */
export interface CreateSemicolonFieldSeparatorInput {
  enabled: boolean;
}

/**
 * Create a User.
 */
export interface CreateUserInput {
  company: string;
  email: string;
  firstName: string;
  jobTitle?: string | null;
  lastName: string;
  mobile?: string | null;
  phone?: string | null;
  roles: UserRole[];
}

/**
 * Create a User notification configuration.
 */
export interface CreateUserNotificationConfigurationInput {
  company: string;
  userID: string;
}

/**
 * Create a WAF profile.
 */
export interface CreateWAFProfileInput {
  bufferOverflow?: CreateBufferOverflowInput | null;
  commandInjection?: CreateCommandInjectionInput | null;
  contentType?: CreateContentTypeInput | null;
  cookieConsistency?: CreateCookieConsistencyInput | null;
  crossSiteScripting?: CreateHTMLXSSInput | null;
  csrfSettings?: CreateCSRFSettingsInput | null;
  denyURL?: CreateDenyURLInput | null;
  enabled: boolean;
  fieldConsistency?: CreateFormFieldConsistencyInput | null;
  fieldFormat?: CreateFieldFormatInput | null;
  htmlSQLInjection?: CreateHTMLSQLInjectionInput | null;
  httpRFCProfile?: CreateHTTPRFCProfileInput | null;
  jsonSettings?: CreateJSONSettingsInput | null;
  postBody?: CreatePOSTBodyInput | null;
  semicolonFieldSeparator?: CreateSemicolonFieldSeparatorInput | null;
  signatures?: CreateWAFSignaturesInput | null;
  wsiSettings?: CreateWSISettingsInput | null;
  xmlCrossSiteScripting?: CreateXMLXSSInput | null;
  xmlFormat?: CreateXMLFormatInput | null;
  xmlSOAPFault?: CreateXMLSOAPFaultInput | null;
  xmlSQLInjection?: CreateXMLSQLInjectionInput | null;
}

/**
 * Create WAF signatures for a Policy.
 */
export interface CreateWAFSignaturesInput {
  configuredBaseSignatures?: ConfiguredBaseWAFSignatureInput[] | null;
  customSignatures?: CustomWAFSignatureInput[] | null;
}

/**
 * Create a web services interoperability countermeasure.
 */
export interface CreateWSISettingsInput {
  action: WAFAction;
  learn: boolean;
  standards?: WSIStandardInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Create an XML format countermeasure.
 */
export interface CreateXMLFormatInput {
  action: WAFAction;
  threshold?: ThresholdInput | null;
}

/**
 * Create an XML SOAP fault countermeasure.
 */
export interface CreateXMLSOAPFaultInput {
  action: XMLSOAPFaultAction;
  threshold?: ThresholdInput | null;
}

/**
 * Create an HTML SQL injection countermeasure.
 */
export interface CreateXMLSQLInjectionInput {
  action: WAFAction;
  checkSQLWildChars: boolean;
  exemptCommentsWith: CommentExemption;
  relaxationRules?: XMLSQLInjectionRelaxationRuleInput[] | null;
  sqlInjectionType: SQLInjectionType;
  threshold?: ThresholdInput | null;
}

/**
 * Create an XML cross-site scripting countermeasure.
 */
export interface CreateXMLXSSInput {
  action: WAFAction;
  relaxationRules?: XMLXSSRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Specify a WAF custom signature.
 */
export interface CustomWAFSignatureInput {
  action: WAFAction;
  category: string;
  description: string;
  requestRules?: SignatureRequestRuleInput[] | null;
  responseRules?: SignatureResponseRuleInput[] | null;
}

/**
 * Filters queries for customers.
 */
export interface CustomerFilterInput {
  activationState?: FeatureActivationState | null;
  bgpAlwaysOn?: FeatureActivationState | null;
  bgpOnDemand?: FeatureActivationState | null;
  corporateNameSubstring?: string | null;
  dNamePrefix?: string | null;
  detectionAndAlertingActivation?: FeatureActivationState | null;
  includeDeleted: boolean;
  includeDisabled: boolean;
  maxDepth: number;
  proxyActivation?: FeatureActivationState | null;
  wafActivation?: FeatureActivationState | null;
}

/**
 * Input for deleting a certificate.
 */
export interface DeleteCertificateInput {
  id: string;
}

/**
 * Input required to delete a generated executive report.
 */
export interface DeleteExecutiveReportInput {
  id: string;
}

/**
 * Input required to delete a one time executive report configuration.
 */
export interface DeleteOneTimeExecutiveReportConfigurationInput {
  id: string;
}

/**
 * Delete a policy.
 */
export interface DeletePolicyInput {
  id: string;
}

/**
 * Delete a proxy.
 */
export interface DeleteProxyInput {
  deletePermanently: boolean;
  id: string;
}

/**
 * Input required to delete a recurring executive report configuration.
 */
export interface DeleteRecurringExecutiveReportConfigurationInput {
  id: string;
}

/**
 * Create a Deny URL Regex Rule.
 */
export interface DenyURLRuleInput {
  denyURL?: string | null;
  enabled: boolean;
  type: DenyURLType;
}

/**
 * Represents the Email channel-specific configuration input.
 */
export interface EmailConfigurationInput {
  emailAddresses?: string[] | null;
}

/**
 *  The filters that can be applied to scope the list of events.
 */
export interface EventFilterInput {
  active?: boolean | null;
  alertid?: string | null;
  id?: string | null;
  prefixes?: any[] | null;
  providers?: MitigationType[] | null;
}

/**
 *  EventsSortBy sorting.
 */
export interface EventSortBy {
  dimension: EventDimension;
  direction: SortDirection;
}

/**
 * Report configuration filter input for report configuration queries.
 */
export interface ExecutiveReportConfigurationFilterInput {
  excludeBot?: boolean | null;
  excludeDDoS?: boolean | null;
  excludeWAF?: boolean | null;
  from?: any | null;
  id?: string | null;
  includeDisabled?: boolean | null;
  includeExpired?: boolean | null;
  name?: string | null;
  reportType?: ExecutiveReportType | null;
  to?: any | null;
}

/**
 * Input filter for Report queries.
 */
export interface ExecutiveReportFilterInput {
  endDate?: any | null;
  id?: string | null;
  reportConfigurationName?: string | null;
  reportType?: ExecutiveReportType | null;
  startDate?: any | null;
}

/**
 * Input notification configuration for report receipients.
 */
export interface ExecutiveReportNotificationDetailsInput {
  emailList?: string[] | null;
}

/**
 * Filter for a feature's enabled and contract statuses.
 */
export interface FeatureActivationState {
  enabled?: boolean | null;
  entitled?: boolean | null;
}

/**
 * Create a field format enforcement rule.
 */
export interface FieldFormatEnforcementRuleInput {
  actionURL: string;
  enabled: boolean;
  fieldType: FieldFormatType;
  formFieldName: string;
  isFormFieldRegex: boolean;
  maxLength: any;
  minLength: any;
}

/**
 * FilterListSortBy sorting.
 */
export interface FilterListSortBy {
  dimension: FilterListDimension;
  direction: SortDirection;
}

export interface FilterListsFilterInput {
  id?: string | null;
  searchWildcard?: string | null;
}

/**
 * A form field consistency exemption rule.
 */
export interface FormFieldConsistencyRuleInput {
  actionURL: string;
  enabled: boolean;
  formFieldName: string;
  isFormFieldRegex: boolean;
}

/**
 * Create a WAF custom signature form field rule.
 */
export interface FormFieldSignatureRuleInput {
  fieldName?: string | null;
  fieldNameFormat: SignatureRuleFormat;
  url?: string | null;
  urlFormat: SignatureRuleFormat;
}

/**
 * An HTML SQL injection relaxation rule.
 */
export interface HTMLSQLInjectionRelaxationRuleInput {
  enabled: boolean;
  isNameRegex: boolean;
  isValueExpressionRegex?: boolean | null;
  location?: HTMLLocation | null;
  name: string;
  url: string;
  valueExpression?: string | null;
  valueType?: ValueType | null;
}

/**
 * An HTML XSS relaxation rule.
 */
export interface HTMLXSSRelaxationRuleInput {
  enabled: boolean;
  isNameRegex: boolean;
  isValueExpressionRegex?: boolean | null;
  location?: HTMLXSSLocation | null;
  name: string;
  url: string;
  valueExpression?: string | null;
  valueType?: XSSValueType | null;
}

/**
 * Create a WAF custom signature header rule.
 */
export interface HeaderSignatureRuleInput {
  headerName?: string | null;
  headerNameFormat: SignatureRuleFormat;
}

/**
 * IPAddressInput is used to specify a generic IP address. One and only one field can be specified.
 */
export interface IPAddressInput {
  ipv4Address?: any | null;
  ipv6Address?: any | null;
}

/**
 * Create an IP filter.
 */
export interface IPFilterInput {
  cidr: any;
  isBlocked: boolean;
}

/**
 * A JSON command injection relaxation rule input.
 */
export interface JSONCommandInjectionRelaxationRuleInput {
  enabled: boolean;
  exemptURL: string;
}

/**
 * A JSON Denial of Service enforcement rule input.
 */
export interface JSONDoSEnforcementRuleInput {
  enabled: boolean;
  jsonMaxArrayLength?: any | null;
  jsonMaxContainerDepth?: any | null;
  jsonMaxDocumentLength?: any | null;
  jsonMaxObjectKeyCount?: any | null;
  jsonMaxObjectKeyLength?: any | null;
  jsonMaxStringLength?: any | null;
}

/**
 * A JSON SQL injection relaxation rule input.
 */
export interface JSONSQLInjectionRelaxationRuleInput {
  enabled: boolean;
  exemptURL: string;
}

/**
 * A JSON XSS relaxation rule input.
 */
export interface JSONXSSRelaxationRuleInput {
  enabled: boolean;
  exemptURL: string;
}

export interface ManagedObjectFilterInput {
  types?: ManagedObjectType[] | null;
}

/**
 *  The filters that can be applied to scope the list of mitigations.
 */
export interface MitigationFilterInput {
  active?: boolean | null;
  id?: string | null;
  providers?: MitigationType[] | null;
}

/**
 *  EventsSortBy sorting.
 */
export interface MitigationSortBy {
  dimension: MitigationDimension;
  direction: SortDirection;
}

/**
 * A filter input to constrain the queried results of notification configurations.
 */
export interface NotificationConfigurationFilterInput {
  companyConfiguration?: boolean | null;
  id?: string | null;
  userConfigurations?: boolean | null;
  userIDs?: string[] | null;
}

/**
 * A filter input to constrain the queried results of notification records.
 */
export interface NotificationRecordFilterInput {
  categories?: NotificationCategory[] | null;
  id?: string | null;
  unread?: boolean | null;
}

/**
 * NotificationSortBy of notification configurations.
 */
export interface NotificationSortBy {
  dimension: NotificationDimension;
  direction: SortDirection;
}

/**
 * Filter a list of policies.
 */
export interface PolicyFilterInput {
  id?: string | null;
  includeDeleted: boolean;
  key?: string | null;
}

/**
 * Input required if extra criteria are needed to constrain the queried results.
 */
export interface ProxyFilterInput {
  id?: string | null;
  includeDeleted: boolean;
}

/**
 * Represents a Responder log filter input.
 */
export interface ResponderLogFilterInput {
  all?: string | null;
  destinationIP?: IPAddressInput | null;
  logType?: ResponderLogType | null;
  responderAction?: string | null;
  sourceIP?: IPAddressInput | null;
  uri?: string | null;
}

export interface ResponderLogGroupByInput {
  direction: SortDirection;
  field: ResponderLogGroupByField;
  timeInterval?: TimeInterval | null;
}

/**
 * Represents a responder log sort input.
 */
export interface ResponderLogSortBy {
  dimension: ResponderLogDimension;
  direction: SortDirection;
}

/**
 * Create a responder match in a given responder policy.
 */
export interface ResponderMatchInput {
  field: ResponderField;
  operand: ResponderOperand;
  value: string;
}

/**
 * Create a responder policy for a given policy.
 */
export interface ResponderPolicyInput {
  action: ResponderAction;
  name: string;
  responderMatches?: ResponderMatchInput[] | null;
  response?: string | null;
}

/**
 * Input needed for update.
 */
export interface SAMLInput {
  id: string;
}

/**
 * Send Test Notification.
 */
export interface SendTestNotificationInput {
  company: string;
}

/**
 * Create a WAF custom signature request rule.
 */
export interface SignatureRequestRuleInput {
  area: SignatureRequestArea;
  cookieRule?: CookieSignatureRuleInput | null;
  formFieldSignatureRule?: FormFieldSignatureRuleInput | null;
  headerRule?: HeaderSignatureRuleInput | null;
  match: string;
  matchFormat: SignatureMatchFormat;
}

/**
 * Create a WAF custom signature response rule.
 */
export interface SignatureResponseRuleInput {
  area: SignatureResponseArea;
  match: string;
  matchFormat: SignatureMatchFormat;
}

/**
 * Represents the Slack channel-specific configuration.
 */
export interface SlackConfigurationInput {
  URL: string;
  channel: string;
}

/**
 * Define TLS options for a virtual server.
 */
export interface TLSOptionsInput {
  cipherSelection: CipherSelectionMode;
  ciphers?: TLSCipher[] | null;
  commonName: string;
  forceBackendSNI: boolean;
  hstsEnabled: boolean;
  hstsIncludeSubdomains: boolean;
  hstsMaxAge: any;
  hstsPreload: boolean;
  minTLSVersion: MinTLSVersion;
}

/**
 * Create a WAF Application Security (AppSec) Threshold.
 */
export interface ThresholdInput {
  bucketDurationSeconds: any;
  count: any;
}

/**
 * A time interval.
 */
export interface TimeInterval {
  interval: any;
  unit: TimeUnit;
}

/**
 * A trusted IP source.
 */
export interface TrustedSourceInput {
  cidr: any;
  description?: string | null;
  enabled: boolean;
}

/**
 * Modify a black list countermeasure.
 */
export interface UpdateBotBlackListInput {
  enabled?: boolean | null;
  types?: BotBlackListBindingInput[] | null;
}

/**
 * Modify a CAPTCHA countermeasure.
 */
export interface UpdateBotCAPTCHAInput {
  resources?: BotCAPTCHABindingInput[] | null;
}

/**
 * Modify a device fingerprint countermeasure.
 */
export interface UpdateBotDeviceFingerprintInput {
  action?: BotDeviceFingerprintAction | null;
  enabled?: boolean | null;
  response?: BotResponse | null;
}

/**
 * Modify an IP reputation countermeasure.
 */
export interface UpdateBotIPReputationInput {
  categories?: BotIPReputationBindingInput[] | null;
  enabled?: boolean | null;
}

/**
 * Modify a bot profile.
 */
export interface UpdateBotProfileInput {
  blackList?: UpdateBotBlackListInput | null;
  botTrap?: UpdateBotTrapInput | null;
  captcha?: UpdateBotCAPTCHAInput | null;
  deviceFingerprint?: UpdateBotDeviceFingerprintInput | null;
  enabled?: boolean | null;
  ipReputation?: UpdateBotIPReputationInput | null;
  rateLimit?: UpdateBotRateLimitInput | null;
  signatures?: UpdateBotSignaturesInput | null;
  tps?: UpdateBotTPSInput | null;
  whiteList?: UpdateBotWhiteListInput | null;
}

/**
 * Modify a rate limit countermeasure.
 */
export interface UpdateBotRateLimitInput {
  enabled?: boolean | null;
  resources?: BotRateLimitBindingInput[] | null;
}

/**
 * Modify bot signatures.
 */
export interface UpdateBotSignaturesInput {
  configuredBaseSignatures?: ConfiguredBaseBotSignatureInput[] | null;
  enabled?: boolean | null;
}

/**
 * Modify a TPS countermeasure.
 */
export interface UpdateBotTPSInput {
  enabled?: boolean | null;
  resources?: BotTPSBindingInput[] | null;
}

/**
 * Modify a bot trap countermeasure.
 */
export interface UpdateBotTrapInput {
  action?: BotTrapAction | null;
  enabled?: boolean | null;
  insertionURLs?: BotTrapBindingInput[] | null;
  response?: BotResponse | null;
}

/**
 * Modify a white list countermeasure.
 */
export interface UpdateBotWhiteListInput {
  enabled?: boolean | null;
  types?: BotWhiteListBindingInput[] | null;
}

/**
 * Modify a buffer overflow countermeasure.
 */
export interface UpdateBufferOverflowInput {
  action?: WAFAction | null;
  maxCookieLength?: any | null;
  maxHeaderLength?: any | null;
  maxURLLength?: any | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify a cross-site request forgery countermeasure.
 */
export interface UpdateCSRFSettingsInput {
  action?: WAFAction | null;
  learn?: boolean | null;
  relaxationRules?: CSRFRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify a command injection countermeasure.
 */
export interface UpdateCommandInjectionInput {
  action: WAFAction;
  commandInjectionType: CommandInjectionType;
  relaxationRules?: CommandInjectionRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Update a Company notification configuration.
 */
export interface UpdateCompanyNotificationConfigurationInput {
  categories?: CategoryConfigurationInput[] | null;
  channel?: ChannelConfigurationInput | null;
  company?: string | null;
  enabled?: boolean | null;
  id?: string | null;
}

/**
 * Modify a content type countermeasure.
 */
export interface UpdateContentTypeInput {
  action?: WAFAction | null;
  learn?: boolean | null;
  relaxationRules?: ContentTypeRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify a cookie consistency countermeasure.
 */
export interface UpdateCookieConsistencyInput {
  action?: WAFAction | null;
  learn?: boolean | null;
  relaxationRules?: CookieConsistencyRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify a deny URL countermeasures configuration.
 */
export interface UpdateDenyURLInput {
  action?: WAFAction | null;
  regexRules?: DenyURLRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify a field format countermeasure.
 */
export interface UpdateFieldFormatInput {
  action?: WAFAction | null;
  enforcementRules?: FieldFormatEnforcementRuleInput[] | null;
  learn?: boolean | null;
  maxLength?: any | null;
  minLength?: any | null;
  threshold?: ThresholdInput | null;
  type?: FieldFormatType | null;
}

/**
 * Modify a form field consistency countermeasure.
 */
export interface UpdateFormFieldConsistencyInput {
  action?: WAFAction | null;
  fieldConsistencyExemptions?: FormFieldConsistencyRuleInput[] | null;
  learn?: boolean | null;
  sessionlessFieldConsistency?: SessionlessFieldConsistency | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify an HTML SQL injection countermeasure.
 */
export interface UpdateHTMLSQLInjectionInput {
  action?: WAFAction | null;
  checkSQLWildChars?: boolean | null;
  exemptCommentsWith?: CommentExemption | null;
  learn?: boolean | null;
  relaxationRules?: HTMLSQLInjectionRelaxationRuleInput[] | null;
  sqlInjectionType?: SQLInjectionType | null;
  sqliGrammar?: boolean | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify an HTML cross-site scripting countermeasure.
 */
export interface UpdateHTMLXSSInput {
  action?: WAFAction | null;
  checkCompleteURLs?: boolean | null;
  learn?: boolean | null;
  relaxationRules?: HTMLXSSRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify an HTTP RFC Profile countermeasure.
 */
export interface UpdateHTTPRFCProfileInput {
  action?: HTTPRFCProfileAction | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modufy a JSON command injection Settings Input.
 */
export interface UpdateJSONCommandInjectionSettingsInput {
  action?: WAFAction | null;
  commandInjectionType?: CommandInjectionType | null;
  relaxationRules?: JSONCommandInjectionRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify a JSON cross-site scripting settings input to protect applications from  XSS Attacks through JSON requests
 */
export interface UpdateJSONCrossSiteScriptingSettingsInput {
  action?: WAFAction | null;
  relaxationRules?: JSONXSSRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify a JSON Denial of Service Settings input to protect applications from Denial of Service Attacks through JSON requests
 */
export interface UpdateJSONDenialOfServiceSettingsInput {
  action?: WAFAction | null;
  enforcementRule?: JSONDoSEnforcementRuleInput | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify a JSON SQL Injection Settings input to protect applications from SQL Injection attacks through JSON requests
 */
export interface UpdateJSONSQLInjectionSettingsInput {
  action?: WAFAction | null;
  relaxationRules?: JSONSQLInjectionRelaxationRuleInput[] | null;
  sqlInjectionType?: SQLInjectionType | null;
  sqliGrammar?: boolean | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify a JSON Security Settings input to protect JSON Applications
 */
export interface UpdateJSONSettingsInput {
  jsonCommandInjectionSettings?: UpdateJSONCommandInjectionSettingsInput | null;
  jsonCrossSiteScriptingSettings?: UpdateJSONCrossSiteScriptingSettingsInput | null;
  jsonDenialOfServiceSettings?: UpdateJSONDenialOfServiceSettingsInput | null;
  jsonSQLInjectionSettings?: UpdateJSONSQLInjectionSettingsInput | null;
}

/**
 * Modify network controls.
 */
export interface UpdateNetworkControlsInput {
  blockedCountries?: CountryCode[] | null;
  ipFilterList?: IPFilterInput[] | null;
}

/**
 * Modify a POST BODY limit countermeasure.
 */
export interface UpdatePOSTBodyInput {
  limit?: any | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify a policy.
 */
export interface UpdatePolicyInput {
  appSecThresholds?: AppSecThresholdInput[] | null;
  botProfile?: UpdateBotProfileInput | null;
  id: string;
  name?: string | null;
  networkControls?: UpdateNetworkControlsInput | null;
  responderPolicies?: ResponderPolicyInput[] | null;
  trustedSources?: TrustedSourceInput[] | null;
  wafProfile?: UpdateWAFProfileInput | null;
}

/**
 * Modify a proxy.
 */
export interface UpdateProxyInput {
  id: string;
  name?: string | null;
  policyIDs?: string[] | null;
  vServers?: VServerInput[] | null;
}

/**
 * Input to an update of a recurring report configuration.
 */
export interface UpdateRecurringExecutiveReportConfigurationInput {
  dName: string;
  description?: string | null;
  enabled?: boolean | null;
  from?: any | null;
  id: string;
  includeBot?: boolean | null;
  includeDDOS?: boolean | null;
  includeWAF?: boolean | null;
  notification?: ExecutiveReportNotificationDetailsInput | null;
  period: ExecutiveReportPeriod;
  to?: any | null;
}

/**
 * Input needed for updating a SAML connection.
 */
export interface UpdateSAMLInput {
  domain?: string[] | null;
  id: string;
  protocolBinding?: Protocol | null;
  requestTemplate?: string | null;
  signInURL?: string | null;
  signatureAlgorithm?: Algorithm | null;
  signatureDigestAlgorithm?: Algorithm | null;
  signatureRequest?: boolean | null;
  sslCertificate?: string | null;
  userIDAttributes?: string | null;
}

/**
 * Modify a Semicolon field separator countermeasure.
 */
export interface UpdateSemicolonFieldSeparatorInput {
  enabled?: boolean | null;
}

/**
 * Modify a user.
 */
export interface UpdateUserInput {
  enabled?: boolean | null;
  firstName?: string | null;
  id: string;
  jobTitle?: string | null;
  lastName?: string | null;
  mobile?: string | null;
  phone?: string | null;
  roles?: UserRole[] | null;
}

/**
 * Update a User notification configuration.
 */
export interface UpdateUserNotificationConfigurationInput {
  categories?: CategoryConfigurationInput[] | null;
  enabled?: boolean | null;
  id?: string | null;
  userID?: string | null;
}

/**
 * Modify a WAF profile.
 */
export interface UpdateWAFProfileInput {
  bufferOverflow?: UpdateBufferOverflowInput | null;
  commandInjection?: UpdateCommandInjectionInput | null;
  contentType?: UpdateContentTypeInput | null;
  cookieConsistency?: UpdateCookieConsistencyInput | null;
  crossSiteScripting?: UpdateHTMLXSSInput | null;
  csrfSettings?: UpdateCSRFSettingsInput | null;
  denyURL?: UpdateDenyURLInput | null;
  enabled?: boolean | null;
  fieldConsistency?: UpdateFormFieldConsistencyInput | null;
  fieldFormat?: UpdateFieldFormatInput | null;
  htmlSQLInjection?: UpdateHTMLSQLInjectionInput | null;
  httpRFCProfile?: UpdateHTTPRFCProfileInput | null;
  jsonSettings?: UpdateJSONSettingsInput | null;
  postBody?: UpdatePOSTBodyInput | null;
  semicolonFieldSeparator?: UpdateSemicolonFieldSeparatorInput | null;
  signatures?: UpdateWAFSignaturesInput | null;
  wsiSettings?: UpdateWSISettingsInput | null;
  xmlCrossSiteScripting?: UpdateXMLXSSInput | null;
  xmlFormat?: UpdateXMLFormatInput | null;
  xmlSOAPFault?: UpdateXMLSOAPFaultInput | null;
  xmlSQLInjection?: UpdateXMLSQLInjectionInput | null;
}

/**
 * Modify WAF signatures.
 */
export interface UpdateWAFSignaturesInput {
  configuredBaseSignatures?: ConfiguredBaseWAFSignatureInput[] | null;
  customSignatures?: CustomWAFSignatureInput[] | null;
}

/**
 * Modify a web services interoperability countermeasure.
 */
export interface UpdateWSISettingsInput {
  action?: WAFAction | null;
  learn?: boolean | null;
  standards?: WSIStandardInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify an XML format countermeasure.
 */
export interface UpdateXMLFormatInput {
  action?: WAFAction | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify an XML SOAP fault countermeasure.
 */
export interface UpdateXMLSOAPFaultInput {
  action?: XMLSOAPFaultAction | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify an HTML SQL injection countermeasure.
 */
export interface UpdateXMLSQLInjectionInput {
  action?: WAFAction | null;
  checkSQLWildChars?: boolean | null;
  exemptCommentsWith?: CommentExemption | null;
  relaxationRules?: XMLSQLInjectionRelaxationRuleInput[] | null;
  sqlInjectionType?: SQLInjectionType | null;
  threshold?: ThresholdInput | null;
}

/**
 * Modify an XML cross-site scripting countermeasure.
 */
export interface UpdateXMLXSSInput {
  action?: WAFAction | null;
  relaxationRules?: XMLXSSRelaxationRuleInput[] | null;
  threshold?: ThresholdInput | null;
}

/**
 * For reducing the returned list of users.
 */
export interface UserFilterInput {
  email?: string | null;
  id?: string | null;
  includeDisabled?: boolean | null;
}

/**
 * Add a VServer to a Proxy.
 */
export interface VServerInput {
  applicationServices?: ApplicationServiceInput[] | null;
  certificateBindings?: CertificateBindingInput[] | null;
  loadBalanceMethod: ProxyLoadBalanceMethod;
  persistenceType: ProxyLoadBalancePersistenceType;
  port: any;
  protocol: ProxyProtocol;
  sp?: boolean | null;
  tcpb?: boolean | null;
  tlsOptions?: TLSOptionsInput | null;
  xffHeader: string;
}

/**
 * A WAF violation log filter input.
 */
export interface ViolationLogFilterInput {
  action?: string | null;
  aggregatedURI?: string | null;
  all?: string | null;
  destinationIP?: IPAddressInput | null;
  domain?: string | null;
  eventID?: string | null;
  host?: string | null;
  httptxID?: string | null;
  profile?: string | null;
  reason?: string | null;
  signatureName?: string | null;
  site?: string | null;
  sourceCity?: string | null;
  sourceCountryName?: string | null;
  sourceIP?: IPAddressInput | null;
  timestamp?: string | null;
  uri?: string | null;
  userAgent?: string | null;
}

/**
 * A WAF violation log group by input.
 */
export interface ViolationLogGroupByInput {
  direction?: SortDirection | null;
  field: ViolationLogGroupByField;
  timeInterval?: TimeInterval | null;
}

/**
 * A WAF violation log sort input.
 */
export interface ViolationLogSortBy {
  dimension: ViolationLogDimension;
  direction: SortDirection;
}

/**
 * Filter a list of WAF signatures.
 */
export interface WAFSignatureFilterInput {
  category?: string | null;
  search?: string | null;
}

/**
 * A web service interoperability standard.
 */
export interface WSIStandardInput {
  enabled: boolean;
  ruleID: WSIStandardRuleID;
}

/**
 * Represents the Webhook channel-specific configuration Input.
 */
export interface WebhookConfigurationInput {
  URL: string;
}

/**
 * An XML SQL injection relaxation rule.
 */
export interface XMLSQLInjectionRelaxationRuleInput {
  enabled: boolean;
  isNameRegex: boolean;
  location?: XMLLocation | null;
  name: string;
}

/**
 * An XML XSS relaxation rule.
 */
export interface XMLXSSRelaxationRuleInput {
  enabled: boolean;
  isNameRegex: boolean;
  location?: XMLLocation | null;
  name: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
