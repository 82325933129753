import React, { PureComponent } from 'react';

import { FederationProvider } from 'Graph';

/**
 * Proxies Module Wrapper
 * @export
 * @class ProxiesModuleWrapper
 * @extends {PureComponent<{}, {}>}
 */
export default class ProxiesModuleWrapper extends PureComponent<{}, {}> {
  /**
   * Render
   * @returns {*} 
   * @memberof ProxiesModuleWrapper
   */
  public render() {
    const { children } = this.props;
    return (
      <FederationProvider silent={true}>
        {children}
      </FederationProvider>
    );
  }
}